import React, { Fragment } from "react"
import Container from "../container"
import CardContainer from "../card-container"
import Banner from "../banner"
import ContentHeader from "../content-header"
import Accordion from "../accordion"
import termsData from "../../assets/pageData/savings-terms-and-conditions"

const SavingsTermsAndConditions = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={`Savings Terms and Conditions`}
        />
        <div className="contentHolder">
          <CardContainer>
            <div className="resources">
              {termsData && <Accordion data={termsData} />}
            </div>
          </CardContainer>
        </div>
      </Container>
    </Fragment>
  )
}

export default SavingsTermsAndConditions
