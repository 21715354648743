import React, { useState } from "react"
import DOMPurify from 'dompurify';
import { window } from "browser-monads"
import styles from "./career.module.scss"
import Button from "../../components/button"
import socialShare from "../social-media-share"
import { truncateText, getFirstParagraphText } from "../../lib"

const Position = ({ data: { Title: header, Description, Department, City, Country, ShortlinkUrl: link } }) => {

  const Header = header.length > 27 ? `${header.substr(0, 26)}...` : header;
  const dept = `${Department} | `
  const location = `| ${City}, ${Country}`

  return (
    <div className={styles.jobDetails}>
      <div className={styles.jobDetailsCard}>
        <div className={styles.head}>
          <h3> {Header}</h3>
          <p> {dept}Full Time {location}</p>
        </div>
        <div
          className={styles.text}
          dangerouslySetInnerHTML={{
            __html: truncateText(
              DOMPurify.sanitize(Description),
              230,
              "..."
            ),
          }}
        ></div>
        <div className={styles.jobDetailsButton}>
          <Button
            type="button"
            onClick={() => {
              window.open(link, "_blank")
            }}
          >
            Apply
                </Button>
          {socialShare(link)}
        </div>
      </div>
    </div>
  )

}

export default Position
