import React from "react"
import ProgressiveImage from "react-progressive-image"
import QuickWithdrawals from "../../assets/images/home-quick-withdrawals.png"
import QuickWithdrawalsTiny from "../../assets/images/home-quick-withdrawals-tiny.png"
import Transfer from "../../assets/images/home-money-transfer.png"
import TransferTiny from "../../assets/images/home-money-transfer-tiny.png"
import PayBills from "../../assets/images/home-pay-bills.png"
import PayBillsTiny from "../../assets/images/home-pay-bills-tiny.png"
import styles from "./home.module.scss"
import { Controller, Scene } from "react-scrollmagic"
import { Tween, Timeline } from "react-gsap"
const Payment = () => {
  return (
    <section className={styles.payment}>
      <Controller>
        <Scene triggerHook="onLeave" duration={3000} pin>
          <Timeline wrapper={<div id="pinContainer" />}>
            <section className={styles.panel}>
              <div></div>
            </section>
            <Tween
              position={0}
              staggerFrom={{
                y: "30%",

                ease: "Expo.easeOut",
              }}
              staggerTo={{
                y: "-120%",
              }}
              stagger={0.5}
              duration={1200}
            >
              <div className={styles.paymentTransfers}>
                <div className={styles.paymentTransfersImage}>
                  <ProgressiveImage src={Transfer} placeholder={TransferTiny}>
                    {src => <img src={src} alt="money transfer" />}
                  </ProgressiveImage>
                </div>
                <div className={styles.paymentTransfersText}>
                  <h2>Money Transfer</h2>
                  <p>
                    Make money by helping people around you transfer cash to
                    their loved ones and business associates. Quick
                    transactions, great profits!
                  </p>
                </div>
              </div>
            </Tween>
            <Tween
            
              position="80"
              staggerFrom={{
                y: "80%",
         
              }}
              staggerTo={{
                y: "-230%",
                ease: "ease",
                duration:"3000"
              }}
              stagger={0.5}
              duration={5000}
            >
              <div className={styles.paymentWithdrawals}>
                <div className={styles.paymentWithdrawalsText}>
                  <h2> Quick Withdrawals</h2>
                  <p>
                    Save your community the stress of going to queue at an ATM.
                    Become the first stop of cash withdrawal in your
                    neighborhood by using Kudi.
                  </p>
                </div>
                <div className={styles.paymentWithdrawalsImage}>
                  <ProgressiveImage
                    src={QuickWithdrawals}
                    placeholder={QuickWithdrawalsTiny}
                  >
                    {src => <img src={src} alt="quick withdrawals" />}
                  </ProgressiveImage>
                </div>
              </div>
            </Tween>
            <Tween
              position="120"
              staggerFrom={{
                y: "100%",
              }}
              staggerTo={{
                y: "-170%",
              }}
              stagger={0.5}
              duration={6000}
            >
              <div className={styles.paymentBills}>
                <div className={styles.paymentBillsImage}>
                  <ProgressiveImage src={PayBills} placeholder={PayBillsTiny}>
                    {src => <img src={src} alt="pay bills" />}
                  </ProgressiveImage>
                </div>
                <div className={styles.paymentBillsText}>
                  <h2>Pay Bills</h2>
                  <p>
                    Your customers do not need to wait to have access to
                    Electricity, TV Subscription (DSTV, GOTV, Startimes) or
                    airtime to make calls. You make better profits when you help
                    your community pay bills and continue with their lives.
                  </p>
                </div>
              </div>
            </Tween>
          </Timeline>
        </Scene>
      </Controller>
    </section>
  )
}

export default Payment

