import React from "react"
import PropTypes from "prop-types"
import Cookies from "../components/cookies"
import Layout from "../components/layout"
import SEO from "../components/seo"


const CookiesPage = ({ pageContext, location }) => {
  return (
    <Layout showNav location={location}>
      <SEO
        title="Kudi Cookies Statement"
        description="Kudi Cookies Statement"
        slug="cookies"
      />
      <Cookies />
    </Layout>
  )
}

CookiesPage.propTypes = {
  location: PropTypes.object,
}

export default CookiesPage
