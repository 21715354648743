import React from "react"
import styles from "./carousel.module.scss"
import cx from "classnames"
const CarouselIndicator = ({ index, activeIndex, onClick }) => (
  <li
    tabIndex="0"
    role="button"
    onKeyDown={() => onClick()}
    onClick={() => onClick()}
    key={index}
  >
    <div
      className={
        index === activeIndex
          ? cx(styles.carouselIndicator, styles.carouselIndicatorActive)
          : styles.carouselIndicator
      }
    ></div>
  </li>
)

export default CarouselIndicator
