import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import SavingsTermsAndConditions from  "../components/savingsTnC"

const SavingsTermsAndConditionsPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Savings Terms & Conditions" description="Nomba's savings terms and conditions" slug="savings-terms" />
    <SavingsTermsAndConditions/>
  </Layout>
)

SavingsTermsAndConditionsPage.propTypes = {
  location: PropTypes.object,
}

export default SavingsTermsAndConditionsPage
