import React from "react"
import styles from "./marketplace.module.scss"
import AgentShop from "../../assets/images/marketplace/agentshop.png"
import Logo1 from "../../assets/images/marketplace/Logo-1.png"
import Logo2 from "../../assets/images/marketplace/Logo-2.png"
import Logo3 from "../../assets/images/marketplace/Logo-3.png"
import Logo4 from "../../assets/images/marketplace/Logo-4.png"
import Logo5 from "../../assets/images/marketplace/Logo-5.png"
import Logo6 from "../../assets/images/marketplace/Logo-6.png"
import Insurance from "../../assets/images/marketplace/insurance.png"
import Banking from "../../assets/images/marketplace/banking.png"
import FMCG from "../../assets/images/marketplace/fmcg.png"
import Education from "../../assets/images/marketplace/edu.png"
import App from "../../assets/images/marketplace/app.png"
import { withPrefix } from "gatsby"
import cx from "classnames"
import { CaretRightIcon, PlayIcon } from "../../assets/svg"
import Button from "../../components/button"
export default function Marketplace() {
  return (
    <div className={styles.home}>
      <div className={styles.main}>
        <div className={styles.container}>
          <div className={styles.mainInner}>
            <div className={styles.left}>
              <h1>
                Digitizing offline &nbsp;
                <br />
                <span className="underlined">
                  <span>payments</span>
                </span>
                &nbsp; across Nigeria.
              </h1>
              <p>
                Our fast-growing network of Mobile Money Agents gives your
                business access to more customers. With Kudi you can accept
                payments, send payouts, onboard customers and more offline from
                anywhere in Nigeria.
              </p>
              <Button>
                <a href="mailto:marketplace@kudi.com">Request for a demo</a>
              </Button>
              <div className={styles.terms}>
                <p>Teams Using Marketplace</p>
                <div className={styles.imagelist}>
                  <img src={Logo1} alt="Aella" />
                  <img src={Logo2} alt="PRA insurance" />
                  <img src={Logo3} alt="Trove" />
                  <img src={Logo4} alt="Wealthealth" />
                  <img src={Logo5} alt="mycovergenius" />
                  <img src={Logo6} alt="omnibiz" />
                </div>
              </div>
            </div>
            <div className={styles.right}>
              <img src={AgentShop} alt="agent shop" />
            </div>
          </div>
        </div>
      </div>
      <div className={cx(styles.section, styles.businesses)}>
        <h2>Made for all types of businesses</h2>
        <div className={styles.whiteBg}>
          <div className={styles.container}>
            <div className={styles.imageTextGrid}>
              <div className={styles.text}>
                <h3>Insurance</h3>
                <p>
                  With our agent network, you can sell and collect insurance
                  payments from anyone from the comfort of your office.
                </p>
              </div>
              <img src={Insurance} alt="agent shop" />
            </div>
          </div>
        </div>
        <div className={styles.gradientBg}>
          <div className={styles.container}>
            <div className={styles.imageTextGrid}>
              <img src={Education} alt="agent shop" />
              <div className={styles.text}>
                <h3>Education</h3>
                <p>
                  Collect school fees from your students in a way that doesn't
                  stress you or stress them.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.section}>
        <div className={styles.whiteBg}>
          <div className={styles.container}>
            <div className={styles.imageTextGrid}>
              <div className={styles.text}>
                <h3>FMCG</h3>
                <p>
                  Deposit and disburse cash, reduce cash transactions by
                  leveraging on our Agent points across the country.
                </p>
              </div>
              <img src={FMCG} alt="agent shop" />
            </div>
          </div>
        </div>
        <div className={styles.whiteBg}>
          <div className={styles.container}>
            <div className={styles.imageTextGrid}>
              <img src={Banking} alt="agent shop" />
              <div className={styles.text}>
                <h3>Banking</h3>
                <p>
                  From account opening, to savings, to loans, wallet-funding and
                  more you can put your banking services in the hands of more
                  customers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.createDashbaord}>
        <div className={styles.container}>
          <div className={styles.card}>
            <div className={styles.left}>
              <h3>Create your own dashboard in few minutes</h3>
              <p>
                You can create your product offering, design what you want it to
                look like, monitor all transactions with our easy-to-use
                dashboard. No technical skills required.
              </p>
              <a className={styles.button} href="mailto: marketplace@kudi.com">
                Request for a demo
              </a>
            </div>
            <div className={styles.right}>
              <img src={App} alt="agent shop" />
            </div>
          </div>
        </div>
      </div>
      <div className={styles.watch}>
        <div className={styles.group}>
          <div className={styles.play}>
            <a
              className={styles.button}
              href="https://www.youtube.com/watch?v=AO4nCAaGy9U"
              target="_blank"
              rel="noopener noreferrer"
            >
              <PlayIcon />
            </a>
          </div>
          <h3>Here’s what happens when you create a dashboard</h3>
        </div>
      </div>

      <div className={styles.container}>
        <div className={styles.stats}>
          <div className={styles.stat}>
            <h3>50,000+</h3>
            <p>Agents</p>
          </div>
          <div className={styles.stat}>
            <h3>1M+</h3>
            <p>Transactions processed monthly</p>
          </div>
          <div className={styles.stat}>
            <h3>100+</h3>
            <p>Businesses</p>
          </div>
        </div>
      </div>
    </div>
  )
}
