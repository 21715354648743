import React, { createContext, useEffect } from "react"
import { Button } from "@kudi-inc/dip"
import PropTypes from "prop-types"
import HeroSection from "../../components/hero-section"
import Wrapper from "../../components/wrapper"
import Banner from "../../components/banner"
import Carousel from "../carousel"
import styles from "./stories.module.scss"
import { Search } from "../../assets/svg"
import { connect } from "react-redux"
import SelectCategory from "./select"
import Story from "./story"
import EmptyState from "./empty-state"
import Loader from "./loader"
import { heroData, carouselData } from "../../assets/pageData/stories"
import {
  setActiveCategory,
  getStoriesRequest,
  paginate,
  setPage,
  doSearch,
  setSearchTerm,
  setSearchPage,
} from "../../store/actions/stories"

const StoriesContext = createContext()

const { Provider } = StoriesContext

const StoriesLayout = ({
  stories,
  activeCategory,
  inProgress,
  error,
  setActiveCategory,
  getStoriesRequest,
  filteredStories,
  paginate,
  page,
  setPage,
  doSearch,
  setSearchTerm,
  searchTerm,
  searchResult,
  limit,
  isSearch,
  setSearchPage,
}) => {
  const handleChange = ({ target: { value } }) => {
    setSearchTerm(value)
    doSearch()
  }

  useEffect(() => {
    if (stories.length === 0) getStoriesRequest("")
    if (stories && stories.length > 0) paginate(page)
  }, [page, stories.length, paginate, getStoriesRequest, activeCategory])

  return (
    <Provider
      value={{
        activeCategory,
        inProgress,
        error,
        setActiveCategory,
        getStoriesRequest,
      }}
    >
      <Wrapper bg="grey">
        <HeroSection data={heroData} />
      </Wrapper>
      <Wrapper bg="blue">
        <Carousel slides={carouselData} />
      </Wrapper>
      <Wrapper bg="white">
        <div className={styles.Stories}>
          {/* <div className={styles.StoriesFilter}>
            <div className={styles.StoriesSearch}>
              <div className={styles.StoriesSearchInput}>
                <Search />
                <input value={searchTerm} onChange={handleChange} />
              </div>
            </div>
            <div className={styles.StoriesDrop}>
              <SelectCategory
                activeCategory={activeCategory}
                getStoriesRequest={getStoriesRequest}
                options={[
                  { text: "All", value: "all" },
                  { text: "Humans of Kudi", value: "kudi-stories" },
                  { text: "Engineering", value: "kudi-engineering" },
                  { text: "Agent Banking", value: "agent-banking" },
                  { text: "Kudi HQ", value: "kudi-hq" },
                ]}
                setActiveCategory={setActiveCategory}
              />
            </div>
          </div> */}
          <div className={styles.StoriesContent}>
            {stories.length > 0 && (
              <>
                {!inProgress &&
                  (filteredStories.length > 0 ? (
                    filteredStories.map((story, i) => (
                      <Story {...story} key={`${story.pubDate}-${i}`} />
                    ))
                  ) : (
                    <EmptyState error={error} />
                  ))}
              </>
            )}

            {inProgress && <Loader />}

            {stories.length > limit &&
              !isSearch &&
              filteredStories.length !== stories.length && (
                <Button
                  className={styles.viewMore}
                  icon={null}
                  variant="flat"
                  onClick={() => setPage(page + 1)}
                >
                  View More
                </Button>
              )}
            {searchResult.length > limit &&
              isSearch &&
              filteredStories.length !== searchResult.length && (
                <Button
                  className={styles.viewMore}
                  icon={null}
                  variant="flat"
                  onClick={() => setSearchPage(page + 1)}
                >
                  View More
                </Button>
              )}
          </div>
        </div>
      </Wrapper>
      <Banner type="list" />
    </Provider>
  )
}

StoriesLayout.propTypes = {
  location: PropTypes.object,
}

StoriesLayout.context = StoriesContext

const mapStateToProps = ({
  stories: {
    stories,
    activeCategory,
    inProgress,
    error,
    filteredStories,
    page,
    searchTerm,
    searchResult,
    isSearch,
    setSearchPage,
    limit,
  },
}) => {
  return {
    stories,
    activeCategory,
    inProgress,
    error,
    filteredStories,
    page,
    searchTerm,
    searchResult,
    isSearch,
    setSearchPage,
    limit,
  }
}

export default connect(mapStateToProps, {
  setActiveCategory,
  getStoriesRequest,
  paginate,
  setPage,
  doSearch,
  setSearchTerm,
  setSearchPage,
})(StoriesLayout)
