export const lastEditDate = `Last updated: March 06, 2021`
export const sideNavTitle = `Cookie Statement`

export const informationData = [
  {
    header: "Introduction",
    id: "introduction",
    description: `<p>Kudi uses cookies and similar technologies throughout our  
          apps and websites to ensure your visit to our sites goes smoothly. </p>

          <h3> What are cookies?</h3>
          
          <p> Cookies (and similar technologies) are widely used on the 
          internet and allow a website/portal to recognize a user’s device, 
          without uniquely identifying the individual person using the
           computer. Cookies are small files that get stored on a user’s 
           computer. They are designed to hold a small amount of data
            specific to a particular client or website. 
            A cookie is basically a web server talking to a web browser 
            so the server can collect information back from the browser. 
            This means the site recognizes your computer each time you visit. 
            These technologies make it easier for you to log on and use our 
            websites and provide information to us, for example which parts 
            of the website you visit.</p>
          
            <p>For more information about cookies, including how to see 
            what cookies have been set and how to manage, block and/or 
            delete them, read the following information on our Cookie 
            Statement.          
          </p>`,
  },
  {
    header: "What cookies do we use and what do they do?",
    id: "what-cookies",
    description: `
    <h3> 1. Functional and technical cookies</h3>
   
    <p>These cookies may store your browser name, the type of computer, 
    and technical information about your means of connection to this website, 
    such as the operating system, browser platform, and other similar information. 
    This information is used to technically facilitate the navigation and use of 
    this website. In addition, functional cookies may be used to store personal 
    settings, such as language, or to remember your information for next visits 
    if you request that. These cookies are stored for a maximum of two years.</p>

    <h3>2. Analytics cookies</h3>
    
    <p>We also use analytics cookies placed by Google Analytics (through Google 
        Tag Manager) to measure the number of visits and the parts of the website 
        that are the most popular among our website visitors, as well as for 
        benchmarking purposes. This information is used to provide aggregated 
        and statistical information on the use of this website and to improve 
        the content we provide to enhance your user experience.  </p>
    
        <p> Kudi has followed the Nigerian Data Protection  Regulation  to ensure 
    that we use Google Analytics in a privacy-friendly way. This means that we 
    disabled the standard setting to share data with Google.  </p>
    
    <p> When a user consents to all analytics cookies, we collect aggregated data 
    on which content users engage with. This means the collective data is grouped as 
    a whole and can never be linked back to any individual users.   </p>
       `,
  },
  {
    header: "Managing your settings and deleting cookies",
    id: "managing-your",
    description: `<p>Cookies generally process your IP address, but 
    they do not save your personal information like email address or 
    phone number. If you don’t want cookies stored on your computer, 
    or if you want to remove cookies that have already been stored, 
    you can do this through your browser settings. You can find more 
    information about the removal of cookies on the website and the 
    all about cookies website.
    </p>
`,
  },
]
