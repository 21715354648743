import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import { Back } from "../../../assets/svg"
import styles from "../questions.module.scss"
import { ScrollToHOC } from "react-scroll-to"
import { isMobile } from "react-device-detect"
import Layout from "../../layout"

const Answer = ({ pageContext: { faq, resource }, scrollTo }) => {
  useEffect(() => scrollTo({ y: isMobile ? 260 : 560, smootth: true }), [])
  const layoutContextValue = useContext(Layout.context)
  const { disableHeaderParam } = layoutContextValue
  return (
    <div className={styles.answer}>
      <p className={styles.back}>
        <Link to={`/faq/${faq.id}${disableHeaderParam}`}>
          <Back /> Back
        </Link>
      </p>
      <h2 className={styles.header}>{resource.name} </h2>
      <div
        className={styles.description}
        dangerouslySetInnerHTML={{ __html: resource.description }}
      ></div>
    </div>
  )
}
export default ScrollToHOC(Answer)
