import React from "react"
import Carousel from "../carousel"
import Timi from "../../assets/images/timi.jpg"

const Testimonial = () => {
  // "Data" could content more but we only have one staff with a current information
  const data = [
    {
      content:
        "At Kudi, you’ll be empowered to work on things that you’re passionate about. You’ll be given autonomy. Your ideas will matter",
      quote: `"Kudi’s culture  <br/> is second to none"`,
      title: "HEAR FROM <br/> Kudi’S EMPLOYEES",
      image: Timi,
      alt: "Kudi's culture is <br/> second to none",
      author: "Timi",
    },
  ]
  return <Carousel slides={data} />
}
export default Testimonial
