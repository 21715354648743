import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Section from "../components/section"

const ProductsPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Products" slug="products" />
    <Section>
      <div/>
    </Section>
  </Layout>
)

ProductsPage.propTypes = {
  location: PropTypes.object,
}

export default ProductsPage
