import React, { useEffect } from "react"
import { navigate } from "gatsby"
import Layout from "../components/layout"
import PropTypes from "prop-types"
import SEO from "../components/seo"
import Dash from "../components/dash"

const DashPage = ({ location }) => {
  useEffect(() => {
    // redirect to main page
    navigate("/")
  })

  return (
    <Layout showNav location={location}>
      <SEO
        title="Kudi's response to Covid-19"
        description="Kudi's response to Covid-19"
        slug="covid-19"
      />
      <Dash />
    </Layout>
  )
}

DashPage.propTypes = {
  location: PropTypes.object,
}

export default DashPage
