import { ASYNC_START, LOG_ERROR, GET_OPENINGS } from "../actions/types"

export default (state = {}, action) => {
  switch (action.type) {
    case GET_OPENINGS:
      return {
        ...state,
        jobs: action.payload,
        inProgress: false,
      }
    case ASYNC_START:
      if (action.subtype === GET_OPENINGS) {
        return {
          ...state,
          inProgress: true,
        }
      }
      return state
    case LOG_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error || "Sorry, something went wrong. Please try again",
      }
    default:
      return state
  }
}
