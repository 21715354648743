import {
    ReliableService,
    MoneyBag,
    InsuranceIcon ,
    InstantSettlement 
  } from "../svg"
export default [{
    icon: ReliableService,
    text: "We understand the importance of reliability and we are proud to offer Agents the most reliable service.",
    header: "Reliable <br/>service.",
    delay: 1000,
},
{
    icon: MoneyBag,
    text: "You will never have to worry about running out of funds to run your business with Kudi Back-up Funds. We have got you here!",
    header: "Back-up <br/>funds.",
    delay: 1500,
},
{
    icon:  InstantSettlement,
    text: "We provide instant settlement to our Agents after every transaction they do on our platform.",
    header: "Instant <br/> settlement.",
    delay: 3000,
},
{
    icon: InsuranceIcon,
    text: "You are protected by the Kudi Cash Insurance Plan so you never need to worry about losing your money.",
    header: "Insurance.",
    delay: 4000,
},
]