import React from "react"
import {
  Health,
  Gym,
  Macbook,
  HealthPattern,
  GymPattern,
  MacbookPattern,
} from "../svg"
import Image from "../images/career-men.png"
import ImageTiny from "../images/career-men-tiny.png"
export const benefitsData = [
  {
    icon: Health,
    text:
      "We have a comprehensive health insurance plan that covers medical needs.",
    header: "Health Insurance",
    pattern: <HealthPattern />,
  },
  {
    icon: Gym,
    text: "Stay fit with free access to the Gym closest to you.",
    header: " Gym Membership ",
    pattern: <GymPattern />,
  },
  {
    icon: Macbook,
    text:
      "All team members receive a brand new laptop computer on their first day at work",
    header: "New Computer",
    pattern: <MacbookPattern />,
  },
]
export const heroData = {
  image: Image,
  imageTiny: ImageTiny,
  description:
    "<p>We’re working to find innovative ways to help Africans access affordable financial services.</p>",
  header: "<span class='underlined'><span>Join us</span></span>",
  buttonText: "Available Positions",
  alt: "Join us",
  page: "careers",
  link: () => (window.location.href = `#positions`),
  bgSize: "51.55em",
  yPercent: "15",
  type: "stretch",
}

export const subHero =
  "<p><span class='underlined--black'><span>Benefits of joining</span></span></p> <p>us at Kudi.</p>"
