import React from "react"
import styles from "./horizontalScroll.module.scss"
import cx from "classnames"
import { Controller, Scene } from "react-scrollmagic"
import { Tween } from "react-gsap"

const HorizontalScroll = () => {
  return (
    <Controller>
      <Scene triggerHook={120} duration={5300}>
        <Tween from={{ xPercent: "-95" }} to={{  xPercent: "100" }}>
          <div className={cx(styles.scroll, styles.sec)} id="horizontalScroll">
            <h2>Easier and Faster payments across Africa.</h2>
          </div>
        </Tween>
      </Scene>
    </Controller>
  )
}

export default HorizontalScroll
