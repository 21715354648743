import React, { Fragment } from "react"
import Container from "../container"
import CardContainer from "../card-container"
import ContentHeader from "../content-header"
import Accordion from "../accordion"
import ContentFooter from "../content-footer"
import termsData from "../../assets/pageData/terminal-terms-and-conditions"

const TerminalsTermsAndConditions = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={`POS Terms and Conditions`}
        />
        <div className="contentHolder">
          <CardContainer>
            <div className="resources">
              {termsData && <Accordion data={termsData} />}
            </div>
            <ContentFooter
              text={"By Accepting these Terms and Conditions/Agreement, You/the Agent hereby irrevocably indemnifies Cosmic Intelligent Labs Limited and its Affiliates against any losses, damages, penalties, fines or otherwise, both direct or indirect, that it may suffer as a result of your/the Agent’s use and/or misuse of the Terminals"}
              />
          </CardContainer>
        </div>
      </Container>
    </Fragment>
  )
}

export default TerminalsTermsAndConditions;

