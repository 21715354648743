import React from "react"
import SubHero from "../sub-hero"
import WidePattern from "../wide-pattern"
import styles from "./features.module.scss"

const Features = ({ features, subHero }) => (
  <section className={styles.feature}>
    <SubHero description={subHero} />
  <div className={styles.features}>
    <div className={styles.featureOptions}>
      {features.map(
        ({ icon, title, description, animate, background }, index) => (
          <div
            key={index}
            data-aos={animate}
            data-aos-duration={1500}
            className={[
              styles.featureOptionsItem,
              styles.background,
            ].join(" ")}
          >
            {icon}
            <h4>{title}</h4>
            <p>{description}</p>
          </div>
        )
      )}
    </div>
    </div>
    <WidePattern type="greyLight" />
  </section>
)

export default Features
