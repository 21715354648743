import React from "react"
import Banner from "../banner"
import Container from "../container"
import ContentHeader from "../content-header"
import CardContainer from "../card-container"
import DocumentSideNav from "../document-side-nav "
import DocumentContent from "../document-content"
import styles from "./cookies.module.scss"
import {
  lastEditDate,
  sideNavTitle,
  informationData,
} from "../../assets/pageData/cookies"

const Cookies = ({ scrollTo }) => {
  return (
    <>
      <Container>
        <ContentHeader
          title={`Kudi Cookies Statement`}
          backText="Cookies"
          description={lastEditDate}
        />
        <div className="contentHolder">
          <CardContainer>
            <div className={styles.wrapperX}>
              <DocumentSideNav
                pageData={informationData}
                sideNavTitle={sideNavTitle}
              />
              
              <DocumentContent informationData={informationData} />
            </div>
          </CardContainer>
        </div>
      </Container>

      <Banner type="list" />
    </>
  )
}

export default Cookies
