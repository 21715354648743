import React from "react"
import ProgressiveImage from "react-progressive-image"
import { agentTestimony } from "../../assets/pageData/home"
import ImageTiny from "../../assets/images/home-agent-tiny.png"
import Image from "../../assets/images/home-agent.png"
import styles from "./home.module.scss"
import HomeCarousel from "../home-carousel"
import { Controller, Scene } from "react-scrollmagic"
const Agent = () => {
  return (
    <section className={styles.agents}>
      <div className={styles.container}>
        <div className={styles.agentsImage}>
          <ProgressiveImage src={Image} placeholder={ImageTiny}>
            {src => (
              <Controller>
                <Scene
                  triggerElement="#trigger"
                  classToggle="zap"
                  duration={4000}
                >
                  <img
                    src={src}
                    alt="affordable financial services"
                    id="trigger"
                  />
                </Scene>
              </Controller>
            )}
          </ProgressiveImage>
        </div>
        <div className={styles.agentsText}>
          <h2 className={styles.agentsTextHeader}>
            What our agents have to say...
          </h2>
          <div className={styles.feedback}>
            <HomeCarousel slides={agentTestimony} />
          </div>
        </div>
      </div>
    </section>
  )
}

export default Agent

