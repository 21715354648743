import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Savings from "../components/savings"

const SavingsPage= ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Kudi's response to Covid-19" description="Kudi's response to Covid-19"  slug="covid-19" />
    <Savings />
  </Layout>
)

SavingsPage.propTypes = {
  location: PropTypes.object,
}

export default SavingsPage
