import React, { useContext } from "react"
import { Link } from "gatsby"
import styles from "./sideNav.module.scss"
import Layout from "../layout"

const SideNav = ({ pageContext }) => {
  const { faqs, faq } = pageContext
  const layoutContextValue = useContext(Layout.context)
  const { disableHeaderParam } = layoutContextValue
  return (
    <div className={styles.sideNav}>
      <ul>
        {faqs &&
          faqs.map(faqItem => (
            <Link
              key={faqItem.id}
              to={`/faq/${faqItem.id}${disableHeaderParam}`}
            >
              <li className={faq.id === faqItem.id ? styles.active : ""}>
                {faqItem.name}{" "}
              </li>
            </Link>
          ))}
      </ul>
    </div>
  )
}

export default SideNav
