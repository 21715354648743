import React from 'react';
import articleStyle from './article.module.scss';

const Article = ({ className, topic, children }) => {
  return (
    <div className={className}>
      <header className={articleStyle.header}>{topic}</header>
      <section className={articleStyle.body}>{children}</section>
    </div>
  );
};

export default Article;
