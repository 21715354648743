import { toast } from "react-toastify"
import {
  VERIFY_OTP,
  VERIFY_OTP_START,
  VERIFY_OTP_STOP,
  RESEND_OTP,
  RESEND_OTP_START,
  RESEND_OTP_STOP,
} from "./types"
import { AgentRegistration } from "../../lib"

export const verifyAgentOTPRequest = payload => async dispatch => {
  dispatch({
    type: VERIFY_OTP_START,
  })
  try {
    const response = await AgentRegistration.verifyOTP(payload)
    dispatch({
      type: VERIFY_OTP,
      payload: response.data,
    })
    toast.success("Verification successful.")

    return true
  } catch (errors) {
    dispatch({
      type: VERIFY_OTP_STOP,
    })
    toast.error(
      errors?.response?.data?.message ||
        errors?.message ||
        "Something went wrong with the Verification process"
    )
    return false
  }
}

export const resendAgentOTPRequest = payload => async dispatch => {
  dispatch({
    type: RESEND_OTP_START,
  })
  try {
    const response = await AgentRegistration.resentOTP(payload)
    dispatch({
      type: RESEND_OTP,
      payload: response.data,
    })
    toast.success("OTP resent successful.")

    return true
  } catch (errors) {
    dispatch({
      type: RESEND_OTP_STOP,
    })
    toast.error(
      errors?.response?.data?.message ||
        errors?.message ||
        "Something went wrong with the resending OTP"
    )
    return false
  }
}
