import React from "react"
import { BusinessFinance, NairaConnection } from "../svg"
import Image from "../images/home-hero.gif"
import Kola from "../images/partner-kola.png"
import Tidjane from "../images/partner-kidjane.png"
import Michael from "../images/partner-michael.png"
import YCombinator from "../images/y-combinator.png"
import Partech from "../images/partech.png"
import VenturesPlatform from "../images/ventures-platform.png"
export const heroData = {
  image: Image,
  description:
    "<p>As a Kudi Agent you can provide financial services in your local community and make money while at it.</p>",
  header:
    "Providing <span class='underlined'> <span>affordable</span></span><br/> financial services",
  buttonText: "Download The App",
  alt: "download kudi app",
  bgSize: "33.33em",
  height: "",
  page: "home",
}
export const subHero = {
  agent:
    "<p>Why you should be an </p><p><span class='underlined--black'> <span>Agent</span></span> on <span class='underlined--black'><span> Kudi</span></span>  </p>",
  product:
    "<p>Explore our </p><p><span class='underlined--black'> <span>product offerings</span></span></p>",
  assurance:
    "<p>With Kudi, you can be <br/>sure of <span class='underlined--black'> <span>2 things.</span></span></p>",
  partners:
    "<p>What our partners <br/>think <span class='underlined--black'><span>about us.</span></span></p>",
}
export const bannerText =
  "We are trusted by millions of people every day to send money, get cash and make bill payments across Nigeria."

export const features = [
  {
    animate: "fade-up",
    icon: <BusinessFinance />,
    title: "Ease",
    description:
      "In partnership with our Agents, we are making it easier to withdraw money, send money and make bill payments even in the most remote areas.",
  },
  {
    animate: "fade-up",
    icon: <NairaConnection />,
    title: "Speed",
    description:
      "Speed is everything when it comes to making payments, you can complete a transaction in a minute or less on Kudi at any of our Agent Points.",
  },
]

export const partners = [
  {
    animate: "zoom-in-right",
    image: Kola,
    author: "Kola Aina",
    company: "Founder,Ventures Platforms",
    description:
      "The Kudi team has an ear to the ground that allows them to understand what their users and customers most need, such that their product is second to none in delivering what users need and making a real dent in financial inclusion.",
  },
  {
    animate: "zoom-in-left",
    image: Tidjane,
    company: "General Partner at Partech",
    author: "Tidjane Dème",
    description:
      "Agent networks are the backbone of African digitization as they offer an effective way of distributing digital services. The Kudi team has built an excellent product that their agents love to use. As a result, Kudi has demonstrated impressive growth since its inception.",
  },
  {
    animate: "zoom-in-left",
    company: "Y Combinator",
    image: Michael,
    author: "Michael Seibel",
    description:
      "For an economy that largely depends on cash, the growth of digital payments in Nigeria will be accelerated by Kudi as they provide a gateway between cash and digital, and we think that's a huge opportunity.",
  },
]
export const logos = [
  {
    image: YCombinator,
    alt: "Y-combinator",
  },
  {
    image: Partech,
    alt: "Partech",
  },
  {
    image: VenturesPlatform,
    alt: "Y-combinator",
  },
]

export const agentTestimony = [
  {
    author: "Engr. Lugard Okwukwe",
    text:
      "Best in everything, best P.O.S service ever and Fastest agent App. What more can you ask for? Join the moving train now. I love Kudi, Kudi has changed my life.",
  },
  {
    author: "Thompson Addeh",
    text:
      "You are fast in attending to Agents complaints and resolving any issue raised as quickly as possible. You have good customer relationship and that is what we Agents want.",
  },
  {
    author: "Solomon Ajayi",
    text:
      "Your customer care service is hands down the best I've experienced so far. Absolutely no lag in getting my complaints resolved. I'd definitely recommend this app to anyone",
  },
  {
    author: "Endurance Azekhouria Ikhalo",
    text:
      "I appreciate your platform. You guys are the only ones attending to your customers during this period.I really appreciate you.",
  },
]
