import React, { Fragment, useState, useEffect } from "react"
import { Button } from "@kudi-inc/dip"
import {
  Check,
  PiggyVestLogo,
  RelianceLogo,
  UlessonLogo,
  WellaHealthLogo,
  OnboardUser,
  EasyRemitance,
  AcceptPayment,
  ConvinienceSettlement,
  Pricing,
  Setup,
} from "../../assets/svg"
import IntroImage from "../../assets/images/introImage.png"
import Jet from "../../assets/images/increase-brand-awareness.gif"
import Payment from "../../assets/images/collect-payment.gif"
import NewCustomer from "../../assets/images/reach-new-customers.gif"
import Tailor from "../../assets/images/tailor.png"

import Article from "../article"
import styles from "./styles.module.scss"
import { navigate } from "gatsby"

const DashPage = () => {
  const [stylesIsLoaded, setStyleIsLoaded] = useState(false)

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setStyleIsLoaded(true)
    }, 200)
    return () => () => clearTimeout(timeoutId)
  }, [])

  const topic = (
    <span>
      Reach more <br /> customers{" "}
      <span className={styles.lastWord}>offline</span>
    </span>
  )
  return (
    <Fragment>
      <section className={styles.aboutDash}>
        <div className={styles.mainContent}>
          <Article className={styles.articleStyle} topic={topic}>
            <span className={styles.articleText}>
              Dash helps you onboard customers, accept and send out payments by
              leveraging on our network of over 60,000 Agents across Nigeria.
            </span>
            <div className={styles.articleCTA}>
              {stylesIsLoaded && (
                <Button
                  className={styles.registerButton}
                  onClick={() => navigate("https://dash.kudi.com/")}
                >
                  Create Account
                </Button>
              )}
              {stylesIsLoaded && (
                <Button
                  className={styles.loginButton}
                  onClick={() => navigate("https://dash.kudi.com/")}
                >
                  Log in
                </Button>
              )}
            </div>
            <div className={styles.listItems}>
              <span>
                <Check /> Made For all Businesses
              </span>
              <span>
                <Check /> Offline Payments
              </span>
              <span>
                <Check /> Quick support when you need it
              </span>
            </div>
          </Article>

          <div className={styles.imageSide}>
            <img src={IntroImage} alt="" />
          </div>
        </div>
      </section>
      <section className={styles.customers}>
        <div className={styles.customer}>
          <UlessonLogo />
        </div>
        <div className={styles.customer}>
          <WellaHealthLogo className="wellahealth" />
        </div>
        <div className={styles.customer}>
          <RelianceLogo />
        </div>
        <div className={styles.customer}>
          <PiggyVestLogo />
        </div>
      </section>
      <section className={styles.howItWorks}>
        <header>
          <span className={styles.minHeading}>How it works</span>
          <span className={styles.heading}>
            The simpliest way to accept cash payments and collect user data
            offline
          </span>
        </header>
        <div className={styles.services}>
          <div className={styles.service}>
            <OnboardUser />
            <Article topic="Onboard users">
              Onboard and manage users without stress
            </Article>
          </div>
          <div className={styles.service}>
            <AcceptPayment />
            <Article topic="Accept Payment">
              Accept payments and subscription fees in one setup
            </Article>
          </div>
          <div className={styles.service}>
            <EasyRemitance />
            <Article topic="Easy Remittance">
              Process cash payments easily to your customers
            </Article>
          </div>
          <div className={styles.service}>
            <ConvinienceSettlement />
            <Article topic="Convinient Settlement">
              Send your money anytime you need it to your bank account
            </Article>
          </div>
        </div>

        <div className={styles.introImage}>
          <div className={styles.illustrativeImage}></div>
          <div className={styles.instructionImage}></div>
        </div>
        <div className={styles.benefits}>
          <div className={styles.collectPayments}>
            <Article className={styles.collectArticle} topic="Collect payments">
              Today, more and more people enjoy the benefit of accessing
              financial services to the last mile through our distribution and
              technology. Collect payments for your goods or services without
              any extra cost or manpower.
              {stylesIsLoaded && (
                <Button
                  className={styles.learnmore}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </Article>
            <div className={styles.payments}>
              <div className={styles.paymentImage}>
                <img src={Payment} alt="" />
              </div>
            </div>
          </div>
          <div className={styles.reachNewCustomer}>
            <div className={styles.newcustomer}>
              <div className={styles.newCustomerImage}>
                <img src={NewCustomer} alt="" />
              </div>
            </div>
            <Article
              className={styles.newcustomerArticle}
              topic="Reach new customers"
            >
              Our platform allows you to reach millions of Nigerians with our
              robust network of Agents across the country. New customers can
              discover your product easily. All of this will be done without you
              lifting a finger, total cruise.
              {stylesIsLoaded && (
                <Button
                  className={styles.learnmore}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </Article>
          </div>
          <div className={styles.brandawareness}>
            <Article
              className={styles.brandawarenessArticle}
              topic="Increase brand awareness"
            >
              Our 60,000+ agents automatically become ambassadors of your
              product. We help you get in front of new customers. Your brand is
              showcased at all our agent outlets across the country.
              {stylesIsLoaded && (
                <Button
                  className={styles.learnmore}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </Article>
            <div className={styles.jet}>
              <img src={Jet} alt="" />
            </div>
          </div>
        </div>
      </section>
      <section className={styles.banner} id="banner">
        <div
          className={styles.bannerImage}
          data-aos={"fade-right"}
          data-aos-duration={1500}
        >
          <img src={Tailor} alt="" className={styles.tailor} />
        </div>
        <div className={styles.bannerText} data-aos={"fade-left"}>
          <span>
            With our 60,000+ Agent points across Nigeria, offline payments have
            never been easier.
          </span>
          <span>Powering offline payments across Nigeria.</span>
          {stylesIsLoaded && (
            <Button
              className={styles.bannerButton}
              onClick={() => navigate("https://dash.kudi.com/")}
            >
              Start Now
            </Button>
          )}
        </div>
      </section>
      <section className={styles.getInTouch}>
        <Article
          className={styles.talkToUs}
          topic="Ready to get started? Get in touch."
        >
          <span>
            Let us know you’re interested and how you would like to use Dash.
            Our sales experts are on standby to speak with you.
          </span>
          {stylesIsLoaded && (
            <Button className={styles.getInTouchButton}>Talk to us</Button>
          )}
        </Article>
        <div className={styles.pricing}>
          <div className={styles.icon}>
            <Pricing />
          </div>
          <header>Afforable pricing</header>
          <span>
            We’ve made our pricing business friendly so you can start
            immediately.
          </span>
        </div>
        <div className={styles.setup}>
          <div className={styles.icon}>
            <Setup />
          </div>
          <header>Setup is as simple as ABC</header>
          <span>
            By draging and dropping fields, you are good to go on Dash. The best
            part is all these can happen in minutes
          </span>
        </div>
      </section>
      <section className={styles.howTo}>
        <header>
          <span className={styles.mainHeader}>
            Here’s how other people are using Dash
          </span>
          <span className={styles.subHeader}>
            Learn how other businesses use Dash to grow their businesses.
          </span>
        </header>
        <div className={styles.howToBody}>
          <div
            className={styles.content}
            data-aos={"slide-right"}
            data-aos-duration={1000}
          >
            <div className={styles.registration}>
              <span className={styles.contentHeader}>
                Dash for Registration
              </span>
            </div>
            <div className={styles.text}>
              <span className={styles.innerText}>
                There is no longer need for hiring sales reps to grow users.
                Let’s help you get customers in communities where we have agent
                presence.
              </span>
              {stylesIsLoaded && (
                <Button
                  className={styles.learnButton}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </div>
          </div>
          <div
            className={styles.content}
            data-aos={"slide-up"}
            data-aos-duration={1000}
          >
            <div className={styles.collection}>
              <span className={styles.contentHeader}>Dash for Collections</span>
            </div>
            <div className={styles.text}>
              <span className={styles.innerText}>
                Over 88% of transactions still happen via cash in Nigeria. Let’s
                help you optimize your business for cash collections using our
                network
              </span>
              {stylesIsLoaded && (
                <Button
                  className={styles.learnButton}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </div>
          </div>
          <div
            className={styles.content}
            data-aos={"slide-left"}
            data-aos-duration={1000}
          >
            <div className={styles.remittance}>
              <span className={styles.contentHeader}>Dash for Remittance</span>
            </div>
            <div className={styles.text}>
              <span className={styles.innerText}>
                Make cash payments to your customers without a bank transfer.
                Let’s bring payments closer to you.
              </span>
              {stylesIsLoaded && (
                <Button
                  className={styles.learnButton}
                  onClick={() => navigate("http://kudi.com/dash")}
                >
                  Learn More
                </Button>
              )}
            </div>
          </div>
        </div>
      </section>
    </Fragment>
  )
}
export default DashPage
