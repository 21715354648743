import axios from "axios"
import {
  websiteServicesBaseUrl,
  boltBaseUrl,
  talentLyftBaseUrl,
  mediumRssToJsonService,
} from "./constants"

const websiteServicesInstance = axios.create({
  baseURL: websiteServicesBaseUrl,
  headers: {
    // "Access-Control-Allow-Origin": "*",
    post: {
      "Content-Type": "application/json",
    },
  },
})

const boltBaseUrlInstance = axios.create({
  baseURL: boltBaseUrl,
  headers: {
    // "Access-Control-Allow-Origin": "*",
    put: {
      "Content-Type": "application/json",
    },
  },
})

const talentLyftServicesInstance = axios.create({
  baseURL: talentLyftBaseUrl,
  headers: {
    // "Access-Control-Allow-Origin": "*",
    post: {
      "Content-Type": "application/json",
    },
  },
})

export const setToken = token => {
  if (token) {
    websiteServicesInstance.defaults.headers.common[
      "Authorization"
    ] = `Token ${token}`
  }
}

const websiteServicesPostRequest = (uri, body) =>
  websiteServicesInstance.post(uri, {
    ...body,
  })
// const websiteServicesGetRequest = uri => websiteServicesInstance.get(uri);

const talentLyftServicesGetRequest = uri => talentLyftServicesInstance.get(uri)

export const Auth = {
  login: body =>
    websiteServicesPostRequest("login", body).then(({ data, status }) => ({
      ...data,
      status,
    })),
}
export const Insurance = {
  purchase: body =>
    websiteServicesPostRequest("insure", body).then(({ data, status }) => ({
      ...data,
      status,
    })),
}
// https://website-service-feature-agent-onboarding-from-wt.kudi.ng/registration/%7BregistrationId%7D/complete
//  http://localhost:1337/v5/agents/registration/60898967a3ab862c521a461c/send-phone-otp HTTP/1.1
export const AgentRegistration = {
  verifyOTP: body =>
    boltBaseUrlInstance
      .put(
        `/registration/${body.registrationId}/complete`,
        body.newAgentActivationRequest
      )
      .then(({ data, status }) => ({
        ...data,
        status,
      })),
  resentOTP: registrationId =>
    boltBaseUrlInstance
      .put(`/registration/${registrationId}/send-otp`, registrationId)
      .then(({ data, status }) => ({
        ...data,
        status,
      })),
}

export const TelentLyft = {
  getOpenPositions: () =>
    talentLyftServicesGetRequest("jobs?details=true&perPage=10").then(
      ({ data, status }) => ({
        ...data,
        status,
      })
    ),
}

const mediumRssToJsonServicesInstance = axios.create({
  baseURL: mediumRssToJsonService,
  headers: {
    post: {
      "Content-Type": "application/json",
    },
  },
})

const mediumRssToJsonServicesGetRequest = uri =>
  mediumRssToJsonServicesInstance.get(`/posts`, {
    params: {
      uri,
    },
  })

export const MediumRssToJson = {
  getStories: publication =>
    mediumRssToJsonServicesGetRequest(publication).then(({ data, status }) => ({
      ...data,
      status,
    })),
}

export const fetchAllAgents = uri =>
  boltBaseUrlInstance.get(`/agent/all`, {
    params: {
      ...uri
    },
  })
