import Image from "../images/stories-hero.png"
import ImageTiny from "../images/stories-hero-tiny.png"
import Promise from "../images/promise.png"
import Deborah from "../images/career-deborah.png"
export const heroData = {
  image: Image,
  description:
    "<p>Our way with words and experiences of our people in building Africa's only solution to Financial Inclusion.</p>",
  header: "<span class='underlined'><span>Our Stories<span> </span>",
  buttonText: "",
  alt: "Learn more about Kudi",
  imageTiny: ImageTiny,
  bgSize: "auto",
  type: "stretch",
}
export const carouselData = [
  {
    description:
      "For more than two years, Kudi has grown its Agent network to help underbanked and unbanked communities have access to affordable financial services. Our Agents have proven to be invaluable to their communities.",
    quote: "Driving Financial inclusion",
    title: "",
    image: Promise,
    alt: "Two years of growth",
    author: " ",
  },
  {
    description:
      "The journey to ensuring that all Nigerians and businesses gain access to appropriate, affordable, and timely financial products seems bleak. However, Kudi will not stop asking the right questions, and we would keeping solving them one transaction at a time.",
    quote: "Kudi’s culture is second to none",
    title: "",
    image: Deborah,
    alt: `Our Journey`,
    author: "",
  },
]
export const bannerText = {
  title: "Become an Agent in 3 simple steps",
  list:
    "<li>Download the Kudi App </li> <li>Create an Account in a few minutes</li> <li>Fund your wallet and start transacting</li>",
}
