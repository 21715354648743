import React from "react"
import styles from "./carousel.module.scss"
import cx from "classnames"
const CarouselSlide = ({ index, activeIndex, slide }) => {
  return (
    <li
      className={
        index === activeIndex
          ? cx(styles.carouselSlide, styles.carouselSlideActive)
          : styles.carouselSlide
      }
    >
      <div className={styles.carouselSlideImage}>
        <img src={slide.image} alt={slide.altText} />
      </div>
      <div className={styles.carouselSlideText}>
        {slide && slide.title && (
          <h2 dangerouslySetInnerHTML={{ __html: slide.title }}></h2>
        )}
        {slide && slide.quote && (
          <h4 dangerouslySetInnerHTML={{ __html: slide.quote }}></h4>
        )}
        {slide && slide.content && (
          <p dangerouslySetInnerHTML={{ __html: slide.content }}></p>
        )}
        {slide && slide.description && (
          <h5 dangerouslySetInnerHTML={{ __html: slide.description }}></h5>
        )}
        {slide && slide.author && (
          <p dangerouslySetInnerHTML={{ __html: slide.author }}></p>
        )}
      </div>
    </li>
  )
}
export default CarouselSlide
