import React from "react"
import PropTypes from "prop-types"
import styles from "./button.module.scss"

import { Right } from "../../assets/svg"
const Button = ({ children, icon, ...rest }) => {
  return (
    <button className={styles.Button} {...rest}>
      <span>{children}</span>
      <span>{icon}</span>
    </button>
  )
}
Button.defaultProps = {
  icon: <Right />,
}

Button.propTypes = {
  icon: PropTypes.any.isRequired,
}
export default Button
