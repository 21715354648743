import { ASYNC_START, LOG_ERROR, GET_OPENINGS } from "./types"
import { TelentLyft } from "../../lib"

const getOpenings = payload => ({
  type: GET_OPENINGS,
  payload,
})

export const getOpeningsRequest = pin => dispatch => {
  dispatch({
    type: ASYNC_START,
    subtype: GET_OPENINGS,
  })

  return TelentLyft.getOpenPositions()
    .then(data => {
      if (data.status === 200) {
        dispatch(getOpenings(data.Results))
        return data
      }
      dispatch({
        type: LOG_ERROR,
      })
      return null
    })
    .catch(({ response }) => {
      if (response !== undefined && response.data.message) {
        return dispatch({
          type: LOG_ERROR,
          error: response.data.message,
        })
      }
      return dispatch({
        type: LOG_ERROR,
      })
    })
}
