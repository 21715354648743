import React, { useContext } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import styles from "./banner.module.scss"
import Button from "../../components/button"
import Wrapper from "../../components/wrapper"
import { playStoreLink } from "../../lib"
import { FirebaseContext } from "gatsby-plugin-firebase"
const Banner =  ({ type, description, link, linkName, logo }) => {
  const firebase = useContext(FirebaseContext)

  return (
    <Wrapper bg="map">
      <div className={styles.Banner}>
        <div className={styles.BannerContent}>
          {type && type === "list" ? (
            <>
              <h3>Become an Agent in 3 simple steps</h3>
              <div>
                <ul>
                  <li>Download the Kudi App </li>
                  <li>Create an Account in a few minutes</li>
                  <li>Fund your wallet and start transacting</li>
                </ul>
              </div>
            </>
          ) : (
            <div>
              {logo && <h3 className={styles.logo}>{logo}...No Wahala! </h3>}
              <p dangerouslySetInnerHTML={{ __html: description }}></p>
            </div>
          )}
        </div>
        <div className={styles.BannerButton}>
          <Button
            onClick={() => {
              firebase.analytics().logEvent("get_started")
              if (link !== playStoreLink) {
                firebase.analytics().logEvent(`view_${linkName}`)
                return navigate(`${link}`)
              }
              window.open(playStoreLink, "_blank")
            }}
          >
            Get Started
          </Button>
        </div>
      </div>
    </Wrapper>
  )
}
Banner.propTypes = {
  type: PropTypes.string,
  link: PropTypes.string,
}
Banner.defaultProps = {
  link: playStoreLink,
}
export default Banner
