import { Link } from "gatsby"
import React, { useState } from "react"
import NavList from "./nav-list"
import MobileMenu from "./mobile-nav"
import PropTypes from "prop-types"
import { Logo, Menu } from "../../assets/svg"
import styles from "./header.module.scss"

const Header = () => {
  const [menu, openMenu] = useState(false)
  return (
    <>
      <header className={styles.header}>
        <Link aria-label="Logo" className={styles.logo} to="/">
          <Logo />
        </Link>
        <NavList show={true} className={styles.navlist} />
        <Menu className={styles.menu} onClick={() => openMenu(true)} />
      </header>
      {menu && <MobileMenu closeFn={() => openMenu(false)} />}
    </>
  )
}

Header.propTypes = {
  showNav: PropTypes.bool,
}

export default Header
