import React from "react"
import styles from "./home.module.scss"
import HeroSection from "./home-hero"
import SubHero from "../sub-hero"
import Banner from "../banner"
import Services from "../services"
import {
  subHero as sub,
  bannerText,
  features,
  subHero,
} from "../../assets/pageData/home"
import Features from "./home-features"
import HorizontalScroll from "../horizontalScroll"

import Partners from "./home-partners"
import Agents from "./home-agent"
import BecomeAgent from "./home-become-agent"
import Payment from "./home-payment"
const Home = () => {
  return (
    <div className={styles.Home}>
      <HeroSection />
      <SubHero description={subHero.agent} />
      <Services />
      <HorizontalScroll />
      <SubHero description={subHero.product} />
      <Payment />
      <Features />
      <Banner type="description" description={bannerText} linkName="playstoreLink"/>
      <BecomeAgent />
      <Partners />
      <Agents />
    </div>
  )
}

export default Home
