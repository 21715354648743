import moment from "moment"
import stripHtml from "string-strip-html"
import {insuranceOptions}  from "../assets/pageData/insurance"

export const getInsurance = (slug = "KUDI_LITE") => {
  return insuranceOptions.find(item => item.slug === slug)
}

export const formatCurrency = num => {
  return num
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
}
export const sortByDate = array => {
  return array.sort((a, b) => {
    const dateA = moment(a.pubDate).unix()
    const dateB = moment(b.pubDate).unix()
    return dateB - dateA
  })
}
export const getPublishedDate = date => {
  const now = moment()
  const pubDate = moment(date)
  if (now.get("year") > pubDate.get("year"))
    return pubDate.format("MMM D, YYYY")
  return pubDate.format("MMM D")
}

export const getFirstParagraphText = text => {
  const start = text.indexOf("<p>")
  const end = text.indexOf("</p>")
  return stripHtml(text.substring(start + 3, end))
}
export const truncateText = (text, length, readMore) => {
  return `${text.substring(0, length)}${text.length > length ? readMore : ""}`
}
