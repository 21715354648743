import React, { useState, useEffect } from "react"
import styles from "./carousel.module.scss"
import Wrapper from "../../components/wrapper"
import CarouselSlide from "./carousel-slide"
import CarouselIndicator from "./carousel-indicator"

const Carousel = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  let slidesLength = slides.length - 1

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeIndex === slidesLength) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    }, 6000)
    return () => {
      clearTimeout(timeout)
    }
  }, [setActiveIndex, activeIndex, slidesLength])
  return (
    <>
      <Wrapper bg="blue">
        <div className={styles.carousel}>
          <ul className={styles.carouselSlides}>
            {slides.map((slide, index) => (
              <CarouselSlide
                key={index}
                index={index}
                activeIndex={activeIndex}
                slide={slide}
              />
            ))}
          </ul>
        </div>
      </Wrapper>
      <ul className={styles.carouselIndicators}>
        {slides.map((slide, index) => (
          <CarouselIndicator
            key={`${slide.alt}-${index}`}
            index={index}
            activeIndex={activeIndex}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </ul>
    </>
  )
}
export default Carousel
