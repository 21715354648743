import React, {useState} from "react"
import styles from "./services.module.scss"
import data from "../../assets/pageData/home-servicelist"
import Service from "./service"
const Services = () => {
  const [service, showService] = useState(1)
  return (
    <div className={styles.servicesList}>
      {data &&
        data.map((item, index) => (
          <Service
            key={index}
            icon={item.icon}
            text={item.text}
            header={item.header}
            show={service === index + 1}
            service={service}
            onClick={() => showService(index + 1)}
          />
        ))}
    </div>
  )
}

export default Services
