import React, { Fragment } from "react"
import Container from "../container"
import CardContainer from "../card-container"
import Banner from "../banner"
import ContentHeader from "../content-header"
import Accordion from "../accordion"
import termsData from "../../assets/pageData/terms-and-conditions"

const Terms = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={`Terms and Conditions`}
          backText="Terms and Conditions"
          description={`Welcome to the Kudi Bolt Mobile APP (the “APP”). The APP is operated by Cosmic Intelligent Labs Limited and these terms and conditions of use contains the complete terms that apply to your use of the APP.`}
        />
        <div className="contentHolder">
          <CardContainer>
            <div className="resources">
              {termsData && <Accordion data={termsData} />}
            </div>
          </CardContainer>
        </div>
      </Container>
      <Banner type="list" />
    </Fragment>
  )
}

export default Terms
