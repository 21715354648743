export const websiteServicesBaseUrl = "https://website-service.kudi.ai"
// export const boltBaseUrl = "https://website-service-dev.kudi.ng/" // Dev enivironment
export const boltBaseUrl = "https://website-service-feature-filter-params-for-getting-agents.kudi.ng/" // TEMPORARY Dev enivironment
// export const boltBaseUrl = "https://website-service.kudi.ai";

export const talentLyftBaseUrl = "https://api.talentlyft.com/v2/public/kudi"

export const mediumRssToJsonService = "https://rssfeed.kudi.ai"

export const storiesCategories = new Map([
  ["kudi-stories", "Humans of Kudi"],
  ["kudi-engineering", "Engineering"],
  ["agent-banking", "Agent Banking"],
  ["kudi-hq", "Kudi HQ"],
])
export const playStoreLink =
  "https://play.google.com/store/apps/details?id=ai.kudi.agent&hl=en"

export const NIGERIAN_STATES = [
  "Abia",
  "Adamawa",
  "Akwa Ibom",
  "Anambra",
  "Bauchi",
  "Bayelsa",
  "Benue",
  "Borno",
  "Cross River",
  "Delta",
  "Ebonyi",
  "Edo",
  "Ekiti",
  "Enugu",
  "FCT Abuja",
  "Gombe",
  "Imo",
  "Jigawa",
  "Kaduna",
  "Kano",
  "Katsina",
  "Kebbi",
  "Kogi",
  "Kwara",
  "Lagos",
  "Nasarawa",
  "Niger",
  "Ogun",
  "Ondo",
  "Osun",
  "Oyo",
  "Plateau",
  "Rivers",
  "Sokoto",
  "Taraba",
  "Yobe",
  "Zamfara",
]
