import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Home from "../components/home"
const IndexPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Home" slug="" />
    <Home />
  </Layout>
)

IndexPage.propTypes = {
  location: PropTypes.object,
}

export default IndexPage
