export { default as CaretDown } from "./caret-down.svg"
export { default as CaretLeft } from "./caret-left.svg"
export { default as ChevronUp } from "./chevron-up.svg"
export { default as Chat } from "./chat.svg"
export { default as Close } from "./close.svg"
export { default as Logo } from "./logo.svg"
export { default as Menu } from "./Union.svg"
export { default as Search } from "./search.svg"
export { default as Back } from "./back-arrow.svg"
export { default as StarWallet } from "./star-wallet.svg"
export { default as Privacy } from "./privacy-policy.svg"
export { default as Sector } from "./sector.svg"
export { default as Rect } from "./rect.svg"
export { default as Stat } from "./stat.svg"
export { default as Terms } from "./terms.svg"
export { default as Careers } from "./careers.svg"
export { default as Gym } from "./gym.svg"
export { default as Lunch } from "./free-lunch.svg"
export { default as Health } from "./health_insurance.svg"
export { default as Leave } from "./leave.svg"
export { default as Macbook } from "./macbook.svg"
export { default as Bonus } from "./performance-bonus.svg"
export { default as Right } from "./chevron-right.svg"
export { default as Down } from "./chevron-down.svg"
export { default as WorkspaceSector } from "./workspace-sector.svg"
export { default as Coding } from "./coding.svg"
export { default as Archer } from "./archer.svg"
export { default as Champagne } from "./champagne.svg"
export { default as Profit } from "./profit.svg"
export { default as Rocket } from "./rocket.svg"
export { default as Victory } from "./victory.svg"
export { default as MobileDialog } from "./mobile-dialog.svg"
export { default as InsuranceIllustration } from "./insuranceIllustration.svg"
export { default as InsuranceSub1 } from "./insurance-sub-1.svg"
export { default as InsuranceSub2 } from "./insurance-sub-2.svg"
export { default as Pinnacle } from "./pinnacle.svg"
export { default as PinnacleBlack } from "./pinnacle-black.svg"
export { default as PhoneWarning } from "./phone-warning.svg"
export { default as ChevronLeft } from "./chevron-left.svg"
export { default as KudiLogo } from "./kudi-logo.svg"
export { default as KudiSaveLogo } from "./kudi-save-logo.svg"
export { default as Logout } from "./logout.svg"
export { default as Avatar } from "./avatar.svg"
export { default as SettingShape } from "./setting-shape.svg"
export { default as Spin } from "./spin.svg"
export { default as Umbrella } from "./umbrella.svg"
export { default as BenefitPattern } from "./benefit-pattern.svg"
export { default as CareerPattern } from "./career-pattern.svg"
export { default as WidePattern } from "./wide-pattern.svg"
export { default as WidePatternDark } from "./wide-pattern-dark.svg"
export { default as WidePatternGrey } from "./wide-pattern-grey.svg"
export { default as Blog } from "./blog.svg"
export { default as Eye } from "./eye.svg"
export { default as DropDown } from "./dropdown.svg"
export { default as Add } from "./add.svg"
export { default as Subtract } from "./subtract-icon.svg"
export { default as InstantSettlement } from "./instant-settlement.svg"
export { default as InsuranceIcon } from "./insurance.svg"
export { default as MoneyBag } from "./money-bag.svg"
export { default as ReliableService } from "./reliable-service.svg"
export { default as BusinessFinance } from "./business-and-finance.svg"
export { default as NairaConnection } from "./naira-connection.svg"
export { default as Facebook } from "./facebook.svg"
export { default as Twitter } from "./twitter.svg"
export { default as Instagram } from "./instagram.svg"
export { default as LinkedIn } from "./linkedin.svg"
export { default as InsuranceShield } from "./insurance-shield.svg"
export { default as InsurancePattern } from "./insurance-pattern.svg"
export { default as HealthPattern } from "./health-pattern.svg"
export { default as GymPattern } from "./gym-pattern.svg"
export { default as MacbookPattern } from "./mac-pattern.svg"
export { default as LunchPattern } from "./lunch-pattern.svg"
export { default as QuickWithdrawal } from "./quick-withdrawal.svg"
export { default as SavingsPlan } from "./savings-plan.svg"
export { default as Learn } from "./learn.svg"
export { default as DashLogo } from "./dashlogo.svg"
export { default as WellaHealthLogo } from "./wellahealth.svg"
export { default as RelianceLogo } from "./reliance.svg"
export { default as UlessonLogo } from "./ulesson.svg"
export { default as PiggyVestLogo } from "./piggyvest.svg"
export { default as Check } from "./check.svg"
export { default as AcceptPayment } from "./acceptpayment.svg"
export { default as OnboardUser } from "./onboarduser.svg"
export { default as EasyRemitance } from "./easyremitance.svg"
export { default as ConvinienceSettlement } from "./conviniencesettlement.svg"
export { default as Payments } from "./payments.svg"
export { default as NewCustomer } from "./newcustomer.svg"
export { default as Jet } from "./jet.svg"
export { default as Pricing } from "./pricing.svg"
export { default as Setup } from "./setup.svg"
export { default as Refresh } from "./refresh.svg"
export { default as WelcomeToKudiImage } from "./welcome-to-kudi.svg"
export { default as LostPhone } from "./move-phone.svg"
export { default as PlayIcon } from "./playicon.svg"
export { default as CaretRightIcon } from "./caret-right-icon.svg"
export { default as EmptyTableIcon } from './empty-table.svg';

