import React from "react"
import PropTypes from "prop-types"
import styles from "./container.module.scss"
import cx from "classnames"

const Container = ({ children, className, style }) => {
  return (
    <div style={style} className={cx(styles.container, className)}>
      {children}
    </div>
  )
}

Container.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Container
