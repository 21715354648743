import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUs from "../components/about-us"

const AboutUsPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO
      title="About Us"
      slug="about-us"
      description="Learn about what makes us tick and the people who keep us moving"
      keywords="Become a Kudi Agent, All about Kudi Hq, Kudi Insurance, money, Financial services in Africa, Loans in Nigeria, Agency Banking, Innovative Agency banking, Banking in local communities"
    />
    <AboutUs />
  </Layout>
)

AboutUsPage.propTypes = {
  location: PropTypes.object,
}

export default AboutUsPage
