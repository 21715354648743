import React, { useContext } from "react"
import { Link } from "gatsby"
import CookieConsent from "react-cookie-consent"
import Button from "../../components/button"
import styles from "./footer.module.scss"
import Layout from "../layout"
import { Facebook, Instagram, Twitter, LinkedIn } from "../../assets/svg"

const Footer = () => {
  // const [openFooter, setOpenFooter] = useState(false)
  const layoutContextValue = useContext(Layout.context)
  const { disableHeaderParam } = layoutContextValue
  return (
    <footer className={styles.footer}>
      <div>
        <h3> Resources</h3>
        <ul>
          <li>
            <Link to={`/faq`}>FAQ</Link>
          </li>
          <li>
            <Link to={`/insurance`}>Cash Insurance</Link>
          </li>
          <li>
            <Link to={`/privacy-policy`}>Privacy Policy</Link>
          </li>
          <li>
            <Link to={`/cookies`}>Cookie Statement</Link>
          </li>
          <li>
            <Link to={`/terms`}>Terms & Conditions</Link>
          </li>
          <li>
            <Link to={`/third-party-terms`}>Third Party Terms of Use</Link>
          </li>
          {/* <li>
            <Link to={`/fees${disableHeaderParam}`}>Kudi Charges</Link>
          </li> */}
          <li>
            <Link to={`/covid19`}>Covid 19</Link>
          </li>
          <li>
            <Link to={`/locate-agents`}>Find/Locate Agents</Link>
          </li>
        </ul>
      </div>

      <div className={styles.community}>
        <h3> Our Products</h3>
        <ul>
          {/* <li>
          <Link to={`/insurance${disableHeaderParam}`}>Insurance</Link>
          </li> */}
          <li>
            <a
              rel="noopener noreferrer"
              target="_blank"
              href="https://chat.kudi.ai"
            >
              Kudi Chatbot
            </a>
          </li>
          <li>
            <Link to={`/savings`}>Savings</Link>
          </li>
          <li>
            <Link to={`/marketplace`}>Marketplace</Link>
          </li>
          <li>{/* <Link to={`/dash`}>Dash</Link> */}</li>
        </ul>
      </div>

      <div>
        <h3>Nigeria</h3>
        <ul>
          <li>
            19b Bosun Adekoya street, Lekki Phase 1, Lagos, Nigeria. 01 888 5008
          </li>
          <li>support@kudi.co</li>
        </ul>
        <div></div>
      </div>
      <div>
        <h3>United states</h3>
        <ul>
          <li>1776 Sacremento st Apt 311 SAN Francisco CA 94109</li>
          <li>
            <div className={styles.social}>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://facebook.com/kudihq"
              >
                <Facebook />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://twitter.com/kudihq"
              >
                <Twitter />
              </a>
              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://ng.linkedin.com/company/kudi.ai"
              >
                <LinkedIn />
              </a>

              <a
                rel="noopener noreferrer"
                target="_blank"
                href="https://www.instagram.com/kudihq/"
              >
                <Instagram />
              </a>
            </div>
          </li>
        </ul>
      </div>
      <CookieConsent
        enableDeclineButton
        location="bottom"
        buttonText={<Button className={styles.Button}>Accept all</Button>}
        declineButtonText="Decline"
        cookieName="myAwesomeCookieName2"
        disableStyles={true}
        buttonClasses={styles.hideLibraryBtn}
        declineButtonClasses={styles.DeclineButtonStyle}
        containerClasses={styles.CookieContainer}
        contentClasses={styles.CookieContent}
        buttonWrapperClasses={styles.ButtonWrapper}
      >
        <div>
          This website uses cookies to optimize your experience and to provide
          us insight on how to interact with the site. All information shared
          with us through cookies are secure and covered by our data privacy
          obligations. For more info, see our{" "}
          <Link to={`/cookies`} style={{ textDecoration: "underline" }}>
            Cookie Policy.
          </Link>
        </div>
      </CookieConsent>
    </footer>
  )
}

export default Footer
