module.exports = [
  {
    name: "Third-Party Terms of Use",
    slug: "thrid-party-terms",
    description: `<p>Certain features of our services and products may utilize the services and/or products of third-party vendors and business partners, which services and/or products may include software, information, data or other services. Certain of these vendors and business partners require Users who utilize such features to agree to additional terms and conditions.</p><br/> 

    <p>In no event shall Cosmic be liable to the Agent or any other entity for any direct, special, consequential, incidental or direct claims, damages or loss caused or alleged to be caused by or in connection with the use or reliance on the services available to such third party.
    </p><br/>
    <p>This page identifies third-party terms and conditions that are required by such third-party vendors and business partners as they apply to the features/products set forth below. <strong>Your use of such features constitutes your agreement to be bound by these additional terms and conditions.</strong> These third-party terms are subject to change at such a third-party’s discretion.
    </p>
    `,
  },
  {
    name: "Pinnacle Risk Advisory",
    slug: "pinnacle-risk-advisory",
    description: `<p>Pinnacle Risk Advisory Limited [PRAL] provides Agent Insurance Cover Services including risk management, insurance, and claims management services. PRAL is an unaffiliated third-party vendor. PRA requires that Users/Agent who utilize the Kudi Money Insurance Service agree to additional terms and conditions set forth <a href="http://www.pinnacleriskng.com/terms" target="_blank" rel="noopener noreferrer"><strong>HERE</strong></a>
    </p><br/> 

    <p>Your purchase/use of the Kudi Money Insurance constitutes acceptance of and agreement to all of the terms and conditions contained herein.</p><br/> 
    `,
  },
  {
    name: "Updates",
    slug: "last",
    description: `<p>Please consult these <strong>Terms of Use</strong> and <strong>Third Party Terms of Use</strong>  regularly to acquit yourself with any changes that may apply. You can review the most current version of these Terms and Conditions by clicking on the APP’s “Terms of Use” and “Third Party Terms of Use”  link.</p><br/> 

    <p>BY CLICKING “I AGREE,” OR BY OTHERWISE SIGNING-UP OR PURCHASING A KUDI MONEY INSURANCE COVER, YOU AGREE TO BE BOUND BY OUR TERMS AND CONDITIONS AND THIRD-PARTY TERMS OF USE.</p>
    `,
  },
]
