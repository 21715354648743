import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PrivacyPolicy from "../components/privacy-policy"

const PrivacyPolicyPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Privacy Policy" slug="privacy-policy" />
    <PrivacyPolicy/>
  </Layout>
)

PrivacyPolicyPage.propTypes = {
  location: PropTypes.object,
}

export default PrivacyPolicyPage
