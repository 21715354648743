import React, { useState } from "react"
import styles from "./accordion.module.scss"
import { Add, Subtract } from "../../assets/svg"
import cx from "classnames"
const Accordion = ({ data }) => {
  const [active, setActive] = useState(data[0].name)
  const toggle = accordionItem => {
    return setActive(accordionItem.name === active ? "" : accordionItem.name)
  }
  return (
    data &&
    data.map((item, index) => (
      <div key={index} className={styles.Accordion}>
        <button
          onKeyDown={() => toggle(item)}
          onClick={() => toggle(item)}
          className={styles.AccordionHeader}
        >
          <p className={active === item.name ? styles.active : " "}>
            {item.name}
          </p>
          {active === item.name ? <Subtract /> : <Add />}
        </button>

        <div
          className={cx(styles.AccordionContent, {
            [styles.AccordionContentActive]: active === item.name,
          })}
        >
          <div
            className={styles.AccordionText}
            dangerouslySetInnerHTML={{ __html: item.description }}
          />
        </div>
      </div>
    ))
  )
}
export default Accordion
