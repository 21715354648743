import { window } from "browser-monads"
import { APP_LOAD, REDIRECT } from "./types"
import { setToken } from "../../lib"

export const onAppLoad = () => {
  const token = window.localStorage.getItem("jwt")
  const kyc =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("kyc"))
      : {}
  const wallet =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("wallet"))
      : {}
  const tokenExpires =
    typeof window !== "undefined"
      ? JSON.parse(window.localStorage.getItem("tokenExpires"))
      : {}
  const selectedPlan = window.localStorage.getItem("selectedPlan")
  if (token) {
    setToken(token)
  }
  return {
    type: APP_LOAD,
    token,
    kyc,
    wallet,
    tokenExpires,
    selectedPlan,
  }
}

export const onRedirect = () => ({ type: REDIRECT })
