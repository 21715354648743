import React from "react"
import cx from "classnames"
import styles from "./services.module.scss"
const Service = ({
  icon: Icon,
  text,
  header,
  show,
  onClick,
  delay,
  duration,
}) => {
  return (
    <div
      className={cx(styles.service, { [styles.active]: show })}
      onClick={onClick}
    >
      <div className={styles.serviceHeader}>
        <h4 dangerouslySetInnerHTML={{ __html: header }} />
        <small>Read More</small>
      </div>
      <div className={styles.serviceBody}>
        {Icon && <Icon />}
        <p
          className={styles.description}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </div>
    </div>
  )
}

export default Service
