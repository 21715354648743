import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import LocateAgents from "../components/locate-agents"

const LocateAgentsPage = ({ location }) => {
  return (
    <Layout showNav location={location}>
      <SEO
        title="Locate Agents"
        description="Find Kudi Agents and their locations across various cities in Nigeria"
        slug="locate-agents"
      />
      <LocateAgents />
    </Layout>
  )
}

export default LocateAgentsPage
