import Yinka from "../images/yinka.jpg"
import Pelumi from "../images/pelumi.png"
import ImageTiny from "../images/about-hero-tiny.png"
import Image from "../images/about-hero.png"
//heroData
export const heroData = {
  image: Image,
  description:
    "<p>We want to build a financial inclusive world with everyone, for everyone.</p>",
  header: "<span class='underlined'><span>About us</span></span>",
  buttonText: "",
  alt: "Providing financial services",
  imageTiny: ImageTiny,
  bgSize: "43.33em",
  type: "stretch",
  page: "about",
}

//carouselData
export const carouselData = [
  {
    content: "",
    quote: `In the middle of 2016, after another frustrating banking experience, Yinka and Pelumi founded Kudi`,
    title: "our Story",
    image: Yinka,
    alt: "Kudi Story by Yinka",
    author: "",
  },
  {
    content: "",
    image: Pelumi,
    alt: `Kudi story by Pelumi`,
    author: "",
    title: "KUDI IS BRIDGING <br/> THE GAP",
    quote:
      "Driven to make banking experience seamless in Africa in our local communities",
  },
]
export const subValues =
  "<p>Our <span class='underlined--black'> <span> Core Values</span></span></p>"
export const subHero =
  "<p>Enjoy our </p><p><span class='underlined--black'> <span>timeline video</span></span></p>"

export const values = [
  // {
  //   id: "open-communication",
  //   name: "Open Communication ",
  //   description:
  //     "<p> Without open communication. The idea that can change the company can come from anywhere, anyone.</p>",
  // },

  {
    name: "Empathy",
    id: "empathy",
    description:
      "<p>Show deep respect for fellow employees and show that you care about others genuinely.</p>",
  },
  {
    name: "Ownership",
    id: "ownership",
    description:
      "<p>Our employees are expected to take full ownership of their roles and profer innovative solutions to their teams.</p>",
  },
  {
    name: "Growth",
    id: "growth",
    description:
      "<p>We encourage employees to grow every day by taking on tasks that look scary.</p>",
  },
  {
    name: "Learning",
    id: "learning",
    description:
      "<p>Life in itself is actually a learning curve and the process of achieving excellence in your work should always continue.</p>",
  },
]
