export const contact = {
  columns: [
    { key: "markets", render: "Markets" },
    {
      key: "zone",
      render: "Zone",
    },
    {
      key: "zonal_head",
      render: "Zonal Head",
    },
    {
      key: "contact",
      render: "Contact",
    },
  ],
  data: [
    {
      markets: `<ul><li>Addo Market</li> 
        <li>Ajah</li>
        <li>Jakande</li>
        <li>New Market </li>
        <li>Sangotedo</li>
        </ul>`,
      zone: "Lagos Island 1",
      zonal_head: "Eyimisan",
      contact: "09053771156",
    },

    {
      markets: `<ul><li>Apongbon</li> 
        <li>Balogun</li>
        <li>Ebute Ero</li>
        <li>Idumota</li>
        <li>Tinubu/Oluwole/ Tone Jones</li>
        </ul>`,
      zone: "Lagos Island 2",
      zonal_head: "Oluwatoyin",
      contact: "09053771075",
    },
    {
      markets: `<ul><li>Agege</li> 
        <li>Dopemu Aluminium</li>
        <li>Dopemu Food</li>
        <li>Iyana Ipaja</li>
        <li>Oshodi</li>
        </ul>`,
      zone: "Lagos Mainland 1",
      zonal_head: "Udeme",
      contact: "09053771120",
    },
    {
      markets: `<ul><li>Aguda</li> 
        <li>Balogun</li>
        <li>Article</li>
        <li>Ifesowapo</li>
        <li>Odunade/Surualaba</li>
        <li>Balogun</li>
        </ul>`,
      zone: "Lagos Mainland 2",
      zonal_head: "Kingsley",
      contact: "09053771004",
    },
  ],
}

export const title = `Markets and <br/>Zonal Heads`
