import { combineReducers } from "redux"
import common from "./common"
import insurance from "./insurance"
import auth from "./auth"
import jobOpenings from "./job-openings"
import stories from "./stories"
import locateAgents from "./locate-agents"

export default combineReducers({
  common,
  insurance,
  auth,
  jobOpenings,
  stories,
  locateAgents
})
