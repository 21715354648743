import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MarketList from "../components/markets"

const KudiCharges = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="KudiSave: Markets" slug="markets" />
    <MarketList />
  </Layout>
)

KudiCharges.propTypes = {
  location: PropTypes.object,
}

export default KudiCharges
