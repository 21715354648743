import React from "react"
import ProgressiveImage from "react-progressive-image"
import PropTypes from "prop-types"
import { connect } from "react-redux"
import Wrapper from "../wrapper"
import styles from "./career.module.scss"
import HeroSection from "../hero-section"
import SubHero from "../sub-hero"
import Benefits from "../career-benefits"
import { benefitsData, heroData, subHero } from "../../assets/pageData/careers"
import Position from "./position"
import billionTransactions from "../../assets/images/career-1B.png"
import billionTransactionsTiny from "../../assets/images/career-1B-tiny.png"
import Dance from "../../assets/images/career-dance.png"
import DanceTiny from "../../assets/images/career-dance-tiny.png"
import Food from "../../assets/images/career-food.png"
import FoodTiny from "../../assets/images/career-food-tiny.png"
import Breakfast from "../../assets/images/career-breakfast-meeting.png"
import BreakfastTiny from "../../assets/images/breakfast-meeting-tiny.png"
import PartyLadies from "../../assets/images/career-ladies-party.png"
import PartyLadiesTiny from "../../assets/images/career-ladies-party-tiny.png"
import DayTiny from "../../assets/images/career-a-day-tiny.png"
import Day from "../../assets/images/career-a-day.png"
import { Spin, CareerPattern } from "../../assets/svg"
import Testimonial from "./testimonial"

const Career = ({ jobs, inProgress }) => {

  console.log(" jobs",  jobs)
  return (
    <>
      <HeroSection data={heroData} />
      <Wrapper bg="white">
      <SubHero description={subHero} />
      </Wrapper>
      <Benefits benefits={benefitsData} />
      <div className={styles.container}>
        <div className={styles.pictures}>
          <div className={styles.posAbsolute}>
            <div>
              Humans
              <br /> behind
              <br /> the tech
            </div>
            <CareerPattern />
          </div>
          <div className={styles.row1}>
            <ProgressiveImage src={Breakfast} placeholder={BreakfastTiny}>
              {src => (
                <img className={styles.item1} src={src} alt="Achieving Together at Kudi" />
              )}
            </ProgressiveImage>

            <ProgressiveImage src={Food} placeholder={FoodTiny}>
              {src => <img className={styles.item2} src={src} alt="Food" />}
            </ProgressiveImage>
            <ProgressiveImage src={Dance} placeholder={DanceTiny}>
              {src => (
                <img className={styles.item3} src={src} alt="Fun times at kudi" />
              )}
            </ProgressiveImage>
            <ProgressiveImage
              src={billionTransactions}
              placeholder={billionTransactionsTiny}
            >
              {src => (
                <img
                  className={styles.item4}
                  src={src}
                  alt="1 billion transactions"
                />
              )}
            </ProgressiveImage>
            <ProgressiveImage src={PartyLadies} placeholder={PartyLadiesTiny}>
              {src => <img className={styles.item5} src={src} alt="Fun" />}
            </ProgressiveImage>
            <ProgressiveImage src={Day} placeholder={DayTiny}>
              {src => <img className={styles.item6} src={src} alt="Cake" />}
            </ProgressiveImage>
          </div>
        </div>

        <div id="positions" className={styles.positions}>
          <h2>
            Open <span className="underlined--black"><span>positions.</span></span>
          </h2>
          <h4>
            At Kudi, we believe that people of all class should have access to
            financial services and seamlessly make transactions. While we’ve
            made significant progress, we won’t stop searching for the right
            people that fit into our culture to provide solutions.
          </h4>
          {jobs?.map((job, ind)=>
            < Position data={job} key={ind}/>)}
          {inProgress && !jobs && <Spin className={styles.positionsSpinner} />}
          {!inProgress && jobs&& jobs.length===0 && <h3 className={styles.empty}>No available position for now!</h3>}
        </div>
      </div>

      <Testimonial />
    </>
  )
}

Career.propTypes = {
  jobs: PropTypes.array,
  inProgress:PropTypes.bool,
}

const mapStateToProps = ({ jobOpenings: { jobs, inProgress } }) => {
  return {
    jobs,
    inProgress
  }
}

export default connect(mapStateToProps, null)(Career)
