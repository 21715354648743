import React from "react"
import PropTypes from "prop-types"
import Register from "../components/register"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Router } from "@reach/router"
import "react-toastify/dist/ReactToastify.css"

const RegisterPage = ({ pageContext, location, ...props }) => {
  return (
    <Layout showNav location={location}>
      <SEO
        title="Kudi Verify Phone Number"
        description="Kudi Verify and complete agent registration."
        slug="Verify Phone"
      />
      <Router basepath="/reg">
        <Register path="/:regId" location={location} regId={props.regId} />
      </Router>
    </Layout>
  )
}

RegisterPage.propTypes = {
  location: PropTypes.object,
}

export default RegisterPage
