import React, { useContext } from "react"
import { Link } from "gatsby"
import Layout from "../layout"
import styles from "./questions.module.scss"

const Questions = ({ faqs }) => {
  const layoutContextValue = useContext(Layout.context)
  const { disableHeaderParam } = layoutContextValue
  return (
    <div className={styles.question}>
      {faqs &&
        faqs.map(({ id, name, resources }, index) => {
          return (
            <div key={index} className={styles.faqs}>
              <h3 className={styles.header} id={id}>
                {name}
              </h3>
              <ul>
                {resources.map(item => (
                  <Link to={`/faq/${id}/${item.id}${disableHeaderParam}`}>
                    <li key={item.id}>{item.name}</li>
                  </Link>
                ))}
              </ul>
            </div>
          )
        })}
    </div>
  )
}

export default Questions
