import {
  FETCH_AGENTS,
  FETCH_AGENTS_START,
  FETCH_AGENTS_STOP,
} from "../actions/types"

const initialState = {
  data: [],
  totalPages: 1,
  numberOfElements: 20,
  totalElements: 1,
  currentPage: 0,
  loading: false,
  filters: {},
  search: {
    searchValue: "",
    searchParamType: "",
  },
  pagination: {
    page: 0,
    pageSize: 10,
  },
  isLastPage: false,
}

export default (state = initialState, { type, payload, subSection }) => {
  switch (type) {
    case FETCH_AGENTS:
      return {
        ...state,
        data:
          state.pagination.page > 0
            ? [...new Set([...state.data, ...payload.data.content])]
            : payload.data.content,
        totalPages: payload.data.totalPages,
        numberOfElements: payload.data.numberOfElements,
        totalElements: payload.data.totalElements,
        currentPage: payload.data.number,
        isLastPage: payload.data.number === payload.data.totalPages - 1,
        loading: false,
      }

    case FETCH_AGENTS_START:
      return {
        ...state,
        loading: true,
      }

    case FETCH_AGENTS_STOP:
      return {
        ...state,
        loading: false,
      }

    default:
      return state
  }
}
