import React from "react"
import PropTypes from "prop-types"
import Welcome from "../components/welcome-to-kudi"
import Layout from "../components/layout"
import SEO from "../components/seo"
import 'react-toastify/dist/ReactToastify.css';


const WelcomePage = ({ pageContext, location, ...props }) => {
  return (
    <Layout showNav location={location}>
      <SEO
        title="Welcome to Kudi"
        description="Welcome to Kudi. Download our app"
        slug="Welcome to Kudi. Download our app"
      />
      
      <Welcome regId={props["*"]} />
    </Layout>
  )
}

WelcomePage.propTypes = {
  location: PropTypes.object,
}

export default WelcomePage
