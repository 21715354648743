import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ThirdPartyContent from "../components/third-party"

const ThirdParty = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Third Party Terms of Use" slug="third-party-terms" />
    <ThirdPartyContent />
  </Layout>
)

ThirdParty.propTypes = {
  location: PropTypes.object,
}

export default ThirdParty
