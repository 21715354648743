import React from "react"
import PropTypes from "prop-types"
import styles from "./career-benefits.module.scss"

const Benefits = ({ benefits }) => {
  return (
    <div className={styles.Benefits}>
      {benefits &&
        benefits.map(({ text, header, icon: Icon }, i) => (
          <div className={styles.BenefitsContainer} key={i}>
            <div>
              <h3 dangerouslySetInnerHTML={{ __html: header }}></h3>{" "}
              {Icon && <Icon />}
            </div>
            <p>{text}</p>
          </div>
        ))}
    </div>
  )
}
Benefits.propTypes = {
  description: PropTypes.string,
}
export default Benefits
