import React from "react"
import styles from "./stories.module.scss"
import ContentLoader from "react-content-loader"

const Loader = () => {
  let random = Math.ceil(Math.random() * 1110)
  return (
    <>
      {Array(3)
        .fill()
        .map((_, index) => (
          <div className={styles.loader} key={index}>
            <ContentLoader
              primarycolor={"#F0F4F9"}
              secondarycolor={"#fdfdfd"}
              height={"416"}
              key={`sh-${index}${random}`}
              width={"100%"}
            >
              <rect x="0" y="0" rx="5" ry="0" width="900" height="416" />
            </ContentLoader>
            <ContentLoader
              primarycolor={"#F0F4F9"}
              secondarycolor={"#fdfdfd"}
              height={415}
              key={`sh-u${index}${random}`}
              width="500"
            >
              <rect x="15" y="15" rx="4" ry="4" width="130" height="10" />
              <rect x="155" y="15" rx="3" ry="3" width="130" height="10" />
              <rect x="295" y="15" rx="3" ry="3" width="165" height="10" />
              <rect x="470" y="15" rx="3" ry="3" width="35" height="10" />

              <rect x="15" y="40" rx="3" ry="3" width="90" height="10" />
              <rect x="115" y="40" rx="3" ry="3" width="60" height="10" />
              <rect x="185" y="40" rx="3" ry="3" width="100" height="10" />
              <rect x="295" y="40" rx="3" ry="3" width="120" height="10" />
              <rect x="425" y="40" rx="3" ry="3" width="80" height="10" />

              <rect x="15" y="115" rx="3" ry="3" width="130" height="10" />
              <rect x="160" y="115" rx="3" ry="3" width="120" height="10" />
              <rect x="290" y="115" rx="3" ry="3" width="95" height="10" />
              <rect x="395" y="115" rx="3" ry="3" width="125" height="10" />

              <rect x="15" y="140" rx="4" ry="4" width="130" height="10" />
              <rect x="155" y="140" rx="3" ry="3" width="130" height="10" />
              <rect x="295" y="140" rx="3" ry="3" width="165" height="10" />
              <rect x="470" y="140" rx="3" ry="3" width="35" height="10" />

              <rect x="15" y="165" rx="3" ry="3" width="90" height="10" />
              <rect x="115" y="165" rx="3" ry="3" width="60" height="10" />
              <rect x="185" y="165" rx="3" ry="3" width="100" height="10" />
              <rect x="295" y="165" rx="3" ry="3" width="120" height="10" />
              <rect x="425" y="165" rx="3" ry="3" width="80" height="10" />

              <rect x="15" y="190" rx="4" ry="4" width="130" height="10" />
              <rect x="155" y="190" rx="3" ry="3" width="130" height="10" />
              <rect x="295" y="190" rx="3" ry="3" width="165" height="10" />
              <rect x="470" y="190" rx="3" ry="3" width="35" height="10" />

              <rect x="15" y="215" rx="3" ry="3" width="130" height="10" />
              <rect x="160" y="215" rx="3" ry="3" width="120" height="10" />
              <rect x="290" y="215" rx="3" ry="3" width="95" height="10" />
              <rect x="395" y="215" rx="3" ry="3" width="125" height="10" />

              <rect x="15" y="240" rx="4" ry="4" width="130" height="10" />
              <rect x="155" y="240" rx="3" ry="3" width="130" height="10" />
              <rect x="295" y="240" rx="3" ry="3" width="165" height="10" />
              <rect x="470" y="240" rx="3" ry="3" width="35" height="10" />

              <rect x="15" y="265" rx="3" ry="3" width="90" height="10" />
              <rect x="115" y="265" rx="3" ry="3" width="60" height="10" />
              <rect x="185" y="265" rx="3" ry="3" width="100" height="10" />
              <rect x="295" y="265" rx="3" ry="3" width="120" height="10" />
              <rect x="425" y="265" rx="3" ry="3" width="80" height="10" />

              <rect x="15" y="385" rx="3" ry="3" width="130" height="10" />
              <rect x="155" y="385" rx="3" ry="3" width="130" height="10" />
            </ContentLoader>
          </div>
        ))}
      {Array(3)
        .fill()
        .map((_, index) => (
          <div className={styles.loaderMobile}>
            <ContentLoader
              primarycolor={"#F0F4F9"}
              secondarycolor={"#fdfdfd"}
              height={"250"}
              key={`shi-${index}${random}`}
              width={"100%"}
            >
              <rect x="0" y="0" rx="5" ry="0" width="100%" height="416" />
            </ContentLoader>
            <ContentLoader
              primarycolor={"#F0F4F9"}
              secondarycolor={"#fdfdfd"}
              height={415}
              key={`sh-${index}`}
              width="100%"
            >
              <rect x="3" y="15" rx="2" ry="2" width="145" height="8" />
              <rect x="155" y="15" rx="2" ry="2" width="130" height="8" />
              <rect x="295" y="15" rx="2" ry="2" width="15" height="8" />

              <rect x="3" y="40" rrx="2" ry="2" width="100" height="8" />
              <rect x="295" y="40" rx="2" ry="2" width="120" height="8" />

              <rect x="3" y="115" rx="2" ry="2" width="145" height="8" />
              <rect x="160" y="115" rx="2" ry="2" width="120" height="8" />
              <rect x="290" y="115" rx="2" ry="2" width="95" height="8" />
              <rect x="395" y="115" rx="2" ry="2" width="125" height="8" />

              <rect x="3" y="140" rx="2" ry="2" width="145" height="8" />
              <rect x="155" y="140" rx="2" ry="2" width="130" height="8" />
              <rect x="295" y="140" rx="2" ry="2" width="165" height="8" />
              <rect x="470" y="140" rx="2" ry="2" width="35" height="8" />

              <rect x="3" y="165" rx="2" ry="2" width="90" height="8" />
              <rect x="115" y="165" rx="2" ry="2" width="60" height="8" />
              <rect x="185" y="165" rx="2" ry="2" width="100" height="8" />
              <rect x="295" y="165" rx="2" ry="2" width="120" height="8" />
              <rect x="425" y="165" rx="2" ry="2" width="80" height="8" />

              <rect x="3" y="190" rx="2" ry="2" width="130" height="8" />
              <rect x="155" y="190" rx="2" ry="2" width="130" height="8" />
              <rect x="295" y="190" rx="2" ry="2" width="165" height="8" />
              <rect x="470" y="190" rx="2" ry="2" width="35" height="8" />

              <rect x="3" y="215" rx="2" ry="2" width="130" height="8" />
              <rect x="160" y="215" rx="2" ry="2" width="120" height="8" />
              <rect x="290" y="215" rx="2" ry="2" width="95" height="8" />
              <rect x="395" y="215" rx="2" ry="2" width="125" height="8" />

              <rect x="3" y="240" rx="2" ry="2" width="130" height="8" />
              <rect x="155" y="240" rx="2" ry="2" width="130" height="8" />
              <rect x="295" y="240" rx="2" ry="2" width="165" height="8" />
              <rect x="470" y="240" rx="2" ry="2" width="35" height="8" />

              <rect x="3" y="265" rx="2" ry="2" width="90" height="8" />
              <rect x="115" y="265" rx="2" ry="2" width="60" height="8" />
              <rect x="185" y="265" rx="2" ry="2" width="100" height="8" />
              <rect x="295" y="265" rx="2" ry="2" width="120" height="8" />
              <rect x="425" y="265" rx="2" ry="2" width="80" height="8" />

              <rect x="3" y="385" rx="2" ry="2" width="130" height="8" />
              <rect x="155" y="385" rx="2" ry="2" width="130" height="8" />
            </ContentLoader>
          </div>
        ))}
    </>
  )
}

export default Loader
