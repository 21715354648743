import React from "react"
import styles from "./home-carousel.module.scss"
import cx from "classnames"
const Slide = ({ index, activeIndex, slide }) => {
  return (
    <li
      className={
        index === activeIndex
          ? cx(styles.Slide, styles.SlideActive)
          : styles.Slide
      }
    >
      <div className={styles.SlideText}>
      <div className={styles.quoteSign}>“</div>
        {slide && slide.text && (
          <p dangerouslySetInnerHTML={{ __html: slide.text }}></p>
        )}
        {slide && slide.author && (
          <h4 dangerouslySetInnerHTML={{ __html: slide.author }}></h4>
        )}
      </div>
    </li>
  )
}
export default Slide
