import React from "react"
import PropTypes from "prop-types"
import FaqLayout from "../components/faq-layout"
import SEO from "../components/seo"
import Questions from "../components/questions"
import data from "../assets/pageData/faq"

const FaqPage = ({ location }) => {
  return (
    <FaqLayout location={location} className={"w100"}>
      <SEO description="Have a question? Take a look at the questions we get asked the most!" slug="faq"/>
      <Questions faqs={data} />
    </FaqLayout>
  )
}

FaqPage.propTypes = {
  location: PropTypes.object,
}

export default FaqPage
