import React from "react";
import styles from "./styles.module.scss";

const ContentFooter = ({text}) => {
  return <div className={styles.container}>
    <p>{text}</p>
  </div>

};

export default ContentFooter;
