import React from "react"
import ProgressiveImage from "react-progressive-image"
import Button from "../../components/button"
import { heroData } from "../../assets/pageData/home"
import ImageTiny from "../../assets/images/home-hero-tiny.png"
import Image from "../../assets/images/home-hero.gif"
import styles from "./home.module.scss"

const Hero = () => {
  const { header, description, buttonText } = heroData
  return (
    <div className={styles.ContainerHolder}>
      <section className={styles.hero}>
        <div className={styles.heroContainer}>
          <h2 dangerouslySetInnerHTML={{ __html: header }}></h2>
          <div
            className={styles.heroDescription}
            dangerouslySetInnerHTML={{ __html: description }}
          ></div>
          {buttonText && (
            <Button
              onClick={() =>
                window.open(
                  "https://play.google.com/store/apps/details?id=ai.kudi.agent&hl=en",
                  "_blank"
                )
              }
            >
              {buttonText}
            </Button>
          )}
        </div>
        <div className={styles.heroImage}>
          <ProgressiveImage src={Image} placeholder={ImageTiny}>
            {src => <img src={src} alt="affordable financial services" />}
          </ProgressiveImage>
        </div>
      </section>
    </div>
  )
}

export default Hero
