import React from "react"
import ProgressiveImage from "react-progressive-image"
import HeroSection from "../hero-section"
import Features from "../features"
import Banner from "../banner"
import SubHero from "../sub-hero"
import KudiWahala from "../../assets/images/savings-wahala.png"
import KudiWahalaTiny from "../../assets/images/savings-wahala-tiny.jpg"
import KudiKampe from "../../assets/images/savings-kampe.png"
import KudiKampeTiny from "../../assets/images/savings-kampe-tiny.jpg"
import KudiOga from "../../assets/images/savings-oga.png"
import KudiOgaTiny from "../../assets/images/savings-oga-tiny.jpg"
import { Learn, KudiSaveLogo } from "../../assets/svg"
import {
  heroData,
  subHero as Hero,
  subProduct,
  bannerText,
  features,
} from "../../assets/pageData/savings"
import style from "./savings.module.scss"
import { Link } from "gatsby"
const Savings = () => {
  return (
    <div className={style.saving}>
      <HeroSection data={heroData} />
      <Features features={features} subHero={Hero} />
      <div className={style.learn}>
        <h2>
          Save securely and be prepared for <br />
          financial emergencies.
        </h2>
        <a
          rel="noopener noreferrer"
          target="_blank"
          href="https://medium.com/kudisave"
        >
          <Learn />
          Learn more about KudiSave
        </a>
      </div>
      <SubHero description={subProduct} />
      <div className={style.products}>
        <div className={style.productsItem}>
          <div className={style.Image}>
            <ProgressiveImage src={KudiWahala} placeholder={KudiWahalaTiny}>
              {src => <img src={src} alt="Kudi No Wahala" />}
            </ProgressiveImage>
          </div>
          <div className={style.Text}>
            <h2> Kudi No-Wahala </h2>
            <h3>
              N500 - N2,000 daily
              <br /> N15,000 - N60,000 monthly
            </h3>
            <p>
              You can access up to 50% of your savings in soft loan and stand
              the opportunity to win fantastic prizes in giveaways. Your first
              day contribution serves as savings charge.
            </p>
          </div>
        </div>
        <div className={style.productsItem}>
          <div className={style.Image}>
            <ProgressiveImage src={KudiKampe} placeholder={KudiKampeTiny}>
              {src => <img src={src} alt="Kudi kampe" />}
            </ProgressiveImage>
          </div>
          <div className={style.Text}>
            <h2> Kudi Kampe</h2>
            <h3>
              N3,000 - N5,000 daily
              <br /> N90,000 - N150,000 monthly
            </h3>
            <p>
              You can access up to 50% of your savings in soft loan and stand
              the opportunity to win fantastic prizes in giveaways. Your first
              day contribution serves as savings charge.
            </p>
          </div>
        </div>
        <div className={style.productsItem}>
          <div className={style.Image}>
            <ProgressiveImage src={KudiOga} placeholder={KudiOgaTiny}>
              {src => <img src={src} alt="Kudi oga" />}
            </ProgressiveImage>
          </div>
          <div className={style.Text}>
            <h2> Kudi Oga</h2>
            <h3>
              N6,000 and above daily
              <br /> N180,000 monthly.
            </h3>
            <p>
              You can access up to 50% of your savings in soft loan and stand
              the opportunity to win fantastic prizes in giveaways. Half of your
              first day contribution serves as savings charge.
            </p>
          </div>
        </div>
      </div>
      <div className={style.Terms}> <Link to={`/savings-terms-and-conditions`}> Terms and Conditions Apply</Link></div>
      <Banner
        type="description"
        description={bannerText}
        link="/markets"
        logo={<KudiSaveLogo />}
        linkName="markets"
      />
    </div>
  )
}
export default Savings
