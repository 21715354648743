module.exports = [
  {
    id: "001",
    name: "1. INTRODUCTION",
    description:
      "<p>The Kudi Savings Product Service, website and/or software application (collectively, the <b>“Savings Service”</b>) enables you to " +
      "deposit and withdraw money services in accordance with our products under these Saving Products Terms and Conditions (the “Savings Terms”). " +
      "These Savings Terms represent an agreement between you (“you”, “your”) and Kudi Cooperative Multipurpose Society Limited. (\"Kudi Savings\") and " +
      "contain the terms and conditions governing your use of and access to our savings product [“Savings Product”] and all affiliated websites owned and " +
      "operated by us (collectively, the \"Website\") and our products, services, and applications (together with the Website, the \"Services\"). </p>" +
      "<br/>" +
      "<p>These Savings Terms also contain important disclosures and information about your Kudi Savings Account. By using Kudi Savings, you agree " +
      "to be bound by these Savings Terms, the General Terms of Service, the Privacy Notice and any additional terms specific to Services you use, and all " +
      "other terms, policies, and guidelines applicable to the Services <br/>(the “Terms”) . Defined terms will have the same meaning as those found in the General " +
      "Terms, unless otherwise defined here. In the event of a conflict between these Savings Terms and the General Terms, the provisions of the General Terms of Service shall prevail.</p>" +
      "<br/>" +
      "<p>\"You\" and \"your\" mean the users or accessors to  the Services. \"We,\" \"us,\" and \"our\" mean Kudi Multipurpose Cooperative Society and its successors, affiliates, and assignees. " +
      "As used in these Terms, \"Kudi Savings Account\"or “Savings Account”  means the account you have with us for the Services.</p>" +
      "<br/>" +
      "<p>Your use of and access to this Kudi Savings Service are subject at all times to the Terms. Please read these Terms and our Privacy Policy, carefully. By using or accessing the Services, " +
      "you represent that you have read and understand all the Terms and our Privacy Policy and you agree to be bound by these Terms and our Privacy Policy. If you do not agree to the Terms including " +
      "the Savings Terms  do not use or access this Service.</p>",
  },
  {
    id: "002",
    name: "2. REGISTRATION AND REQUIREMENTS TO USE THE SERVICE",
    description:
      "<p>In order to use the Services, you must be a resident of the Federal Republic of Nigeria, at least 18 years old and register by providing all  information requested by us, including a valid " +
      "mobile phone number; a valid residential or business address, a valid email address (a bank account with a Nigerian financial institution, means of identification and such other information as we may " +
      "request from time to time (collectively, \"User Information\").</p>" +
      "<br/>" +
      "<p>You agree to provide true, accurate and complete information about yourself during the Savings Account registration process.  If we approve your registration, you will be authorised to become an associate member " +
      "of our Kudi Multipurpose Cooperative Society and to use the Services, subject to these Savings Terms and the Terms.</p>" +
      "<br/>" +
      "<p>You will be given the option of receiving a unique PIN for purposes of reusing your Savings Account. You are responsible for keeping your PIN secure. Your personal information is highly protected in accordance with our " +
      "Privacy Policy published and updated on our mobile and web application (the “Platform”). The Savings Product shall only be made available to you once our Know-Your-Customer (KYC) process has been compiled with by you as part " +
      "of our onboarding process and your identification has been verified.</p>" +
      "<br/>" +
      "<p>In order to ensure that we are able to provide Communications to you electronically, you must notify us of any change in your email address and your mobile device number, other text message address or email address" +
      " by updating your profile on the Application provided, however, that you must notify us at least three (3) Business Days before any changes to your bank account information, including, but not limited to, the closure of your " +
      "bank account for any reason by calling 018885008, 018888866, 015200061 or sending an email to support@kudi.com.</p>" +
      "<br/>" +
      "<p>To fulfil our compliance obligations and to provide the Services to you, you hereby authorise us to, directly or through a third-party, obtain, verify, and record information and documentation that helps us verify your identity and " +
      "other registration details. When you register for the Services and from time to time thereafter, we may require you to provide and/or confirm information and documentation that will allow us to identify you, such as: a valid government-issued" +
      " identity card, a copy of your utility bill, affidavit, and such other information and documentation that we may require from time to time.</p>",
  },
  {
    id: "003",
    name: "3. PURPOSE AND USE OF THE SERVICE",
    description:
      "<p>The purpose of the Services is to try to help you save money with minimal effort. You can request for your savings at any time on any of our payout days.</p>" +
      "<br/>" +
      "<p>Your funds will remain in your Kudi Savings Account until you instruct us to transfer any or all of your funds from your Kudi Savings Account to your Registered Bank Account.</p>" +
      "<br/>" +
      "<p>Please note that if you do not have sufficient available funds in your Kudi Savings Account to cover the amount of the requested transfer, your request for the transfer will be declined.</p>" +
      "<p>You can use the funds in your Kudi Savings Account  for any applicable legal use, whether it’s online or offline We may also transfer funds from your Kudi Savings Account to your bank account without notice to you upon the closure of your Kudi Account" +
      " as described below and at any time if required by applicable law or if we, in our sole discretion, suspect the Services are being used for illicit purposes or otherwise in violation of these Savings Terms.</p>" +
      "<br/>" +
      "<p>The purpose of the Services is to try to help you save money with minimal effort. We debit your Kudi Agent Banking Account (the amount you specify). You can request for your savings at any time unless you choose to lock it for a specific period. " +
      "The funds in your Kudi Savings Account are held at a bank for your benefit. Your funds will remain in your Kudi Savings Account until you instruct us to transfer any or all of your funds to your Bank Account.</p>",
  },
  {
    id: "004",
    name: "4. EARNING AND INTERESTS",
    description:
      "<p>You agree that you will not receive interest or other earnings on the funds in your Kudi Savings Account.</p>",
  },
  {
    id: "005",
    name: "5. FEES",
    description:
      "<p>The transaction fees are as communicated to you from time via the Platform; we reserve the right to change fees for the Services in the future. We will notify you before charging a fee for the Services by " +
      "delivering notice to you electronically, by posting such fee on the Website, or by any other method permitted by applicable law. If you continue using the Services after such notice, you must pay all applicable fees for the Services.</p>",
  },
  {
    id: "006",
    name: "6. FRAUD, RESTRICTIONS AND PENALTIES",
    description:
      "<p>You agree and acknowledge that where the funds in your Kudi Savings Account have been flagged as or are suspected of being fraudulent in nature, we may immediately suspend your account and retain the funds pending an investigation.</p>" +
      "<br/>" +
      "<p>If you elect to lock an amount in your Kudi Savings Account, such lock will remain in place for an agreed period of time. If you elect to lift the lock on the account prior to the agreed date, you agree that we may impose a " +
      "penalty on you for lifting such lock and which amount we may immediately deduct from your Kudi Savings Account balance.</p>",

  },
  {
    id: "007",
    name: "7. UNAUTHORIZED ACCESS",
    description:
      "<p>You may only use the Services for your own personal, non-commercial use and or for the benefit of any third party. Your use of the Services must comply with all applicable law. " +
      "If your use of the Services is prohibited by applicable law, then you are not authorised to use the Services. We are not responsible if you use the Services in any manner that violates applicable law.</p>" +
      "<p>You agree not to authorise any other person or entity to use your username and password or mobile device to access the Services. You are solely responsible for the maintenance, confidentiality, and security of your " +
      "username, password, and other User Information. Except as otherwise required by applicable law, you are responsible for all transactions and other activities authorised or performed using your username and password or mobile device, whether authorised or unauthorised by you. " +
      "Except as otherwise expressly stated in these here or required by applicable law, we are not responsible for any losses arising out of the loss or theft of your User Information or your mobile device or from unauthorised or fraudulent transactions associated with your bank account or your Kudi Savings Account. " +
      "If you suspect or become aware of any unauthorised activity or access to your username, password, or mobile device, you must contact us immediately at 018885008, 018888866 or 015200061.</p>",
  },
  {
    id: "008",
    name: "8. COMMUNICATION AND NOTICES",
    description:
      "<p>By using the Savings Product you hereby expressly consent to receive text messages relating to the services at that number or address provided at the point of registrations and as duly updated from time on your profile. Third-party data and message fees may apply. To verify your mobile device number or text message" +
      " address, we may send you a code via text message to the mobile device number or text message address you provide, and you must enter that code as instructed by us. If you change your mobile device number or text message address, you must promptly provide and verify your new mobile device number or text message address.</p>" +
      "<p>In addition, we may send you important notices via email and to your registered email about the Services.</p>",
  },
  {
    id: "009",
    name: "9. AUTHORIZATIONS AND THIRD PARTY WEBSITES",
    description:
      "<p>By using the Services and providing User Information to us, you automatically authorise us to obtain, directly or indirectly through our third-party service providers and without any time limit or the requirement to pay any fees, information about you and your bank account from the financial institution " +
      "holding your bank account and other third-party websites and databases as necessary to provide the Services to you.</p>" +
      "<br/>" +
      "<p>For purposes of such authorization, you hereby grant us and our third-party service providers a limited power of attorney, and you hereby appoint us and our third-party service providers as your true and lawful attorney-in-fact and agent, with full power of substitution and resubstitution, for you and in your name, " +
      "place, and stead, in any and all capacities, to access third-party websites, servers, and documents; retrieve information; and use your User Information, all as described above, with the full power and authority to do and perform each and every act and thing requisite and necessary to be done in connection with such activities, as fully to all intents " +
      "and purposes as you might or could do in person.</p>" +
      "<br/>" +
      "<p>YOU ACKNOWLEDGE AND AGREE THAT WHEN WE OR OUR THIRD-PARTY SERVICE PROVIDERS ACCESS AND RETRIEVE INFORMATION FROM SUCH THIRD-PARTY WEBSITES, WE AND OUR THIRD-PARTY SERVICE PROVIDERS ARE ACTING AS YOUR AGENT, AND NOT THE AGENT OR ON BEHALF OF THE THIRD PARTY. You agree that other third parties shall be entitled " +
      "to rely on the foregoing authorization, agency, and power of attorney granted by you. You understand and agree that the Services are not endorsed or sponsored by any third-party account providers accessible through the Services. We make no effort to review information obtained from the financial institution holding your bank account and " +
      "other third-party websites and databases for any purpose, including, but not limited to, accuracy, legality, or <br/>non-infringement. As between us and our third-party service providers, we own your confidential User Information.</p>",

  },
  {
    id: "010",
    name: "10. PRIVACY",
    description:
      "<p>Your use of the Savings Product signifies your continuing consent to our privacy policy, which governs our collection and use of personal information that you supply to us.</p>",
  },
  {
    id: "011",
    name: "11. WARRANTIES",
    description:
      "<p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW AND EXCEPT AS OTHERWISE EXPRESSLY PROVIDED IN THE TERMS, YOU EXPRESSLY UNDERSTAND AND AGREE THAT YOUR USE OF THE SERVICES AND ALL INFORMATION, PRODUCTS, AND OTHER CONTENT (INCLUDING THAT OF THIRD PARTIES) INCLUDED IN OR ACCESSIBLE FROM THE SERVICES IS AT YOUR SOLE RISK. " +
      "THE SERVICES ARE PROVIDED ON AN \"AS IS\" AND \"AS AVAILABLE\" BASIS WITHOUT ANY WARRANTY OF ANY KIND.</p>" +
      "<p>TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, WE AND OUR THIRD-PARTY SERVICE PROVIDERS EXPRESSLY DISCLAIM ALL WARRANTIES, INCLUDING ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, " +
      "OR NON-INFRINGEMENT. WE DO NOT WARRANT THAT THE FUNCTIONS OR CONTENT CONTAINED IN THIS APP WILL BE INTERRUPTED OR ERROR-FREE, THAT DEFECTS WILL BE CORRECTED, OR THAT THIS SITE OR SERVER THAT MAKES IT AVAILABLE IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS." +
      " WE DO NOT WARRANT OR MAKE ANY REPRESENTATION REGARDING USE, OR THE RESULT OF USE OF THE CONTENT IN TERMS OF ACCURACY, RELIABILITY, OR OTHERWISE INCLUDING THE CONTINUOUS, UNINTERRUPTED OR SECURE ACCESS TO THE APP, WHICH MAY BE AFFECTED BY FACTORS OUTSIDE OUR CONTROL OR MAY BE SUBJECT TO " +
      "PERIODIC TESTING, REPAIR, MAINTENANCE OR UPGRADES. THE CONTENT MAY INCLUDE TECHNICAL ERRORS AND THE COMPANY MAY MAKE CHANGES, OR IMPROVEMENTS AT ANY TIME. YOU, AND NOT US, ASSUME THE ENTIRE COST OF ALL NECESSARY SERVICING REPAIR " +
      "OR CORRECTION IN THE EVENT OF ANY LOSS OR DAMAGE ARISING FROM THE USE OF THIS APP OR IT’S CONTENT. WE MAKE NO WARRANTIES THAT YOUR USE OF THE CONTENT WILL NOT INFRINGE THE RIGHTS OR OTHERS AND ASSUME NO LIABILITY OR RESPONSIBILITY FOR ERRORS OR OMISSIONS IN SUCH CONTENT.</p>" +
      "<br/>" +
      "<p>TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE THAT Kudi, ITS AFFILIATES, AND ITS AND THEIR RESPECTIVE EMPLOYEES, OFFICERS, DIRECTORS, AGENTS, AND THIRD-PARTY SERVICE PROVIDERS WILL NOT BE LIABLE TO YOU OR ANY THIRD PARTY FOR (A) THE PERFORMANCE OF THE SERVICES OR THE " +
      "INABILITY TO USE THE SERVICES; (B) ANY INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL, PUNITIVE, OR EXEMPLARY DAMAGES, WHETHER BASED IN CONTRACT, TORT, OR OTHERWISE, INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA, OR OTHER INTANGIBLE LOSSES, EVEN IF SUCH PERSONS" +
      " HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGES, WHICH ARISE OUT OF OR ARE IN ANY WAY CONNECTED WITH THESE TERMS, THE SERVICES, OR CONTENT; (C) ANY AMOUNT, IN THE AGGREGATE, IN EXCESS OF THE GREATER OF 10,000 NAIRA OR THE AMOUNT TRANSFERRED FROM YOUR DEBIT CARD TO YOUR Kudi ACCOUNT VIA THE SERVICES; " +
      "OR (D) ANY EVENT BEYOND OUR REASONABLE CONTROL. SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF CERTAIN DAMAGES, SO THE ABOVE LIMITATION AND EXCLUSIONS MAY NOT APPLY TO YOU.</p>",
  },
  {
    id: "012",
    name: "12. INDEMNITY",
    description:
      "<p>At our request, you agree to defend, indemnify, and hold harmless Kudi, our affiliates, and its and our respective employees, officers, directors, agents, and third-party service providers from and against any and all claims, suits, liabilities, damages (actual and consequential)," +
      " losses, fines, penalties, costs, and expenses (including reasonable attorney  fees) arising from or in any way related to any third-party claims relating to your use of the Savings Product, violation of the  Terms, applicable law or any third-party rights, or your fraud or willful misconduct. Kudi reserves the " +
      "right to assume the exclusive defence and control of any matter subject to indemnification by you, in which event you will cooperate in asserting any available defences.\n</p>",
  },
  {
    id: "013",
    name: "13. DISPUTE RESOLUTION",
    description:
      "<p>These Terms will be governed and interpreted pursuant to the laws of the Federal Republic of Nigeria.</p>"+
      "<br/>"+
      "<p>Any and all disputes, claims or litigation arising from or related in any way to this Terms or any provisions herein will be resolved by mediation at the Lagos Multi Door Court House, Lagos State, Nigeria. Where such dispute persists, it shall then be resolved by Nigerian Courts in Lagos State. " +
      "The parties hereby waive any objections against and expressly submit to the exclusive jurisdiction of the Nigerian Courts in Lagos State.</p>"
  },
  {
    id: "014",
    name: "14. OTHERS",
    description:
      "<b>a. Taxes</b>"+
      "<p>You will be responsible for paying, withholding, filing, and reporting all taxes, duties, and other governmental assessments associated with your activity in connection with the Services, provided that we may, in our sole discretion, do any of the foregoing on your behalf or for ourselves.</p>"+
      "<br/>"+
      "<b>b. Waiver</b>"+
      "<p>The enforceability or otherwise of any provisions of these Saving Terms shall not affect the enforceability of the rest of these Saving Terms and the Terms.This Agreement will not  be assignable or transferable by you.</p>"+
      "<br/>"+
      "<b>c. No Partnership</b>"+
      "<p>You hereby acknowledge and agree that you are not an employee, agent, partner, or joint venture of Kudi, and you do not have any authority of any kind to bind Kudi in any respect whatsoever.</p>"+
      "<br/>"+
      "<b>d. Change of Terms</b>"+
      "<p>We may revise these Saving Terms at any time by updating this posting. We may discontinue the Savings Product (temporarily or permanently) or change any information, content, feature or service at any time with or without notice. We also retain the right to create limits on use of the Savings Product at our sole discretion. " +
      "Your use of the Services after such changes are implemented constitute your acknowledgement and acceptance of the changes. Please consult the Terms of use regularly to acquit yourself with any changes that may apply.</p>"+
      "<br/>"+
      "<b>e. Termination</b>"+
      "<p>We reserve the right to terminate your use of the Service if you violate any of the Terms or any rules or guidelines posted on the Platform or for any other reason at our discretion.</p>"+
      "<br/>"+
      "<b>f. Proprietary Rights</b>"+
      "<p>Using the Platform does not give you ownership of or any rights to any materials, functionalities, operations and content on the Platform, all of which are owned exclusively and reserved by us and protected by copyright and other intellectual property rights.</p>"+
      "<br/>"+
      "<p>Trademarks, service marks, and logos appearing on the Platform are the property of Kudi or the party that provided the trademarks, service marks, and logos to Kudi  and any party that provided trademarks, service marks, and logos to Kudi retain all rights with respect " +
      "to any of their respective trademarks, service marks, and logos appearing on the Platform.</p>"+
      "<br/>"+
      "<p>You acknowledge and will respect all such copyright and other intellectual property rights we own or may own in the look and feel of the Platform and its contents including any text, graphics, data, stills, photographs and moving images. " +
      "You further agree that you will not infringe our copyright by any method or manner now known or as may exist in the future.</p>"
  },
]
