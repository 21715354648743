import React, { useEffect } from "react"
import { isMobile } from "react-device-detect"
import { ScrollToHOC } from "react-scroll-to"
import BackToTop from "react-easy-back-to-top"
import Banner from "../banner"
import Container from "../container"
import ContentHeader from "../content-header"
import CardContainer from "../card-container"
import DocumentSideNav from "../document-side-nav "
import styles from "./documentContent.module.scss"


const DocumentContent = ({ informationData, scrollTo }) => {
  useEffect(() => scrollTo({ y: isMobile ? 260 : 560, smooth: true }), [])
  return (
    <>
      <div id="back-to-top-container" className={styles.answer}>
        {informationData &&
          informationData.map(data => (
            <div id={`${data.id}`}>
              <h2 className={styles.header}>{data.header}</h2>
              <div
                className={styles.description}
                dangerouslySetInnerHTML={{ __html: data.description }}
              />
            </div>
          ))}
        <BackToTop
          icon="fas fa-chevron-up"
          text="&#8593; TOP"
          color="#0E2D8F"
          backgroundColor="#00E59E"
          hover={{
            backgroundColor: "#01CF8F",
            color: "#18379A",
          }}
          margin="32px"
          padding="12px"
          borderRadius={3}
          showOnDistance={900}
          position={{ bottom: "0", right: "5%" }}
          fontSize="16px"
          scrollBehavior="auto"
        />
      </div>
    </>
  )
}

export default ScrollToHOC(DocumentContent)
