module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Kudi Inc","short_name":"kudi-inc","start_url":"/","background_color":"#FFFFFF","theme_color":"#18379A","display":"standalone","icon":"src/assets/images/logo.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"58bc4fd5b0db40984c0e6c5ff4a3c0f0"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["Sofia Pro"],"urls":["src/assets/fonts/stylesheet.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-firebase/gatsby-browser.js'),
      options: {"plugins":[],"features":{"auth":false,"database":false,"firestore":false,"storage":false,"messaging":false,"functions":false,"performance":false,"analytics":true},"credentials":{"apiKey":"AIzaSyA8g3ePcyfB_GEezYmRlqnY8iunkVTx6PM","authDomain":"kudi-website.firebaseapp.com","databaseURL":"https://kudi-website.firebaseio.com","projectId":"kudi-website","storageBucket":"kudi-website.appspot.com","messagingSenderId":"402458221484","appId":"1:402458221484:web:40a33a8a45385f9dbe7809","measurementId":"G-5Z78KJWZ1S"}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
