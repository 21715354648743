import React from "react"
import PropTypes from "prop-types"
import NavList from "./nav-list"

import styles from "./header.module.scss"

const MobileMenu = ({ closeFn }) => {
  return (
    <div className={styles.mobileMenu}>
      <div className={styles.holder}>
        <div className={styles.textAbsolute}>Easier & Faster</div>
        <NavList show={true} closeFn={closeFn} />
      </div>
    </div>
  )
}
MobileMenu.propTypes = {
  closeFn: PropTypes.func.isRequired,
}

export default MobileMenu
