import React from "react"
import FaqLayout from "../components/faq-layout"
import SingleFaq from "../components/questions/single-faq"
import SideNav from "../components/side-nav"
import styles from "./styles.module.scss"
export default ({ pageContext, location }) => {
  return (
    <FaqLayout location={location} className={styles.wrapperX}>
      <SideNav className={styles.side} pageContext={pageContext} />
      <SingleFaq className={styles.content} pageContext={pageContext} />
    </FaqLayout>
  )
}
