import React from "react"
import PropTypes from "prop-types"
import HeroSection from "../hero-section"
import Accordion from "../accordion"
import SubHero from "../sub-hero"
import Carousel from "../carousel"
import {
  heroData,
  subHero,
  carouselData,
  values,
  subValues,
} from "../../assets/pageData/about-us"
import styles from "./about.module.scss"
import WidePattern from "../wide-pattern"

const AboutUs = () => (
  <>
    <HeroSection data={heroData} />
    <Carousel slides={carouselData} />
    <div className={styles.Story}>
      <div className={styles.StoryTitle}>
        <h1 className={styles.StoryTitleOutline}>Our Story</h1>
        <h3>
          We’re working to <br />
          <span className="underlined--black">
            <span>change the banking</span>{" "}
          </span>{" "}
          <br />
          <span className="underlined--black">
            <span>experience</span>{" "}
          </span>{" "}
          for all of us
        </h3>
      </div>
      <div className={styles.StoryText}>
        <p>
          The first line of code was written that same year. Kudi has grown from
          just a chatbot that responds to your financial requests. We are a
          financial technology company providing financial services for all
          Africans.
        </p>
        <p>
          We created a chatbot to help you send money and pay bills, but we
          didn’t stop there. We realised that many people can’t access financial
          services across Nigeria and we wanted to help. We dug further to the
          real problem and decided to create Kudi app for Agents. At Kudi, we
          are still asking those questions and working round the clock to answer
          them.
        </p>
        <p>
          We hope that regardless of the transaction you are trying to perform,
          whether you’re stranded in the urban city setting or chilling with
          friends and want to pay bills or you reside in an extremely remote
          part of the continent in need of a banking experience, know that we
          see you and we’re building for you. We are today making financial
          services accessible and affordable particularly to the banked,
          underbanked and unbanked. We won’t stop asking the questions, and also
          continue to answer them.
        </p>
        <p>We’re working to change the banking experience for all of us.</p>
      </div>
    </div>
    <WidePattern type="light" />
    <SubHero description={subValues} />
    <div className={styles.Values}>
      <div className={styles.absoluteText}>Values</div>
      <div className={styles.ValuesList}>
        {values && <Accordion data={values} />}
      </div>
    </div>
    <SubHero description={subHero} />
    <div className={styles.Video}>
      <iframe
        title="About Kudi"
        src="https://www.youtube.com/embed/n4E1V2-nT1A"
        frameBorder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
    </div>
  </>
)

AboutUs.propTypes = {
  location: PropTypes.object,
}

export default AboutUs
