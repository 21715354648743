import React, { useState } from "react"
import SubHero from "../sub-hero"
import { subHero, partners, logos } from "../../assets/pageData/home"
import { Right } from "../../assets/svg"
import styles from "./home.module.scss"
import cx from "classnames"
export const Partner = ({
  partner: { author, description, company, image },
  index,
}) => (
  <div key={index} className={styles.Item}>
    <div className={styles.ItemHeader}>
      <div className={styles.ItemHeaderImage}>
        <img src={image} alt={`${author} partnering with Kudi`} />
      </div>
      <div>
        <h4>{author}</h4>
        <small>{company}</small>
      </div>
    </div>
    <p>{description}</p>
  </div>
)
const Partners = () => {
  const [activeIndex, setActiveIndex] = useState(0)
  return (
    <section className={styles.partners}>
      <SubHero description={subHero.partners} />
      <div className={styles.logos}>
        {logos.map(({ image, alt }, id) => (
          <img key={id} src={image} alt={alt} />
        ))}
      </div>
      <div className={styles.partnersTestimonials}>
        {partners.map((partner, index) => (
          <Partner partner={partner} key={index} index={index} />
        ))}
      </div>
      <div className={styles.partnersMobile}>
        <Right
          onClick={() =>
            activeIndex === 0
              ? setActiveIndex(partners.length - 1)
              : setActiveIndex(activeIndex - 1)
          }
        />

        {partners.map((partner, index) => (
          <div
            key={index}
            className={
              index === activeIndex
                ? cx(styles.Slide, styles.SlideActive)
                : styles.Slide
            }
          >
            <Partner partner={partner} index={index} />
          </div>
        ))}
        <Right
          onClick={() =>
            activeIndex === partners.length - 1
              ? setActiveIndex(0)
              : setActiveIndex(activeIndex + 1)
          }
        />
      </div>
    </section>
  )
}

export default Partners
