import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Terms from "../components/terms"

const TermsPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="Terms & Conditions" slug="terms" />
    <Terms/>
  </Layout> 
)

TermsPage.propTypes = {
  location: PropTypes.object,
}

export default TermsPage
