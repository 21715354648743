import {
  APP_LOAD,
  REDIRECT,
  LOGOUT,
  LOGIN,
  SELECT_INSURANCE,
  SET_ACTIVE_INSURANCE,
  VERIFY_OTP,
  VERIFY_OTP_START,
  VERIFY_OTP_STOP,
  RESEND_OTP,
  RESEND_OTP_START,
  RESEND_OTP_STOP,
} from "../actions/types"

let initialState = {
  verifyAgent: {},
  verifyAgentLoading: false,
  resendOtp: {},
  resendOtpLoading: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case APP_LOAD:
      return {
        ...state,
        token: action.token || null,
        kyc: action.kyc || null,
        wallet: action.wallet || null,
        tokenExpires: action.expires || null,
        appLoaded: true,
      }
    case REDIRECT:
      return { ...state, redirectTo: null }
    case LOGIN:
      return {
        ...state,
        redirectTo: "/insurance/checkout",
        token: action.token,
        kyc: action.agent,
        wallet: action.wallet,
        tokenExpires: action.expires,
      }
    case SELECT_INSURANCE:
      return {
        ...state,
        redirectTo: "/insurance/sign-in",
      }
    case SET_ACTIVE_INSURANCE:
      return {
        ...state,
        kyc: { ...state.kyc, insurancePlan: action.slug },
        wallet: {
          ...state.wallet,
          amount: state.wallet.amount - action.amount,
        },
      }

    case VERIFY_OTP:
      return {
        ...state,
        verifyAgent: { ...state.verifyAgent, verifyAgent: action.verifyAgent },
      }
    case VERIFY_OTP_START:
      return {
        ...state,
        verifyAgentLoading: true,
      }
    case VERIFY_OTP_STOP:
      return {
        ...state,
        verifyAgentLoading: false,
      }
    case RESEND_OTP:
      return {
        ...state,
        resendOtp: { ...state.resendOtp, resendOtp: action.resendOtp },
      }
    case RESEND_OTP_START:
      return {
        ...state,
        resendOtpLoading: true,
      }
    case RESEND_OTP_STOP:
      return {
        ...state,
        resendOtpLoading: false,
      }

    case LOGOUT:
      return {
        ...state,
        redirectTo: "/insurance",
        token: null,
        kyc: null,
        wallet: null,
        tokenExpires: null,
      }
    default:
      return state
  }
}
