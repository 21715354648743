import React from "react"
import PropTypes from "prop-types"
import styles from "./contentHeader.module.scss"

const ContentHeader = ({ title, backText, children, description }) => {
  return (
    <div className={styles.contentHeader}>
      <h2
        className={styles.title}
        dangerouslySetInnerHTML={{ __html: title }}
      />
      {description ? <p className={styles.description}>{description}</p> : null}
      {children}
      {backText ? <div className={styles.backText}>{backText}</div> : null}
    </div>
  )
}

ContentHeader.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
}

export default ContentHeader
