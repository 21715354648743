export const LOGIN = "LOGIN"
export const LOGOUT = "LOGOUT"
export const LOG_ERROR = "LOG_ERROR"
export const APP_LOAD = "APP_LOAD"
export const REDIRECT = "REDIRECT"
export const ASYNC_START = "ASYNC_START"
export const LOGIN_PAGE_UNLOADED = "LOGIN_PAGE_UNLOADED"
export const SELECT_INSURANCE = "SELECT_INSURANCE"
export const SET_ACTIVE_INSURANCE = "SET_ACTIVE_INSURANCE"
export const PURCHASE_INSURANCE = "PURCHASE_INSURANCE"
export const GET_OPENINGS = "GET_OPENINGS"
export const CLEAR_ERROR = "CLEAR_ERROR"
export const GET_STORIES = "GET_STORIES"
export const SET_STORIES = "SET_STORIES"
export const SET_ACTIVE_CATEGORY = "SET_ACTIVE_CATEGORY"
export const PAGINATE = "PAGINATE"
export const SET_PAGE = "SET_PAGE"
export const SET_SEARCH_TERM = "SET_SEARCH_TERM"
export const SEARCH_STORIES = "SEARCH_STORIES"
export const SET_SEARCH_PAGE="SET_SEARCH_PAGE"
export const VERIFY_OTP_START = "VERIFY_OTP_START"
export const VERIFY_OTP_STOP = "VERIFY_OTP_STOP"
export const VERIFY_OTP = "VERIFY_OTP"
export const RESEND_OTP_START = "RESEND_OTP_START"
export const RESEND_OTP_STOP = "RESEND_OTP_STOP"
export const RESEND_OTP = "RESEND_OTP"
export const FETCH_AGENTS = 'FETCH_AGENTS'
export const FETCH_AGENTS_START = 'FETCH_AGENTS_START'
export const FETCH_AGENTS_STOP = 'FETCH_AGENTS_STOP'