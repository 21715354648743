import {
  ASYNC_START,
  LOG_ERROR,
  GET_STORIES,
  SET_STORIES,
  SET_ACTIVE_CATEGORY,
  PAGINATE,
  SET_PAGE,
  SET_SEARCH_TERM,
  SEARCH_STORIES,
  SET_SEARCH_PAGE,
} from "./types"
import { MediumRssToJson, storiesCategories, sortByDate } from "../../lib"
import { batch } from "react-redux"
const setStories = (payload, filteredStories) => ({
  type: SET_STORIES,
  payload,
  filteredStories
})


export const setPage = page => dispatch =>
  dispatch({
    type: SET_PAGE,
    page
  })

export const setSearchPage = page => (dispatch, getState) => {
  let {
    stories: { offset, limit, searchResult },
  } = getState()

  let totalPages = Math.ceil(searchResult.length / limit)
  let filteredStories = sortByDate(searchResult).slice(offset, limit * page)

  if (page <= totalPages) {
    return batch(() => {
      dispatch({
        type: SET_SEARCH_PAGE,
        filteredStories
      })
      dispatch({
        type: SET_PAGE,
        page
      })
    })
  }
}

export const setSearchTerm = term => dispatch =>
  dispatch({
    type: SET_SEARCH_TERM,
    term
  })

export const paginate = page => (dispatch, getState) => {
  let {
    stories: { offset, limit, stories, isSearch },
  } = getState()
  if (!isSearch) {
    let filteredStories = sortByDate(stories).slice(offset, limit * page)
    return dispatch({
      type: PAGINATE,
      filteredStories
    })
  }
}

export const setActiveCategory = category => dispatch =>
  dispatch({
    type: SET_ACTIVE_CATEGORY,
    category
  })

export const doSearch = () => (dispatch, getState) => {
  let {
    stories: { stories, searchTerm, limit, offset },
  } = getState()
  let filteredStories = sortByDate(stories).slice(offset, limit)
  if (searchTerm) {
    searchTerm = searchTerm.toLowerCase()
    const result = stories.filter(
      item =>
        item.title.toLowerCase().includes(searchTerm) ||
        item.description.toLowerCase().includes(searchTerm)
    )
    filteredStories = sortByDate(result).slice(offset, limit)
    return dispatch({
      type: SEARCH_STORIES,
      payload: { result, limit, filteredStories, isSearch: true },
    })
  }
  return dispatch({
    type: SEARCH_STORIES,
    payload: { result: [], limit, filteredStories, isSearch: false },
  })
}

export const getStoriesRequest = (category = "") => async dispatch => {
  dispatch({
    type: ASYNC_START,
    subtype: GET_STORIES,
  })

  const payload = []
  let filteredStories

  if (category)
    await getStoriesByCategory(category, dispatch, items =>
      payload.push(...items)
    )

  if (!category)
    for (let key of storiesCategories.keys()) {
      await getStoriesByCategory(key, dispatch, items => payload.push(...items))
    }

  return dispatch(setStories(payload, filteredStories))
}

const getStoriesByCategory = (category, dispatch, callback) => {
  return MediumRssToJson.getStories(category)
    .then(({status,data}) => {

      if (status === 200) {
        const items = data.items.map(
          ({ title, pubDate, link, thumbnail,content }) => ({
            title,
            pubDate,
            link,
            thumbnail,
            description:content,
            category,
          })
        )
        return callback(items)
      }
      dispatch({
        type: LOG_ERROR,
      })
      return null
    })
    .catch(({ response }) => {
      if (response !== undefined && response.data.message) {
        return dispatch({
          type: LOG_ERROR,
          error: response.data.message,
        })
      }
      return dispatch({
        type: LOG_ERROR,
      })
    })
}
