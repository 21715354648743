import React from "react"
import PropTypes from "prop-types"
import readTimeEstimate from "read-time-estimate"
import { window } from "browser-monads"
import styles from "./stories.module.scss"
import {
  truncateText,
  getPublishedDate,
  getFirstParagraphText,
} from "../../lib"

const Story = ({ title, pubDate, link, thumbnail, description }) => {
  let validDescription = description ? description : " "
  const { humanizedDuration } = readTimeEstimate(validDescription)
  return (
    <div
      tabIndex="0"
      role="button"
      onClick={() => window.open(link)}
      onKeyDown={() => window.open(link)}
      className={styles.story}
    >
      <div className={styles.storyThumbnail}>
        <img src={thumbnail} alt="Story" />
      </div>
      <div className={styles.storyContent}>
        <div>
          <h3 className={styles.storyTitle}>
            {truncateText(title, 60, "...")}
          </h3>
          <small className={styles.storyCreatedAt}>
            {getPublishedDate(pubDate)}
            <span>&nbsp;</span>
            {humanizedDuration} read
          </small>
        </div>
        <p
          className={styles.storyDescription}
          dangerouslySetInnerHTML={{
            __html: truncateText(
              getFirstParagraphText(validDescription),
              148,
              ""
            ),
          }}
        />
        <span>
          <b>Read more story</b>
        </span>
      </div>
    </div>
  )
}

Story.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  link: PropTypes.string,
  pubDate: PropTypes.string,
}

export default Story
