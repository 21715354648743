import React from "react"
import { isMobile } from "react-device-detect"
import ProgressiveImage from "react-progressive-image"
import { bannerText } from "../../assets/pageData/stories"
import ImageTiny from "../../assets/images/home-phone-tiny.png"
import Image1 from "../../assets/images/home-phone1.png"
import Image2 from "../../assets/images/home-phone2.png"
import PlayStore from "../../assets/images/google-play.png"
import WidePattern from "../wide-pattern"
import { WidePatternGrey } from "../../assets/svg"
import styles from "./home.module.scss"
import { playStoreLink } from "../../lib"
const TextSection = () => (
  <>
    <h2>
      <p>
        Become an Agent <br />
        in <span className="underlined--black"><span>3 simple steps</span></span>
      </p>
    </h2>
    <ul dangerouslySetInnerHTML={{ __html: bannerText.list }}></ul>
    <h3>
      Download the <span>Kudi App</span>{" "}
    </h3>
    <a href={playStoreLink} target="_blank" rel="noopener noreferrer">
      <img src={PlayStore} alt="Download Kudi on Playstore" />
    </a>
  </>
)
const BecomeAgent = () => {
  return (
    <section className={styles.becomeAgent}>
      <div className={styles.becomeAgentContainer}>
        <div className={styles.Image}>
          <ProgressiveImage src={Image1} placeholder={ImageTiny}>
            {src => (
              <img
                src={src}
                onMouseOver={e => (e.currentTarget.src = Image2)}
                onFocus={e => (e.currentTarget.src = Image2)}
                onMouseOut={e => (e.currentTarget.src = Image1)}
                onBlur={e => (e.currentTarget.src = Image1)}
                alt="Download Kudi App"
              />
            )}
          </ProgressiveImage>
        </div>

        <div className={styles.Text}>
          <TextSection />
        </div>
      </div>
      <WidePatternGrey />
      <WidePattern type={"grey"} className={styles.bg} />
      <div className={styles.Mobile}>
        <TextSection />
      </div>
    </section>
  )
}

export default BecomeAgent

