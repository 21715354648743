import React from "react"
import PropTypes from "prop-types"
import styles from "./section.module.scss"
import cx from "classnames"

const Section = ({ children, className, style }) => {
  return (
    <section style={style} className={cx(styles.section, className)}>
      {children}
    </section>
  )
}

Section.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Section
