import React, { Fragment } from "react"
import { navigate } from "gatsby"
import PropTypes from "prop-types"
import cx from "classnames"
import Button from "../../components/button"
import Wrapper from "../../components/wrapper"
import ProgressiveImage from "react-progressive-image"
import styles from "./hero-section.module.scss"
import { Controller, Scene } from "react-scrollmagic"
import { Tween } from "react-gsap"

const HeroBody = ({
  data: {
    description,
    header,
    buttonText,
    page,
    link,
    alt,
    imageTiny,
    yPercent,
    image,
    type,
  },
}) => (
  <Fragment>
    <div className={styles.Hero} id="imageHolder">
      <div className={styles.HeroContainer}>
        <h2
          className={page === "careers" ? "careers" : ""}
          dangerouslySetInnerHTML={{ __html: header }}
        ></h2>
        <div
          className={styles.HeroContainerDescription}
          dangerouslySetInnerHTML={{ __html: description }}
        ></div>
        {buttonText && <Button onClick={link}>{buttonText}</Button>}
      </div>

      {type === "stretch" ? (
        <div className={styles.stretch}>
          <ProgressiveImage src={image} placeholder={imageTiny}>
            {src => (
              <Controller>
                <Scene triggerElement="#imageHolder" duration={1500}>
                  <Tween
                    position="0"
                    from={{ yPercent: "0" }}
                    to={{ yPercent: `${yPercent ? yPercent : 20}` }}
                  >
                    <img src={src} className={page} alt={alt} />
                  </Tween>
                </Scene>
              </Controller>
            )}
          </ProgressiveImage>
        </div>
      ) : (
        <div className={cx(styles.HeroContainerImage, styles[`${page}`])}>
          <ProgressiveImage src={image} placeholder={imageTiny}>
            {src => (
              <Controller>
                <Scene triggerElement="#imageHolder" duration={1500}>
                  <Tween
                    position="0"
                    from={{ yPercent: "0" }}
                    to={{ yPercent: `${yPercent ? yPercent : 30}` }}
                  >
                    <img src={src} alt={alt} />
                  </Tween>
                </Scene>
              </Controller>
            )}
          </ProgressiveImage>
        </div>
      )}
    </div>
    <div className={styles.MobileHero}>
      <div>
        <h2
          className={["careers", "savings"].includes(page) ? "mt" : ""}
          dangerouslySetInnerHTML={{ __html: header }}
        ></h2>
        <div dangerouslySetInnerHTML={{ __html: description }}></div>
        {page && ["careers", "savings"].includes(page) && (
          <img src={image} alt="Join us at Kudi" />
        )}
      </div>
    </div>
  </Fragment>
)
const HeroSection = ({ data }) => (
  <Wrapper bg="grey">
    <HeroBody data={data} />
  </Wrapper>
)

HeroSection.propTypes = {
  image: PropTypes.string,
  buttonText: PropTypes.string,
  description: PropTypes.string,
  header: PropTypes.string,
  altText: PropTypes.string,
}
export default HeroSection
