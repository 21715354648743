import { toast } from "react-toastify"
import { FETCH_AGENTS, FETCH_AGENTS_START, FETCH_AGENTS_STOP } from "./types"
import { fetchAllAgents } from "../../lib/agents"

export const fetchAllAgentsRequest = data => async dispatch => {
  dispatch({
    type: FETCH_AGENTS_START,
  })
  try {
    const response = await fetchAllAgents(data)
    dispatch({
      type: FETCH_AGENTS,
      payload: response.data,
    })
    return true
  } catch (error) {
    dispatch({
      type: FETCH_AGENTS_STOP,
    })

    toast.error(
      error?.response?.data?.message ||
        error?.message ||
        "Something went wrong with fetching Agents"
    )
    return false
  }
}
