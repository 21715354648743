import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Stories from "../components/stories"

const StoriesPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO
      title="Our Stories "
      slug="stories"
      description="We’re telling our stories on challenges, failures and successes we have faced in providing affordable financial services to local communities. Learn with us"
      keywords="Kudi stories, Become a Kudi Agent, All about Kudi Hq, Kudi Insurance, money, Financial services in Africa, Loans in Nigeria, Agency Banking, Innovative Agency banking, Banking in local communities, Join us In Kudi, Careers at Kudi, Careers in Kudi, KudiMoney, Kudi moni, funds, local funds, Kudi Engineering, Kudi Stories, The Kudian, "
    />
    <Stories />
  </Layout>
)

StoriesPage.propTypes = {
  location: PropTypes.object,
}

export default StoriesPage
