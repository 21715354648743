import React, { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import Dropdown from "react-dropdown"
import { useFormik } from "formik"
import * as Yup from "yup"
import cx from "classnames"
import ReactPaginate from "react-paginate"

import Container from "../container"
import CardContainer from "../card-container"
import ContentHeader from "../content-header"

// CSS modules
import searchStyles from "../content-search/contentSearch.module.scss"
import styles from "./locate-agents.module.scss"
import "react-dropdown/style.css"

import { fetchAllAgentsRequest } from "../../store/actions/locate-agents"
import { NIGERIAN_STATES } from "../../lib"
import { EmptyTableIcon } from "../../assets/svg"

const LocateAgents = ({}) => {
  const dispatch = useDispatch()
  const loading = useSelector(({ locateAgents }) => locateAgents.loading)
  const agentsData = useSelector(({ locateAgents }) => locateAgents.data)
  const pageCount = useSelector(({ locateAgents }) => locateAgents.totalPages)
  const numberOfElements = useSelector(
    ({ locateAgents }) => locateAgents.numberOfElements
  )
  const totalElements = useSelector(
    ({ locateAgents }) => locateAgents.totalElements
  )
  const currentPage = useSelector(
    ({ locateAgents }) => locateAgents.currentPage
  )

  const formOne = useFormik({
    initialValues: {
      businessName: "",
      state: "Lagos",
    },
    validationSchema: Yup.object({
      businessName: Yup.string()
        .matches(/^[a-zA-Z0-9\s]+$/, {
          message: "Cannot contain special characters or spaces",
          excludeEmptyString: true,
        })
        .max(50, "Must be less than 50 characters"),
    }),
    enableReinitialize: true,

    async onSubmit(values, { resetForm }) {
      let submittedData = {
        state: values.state,
        businessName: values.businessName,
      }

      if (submittedData.state === "") {
        delete submittedData.state
      }
      if (submittedData.businessName === "") {
        delete submittedData.businessName
      }

      const updateRequest = await dispatch(fetchAllAgentsRequest(submittedData))
      // if (updateRequest) {
      //   resetForm()
      // } else {
      // }
    },
  })

  const handlePageClick = data => {
    let submittedData = {
      state: formOne.values.state,
      businessName: formOne.values.businessName,
    }

    if (submittedData.state === "") {
      delete submittedData.state
    }
    if (submittedData.businessName === "") {
      delete submittedData.businessName
    }
    dispatch(fetchAllAgentsRequest({ page: data.selected, ...submittedData }))
  }

  useEffect(() => {
    dispatch(fetchAllAgentsRequest({ state: formOne.values.state }))
    return () => {}
  }, [])

  return (
    <>
      <Container>
        <ContentHeader
          title={`Locate Kudi Agents`}
          backText="Locate Kudi Agents"
        >
          <form onSubmit={formOne.handleSubmit}>
            <div className={styles.inputWrapper}>
              <div className={searchStyles.inputContainer}>
                <div id={searchStyles.searchIcon}></div>
                <input
                  type="text"
                  onChange={formOne.handleChange}
                  onBlur={formOne.handleBlur}
                  value={formOne.values.businessName}
                  className={cx(
                    searchStyles.formInput,
                    formOne.errors.businessName ? styles.formInputError : ""
                  )}
                  name="businessName"
                  placeholder="Find Agent"
                />
              </div>
              {formOne.touched.businessName && formOne.errors.businessName ? (
                <div className="errorMessage">
                  {formOne.errors.businessName}
                </div>
              ) : null}
            </div>
          </form>
        </ContentHeader>
        <div className="contentHolder">
          <CardContainer>
            <div className={styles.wrapper}>
              <section>
                <div className={styles.showingResults}>
                  Showing results for: <span>{formOne.values.state}</span>
                  <Dropdown
                    type="select"
                    defaultValue={null}
                    options={[
                      { value: "", label: "Select State" },
                      ...NIGERIAN_STATES,
                    ]}
                    className={styles.CustomDropdown}
                    controlClassName={styles.CustomDropdownControl}
                    arrowClassName={styles.CustomDropdownArrow}
                    onBlur={formOne.handleBlur}
                    onChange={async option => {
                      await formOne.setFieldValue("state", option.value)
                      await formOne.handleSubmit()
                    }}
                    name="state"
                    value={formOne.values.state}
                    placeholder="Select State"
                  />
                </div>
              </section>

              {loading ? (
                <p>Fetching agents...</p>
              ) : (
                <section className={styles.locationTable}>
                  <table>
                    <thead>
                      <tr>
                        <th>S/N</th>
                        <th>Business name</th>
                        <th>State</th>
                        <th>Landmark</th>
                      </tr>
                    </thead>
                    <tbody>
                      {agentsData.length < 1 ? (
                        <tr>
                          <td colSpan={4} className={styles.EmptyTable}>
                            <EmptyTableIcon />
                            <p>No data available at this time</p>
                          </td>
                        </tr>
                      ) : (
                        agentsData.map((agent, index) => (
                          <tr key={index}>
                            <td>
                              {numberOfElements * currentPage + index + 1}
                            </td>
                            <td>{agent.businessName}</td>
                            <td>{agent.state}</td>
                            <td>{agent.address}</td>
                          </tr>
                        ))
                      )}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan={2}>
                          Showing {numberOfElements * currentPage + 1} -{" "}
                          {numberOfElements * currentPage + numberOfElements} of{" "}
                          {totalElements} entries
                        </td>
                        <td colSpan={2}>
                          <ReactPaginate
                            previousLabel={"Prev"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            marginPagesDisplayed={2}
                            pageCount={pageCount}
                            forcePage={currentPage}
                            onPageChange={handlePageClick}
                            disableInitialCallback={true}
                            containerClassName={styles.pagination}
                            activeClassName={styles.pagination_active}
                            previousLinkClassName={styles.pagination_control}
                            nextLinkClassName={styles.pagination_control}
                          />
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </section>
              )}
            </div>
          </CardContainer>
        </div>
      </Container>
    </>
  )
}

export default LocateAgents
