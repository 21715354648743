import React, { Fragment } from "react"
import Container from "../container"
import CardContainer from "../card-container"
import Banner from "../banner"
import ContentHeader from "../content-header"
import styles from "./insuranceHome.module.scss"

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={`Cash Insurance Policy`}
          backText="Cash Insurance"
          description={
            <p>
              Please read this Policy carefully to learn more about cash <br />
              insurance on KUDI MOBILE APP (the “APP”)
            </p>
          }
        />
        <div className="contentHolder">
          <CardContainer>
            <div className={styles.cashInsurance}>
              <h3> What is the kudi cash insurance Policy?</h3>

              <p>
                The Kudi Insurance is a policy that provides cover for loss of
                cash in transit between the insured’s premises, bank and other
                specified places occasioned by robbery, theft or any other
                frivolous cause. It also provides cover for loss of cash within
                the business premises, safe or vault and a fidelity guarantee
                (cash theft by the insured’s member of staff).
              </p>
              <h3>Benefits of cash insurance</h3>


              <ol>
                <li>
                  Your money is refunded back to you if stolen (theft or armed
                  robbery) within the limit of occurrence.
                </li>
                <li>Claims process takes a maximum of 21 working days.</li>
                <li>
                  You can have peace of mind knowing your business is insured.
                </li>
              </ol>

              <h3>Policy limit breakdown</h3>

              <p>
                There is a limit to the amount of money that can be given back
                to you each time you lose your money. See breakdown:
              </p>

              <table>
                <thead>
                  <tr>
                    <th>Category</th>
                    <th>Limit per occurence(₦)</th>
                    <th>Annual limit of liability(₦)</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Cash-in-Transit</td>
                    <td>200,000</td>
                    <td>1,500,000</td>
                  </tr>
                  <tr>
                    <td>Cash-in-Safe</td>
                    <td>200,000</td>
                    <td>1,200,000</td>
                  </tr>
                  <tr>
                    <td>Cash-in-Premises</td>
                    <td>200,000</td>
                    <td>1,200,000</td>
                  </tr>
                  <tr>
                    <td>Fidelity Guarantee</td>
                    <td>200,000</td>
                    <td>1,200,000</td>
                  </tr>
                </tbody>
              </table>

              <ol className={styles.unorderlist}>
                <li>
                  Limit per occurrence represents the amount you can get when
                  you report an issue.
                </li>
                <li>
                  Annual Limit of liability represents the total amount of money
                  that can be paid to you in a year.
                </li>
              </ol>

              <h3>Subscription</h3>

              <p>
                The Kudi Cash insurance premium should be ₦24,000 per annum but
                we have developed a seamless method that entails a monthly
                deduction of two thousand naira (₦2000) from your KUDI wallet
                for the next one year (12 months) which will equal the annual
                premium. However, if you are subscribing for a full year, you
                get a discount of ₦2,000 and pay ₦22,000 as the premium for that
                year.
              </p>

              <h3>Terms and Condition</h3>

              <ol>
                <li>
                  Outstanding premium must be deducted before claim settlements{" "}
                </li>
                <li>
                  Agents must always ensure that their outlets are well secured
                </li>
                <li>Cash must not be kept in premises unless in safe/vaults</li>
                <li>There should be security to safeguard business premises</li>
                <li>
                  Withdrawal of cash must be done by the agent or a senior staff
                </li>
                <li>Agent’s negligence won’t be claimed </li>
                <li>Cash must not be left unattended or unaccompanied</li>
                <li>
                  15% policy excess will be deducted on each and every claim
                  settlement
                </li>
                <li>
                  Claim threshold must not be reported more than (2) times a
                  year (Fidelity guarantee related claims only)
                </li>
                <li>
                  Details of an employee, including salary proof must be
                  provided in case of infidelity of such employee (Fidelity
                  guarantee related claims only)
                </li>
                <li>
                  No refund of previously paid premium when insurance is
                  deactivated
                </li>
              </ol>

              <h3>Claim Requirement</h3>
              <p>
                To report an incidence properly, you will need to provide the
                following:
              </p>

              <ol>
                <li>Completed Claim form </li>
                <li>
                  Documentary evidence of claimed amount (Kudi wallet statement
                  of account)
                </li>
                <li>Police report (if theft/burglary applicable)</li>
                <li>Pictures of Damaged safe or drawer.</li>
                <li>Any valid means of Identification</li>
              </ol>

              <h3>Claim Procedure</h3>

              <ol>
                <li>
                  Claim notification must be sent to PRA within 72 hours of
                  occurrence.
                </li>
                <li>
                  Discharge voucher is issued (2) weeks after receiving required
                  documentation.
                </li>
                <li>
                  Claim settlement is made (7) working days after receiving duly
                  completed offer.
                </li>
              </ol>
            </div>
          </CardContainer>
        </div>
      </Container>
      <Banner type="list" />
    </Fragment>
  )
}

export default PrivacyPolicy
