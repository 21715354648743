import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TerminalsTermsAndConditions from  "../components/terminalTnC"

const TerminalsTermsAndConditionsPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="POS Terms & Conditions" description="Nomba's terminal terms and conditions" slug="terminal-terms" />
    <TerminalsTermsAndConditions/>
  </Layout>
)

TerminalsTermsAndConditionsPage.propTypes = {
  location: PropTypes.object,
}

export default TerminalsTermsAndConditionsPage;
