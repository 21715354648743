import React from "react"
import PropTypes from "prop-types"
import styles from "./sub-hero.module.scss"

const SubHero = ({ description }) => {
  return (
    <div className={styles.SubHero}>
      <div dangerouslySetInnerHTML={{ __html: description }}></div>
    </div>
  )
}
SubHero.propTypes = {
  description: PropTypes.string,
}
export default SubHero
