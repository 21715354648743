module.exports = [
  {
    id: "000",
    name: "OVERVIEW",
    description:
      "<p>These POS Terms and Conditions constitute a legally binding agreement made between you, whether personally or on behalf of an entity (“you” or “the Agent”) and Cosmic Intelligent Labs (“we,” “us” or “our”), concerning your access to and use of our Terminal. You agree that by accepting these terms, you have read, understood, and agree to be bound by all of these Terms and Conditions. Cosmic may revise these Terms and Conditions of Use at any time by updating this posting.</p>",
  },
  {
    id: "001",
    name: "1. DEFINITIONS",
    description:
      "<p><b>Agent(s)</b> means a person or entity who successfully subscribes to use the Application. The term Agent shall include the said Agent’s personal representatives, successors in title and assigns as well as persons acting with the Agent’s authority such as servants or employees.<br/><br/>" +
      "<b>Agents Locations</b> means the  physical location or place used by the Agent to conduct business as stated in Agent’s biodata information. <br/><br/>" +
      "<b>Agents Wallet</b> means a digital account on the Kudi Mobile Banking Application that, when funded, allows an Agent to make electronic transactions on behalf of members of the public.<br/><br/>" +
      "<b>Agreement</b> means these POS Terms and Conditions<br/><br/>" +
      "<b>Application</b> means the Kudi Agent Banking Application <br /><br/>" +
      "<b>Card Holder</b> means an individual or company issued with a payment card linked to an account at a licensed financial institution who uses the card to effect a transaction on the Terminal(s). <br/><br/>" +
      "<b>Commencement Date</b> means the date the Terminal(s) is linked to the Agent’s Wallet. <br/><br/>" +
      "<b>“Cosmic”, “we”, “us”, “our”</b> means Cosmic Intelligent Labs Limited and where applicable, its employees, officers and authorized agents. <br/><br/>" +
      "<b>Caution Fee</b> means the POS Lease fee as communicated by us from time to time, and  paid by the Agent before the issuance of Terminal(s) <br/><br/>" +
      "<b>CBN</b> means the Central Bank of Nigeria. <br/><br/>" +
      "<b>Hot list</b> means list of deactivated cards that were reported missing, stolen, lost or damaged by the cardholders. <br /><br/>" +
      "<b>Issuing Bank</b> means Banks or financial institutions that have issued payment cards to their Customers <br/><br/>" +
      "<b>Lease Application</b> means the request for a POS initiated by an Agent via the Application <br/><br/>" +
      "<b>Payment Card</b> Any debit card issued by any Financial Institution in Nigeria. <br/><br/>" +
      "<b>Payment Terminal Service Aggregator/PTSA</b> means the Nigeria Inter-Bank Settlement System, the entity mandated to, among others, act as the Automated Clearing House and payment terminal service aggregator and the Central Switch for Nigeria.  <br /><br/>" +
      "<b>Payment Terminal Services Provider/PTSP</b> means a CBN licensed entity responsible for the deployment, maintenance and support of payment terminals on behalf of acquiring banks. <br/><br/>" +
      "<b>POS</b> means Point of sale terminal <br /><br/>" +
      "<b>POS Lease</b> means an arrangement whereby the agent ‘leases’ the POS terminal from Cosmic and subject the payment of the Caution Fee sum of ₦20,000]. <br /><br/>" +
      "<b>Terminal(s)</b> means the point of sale devices running on the approved software supplied by the Company to the Agent to facilitate payments at the Agent's Locations. <br /><br/>" +
      "<b>Transactions</b> means all transactions done through the point of sale terminal. <br /></p>",
  },
  {
    id: "002",
    name: "2. SERVICES",
    description: "<ol type='a'>" +
      "<li>Cosmic may in its absolute discretion, and subject to the Lease Application by the Agent, deploy its Terminals to any Agent it deems fit.</li>" +
      "<li>Cosmic may lease a Terminal to an agent subject to the fulfilment of the prerequisites as communicated to the Agent from time to time including but not limited to set transaction thresholds and the payment of the lease sum.</li>" +
      "</ol>"
  },
  {
    id: "003",
    name: "3. OWNERSHIP",
    description:
      "<ol type='a'>" +
      "<li>The Terminal(s) remain the property of Cosmic, but the responsibility for the Terminal shall remain with the Agent.</li>" +
      "<li>The Agent must obtain a Terminal Received Voucher (TRV) from Cosmic certifying the condition of the Terminals upon return/surrender of the Terminal(s) to Cosmic. </li>" +
      "</ol>" +
      "<p>Where the Agent obtains a TRV from Cosmic certifying that the Terminal(s) returned/surrendered is in good condition, Cosmic shall refund the Caution Fee paid by the Agent subject to depreciation and logistics costs. Depreciation is calculated from the receipt of the Terminal and at 0% first 3 month [i.e. you will receive full reimbursement]," +
      "50% 4-12months [i.e. you will receive a refund of Ten Thousand Naira" +
      "100% after 12months [i.e. you will no longer be entitled to a refund]. <br/> </p>",
  },
  {
    id: "004",
    name: "4. POINT OF SALE (POS)/TRANSACTION FEES",
    description:
      "<ol type='a'>" +
      "<li>The transaction fees payable by the Agent shall be a percentage of each Transaction or a flat fee per Transaction as decided by Cosmic and communicated from time to time.</li>" +
      "<li>The transaction fee shall be paid to Cosmic and this will be deducted from the Agent’s Wallet.</li>" +
      "<li>The transaction fees payable by the Agent may vary subject to the transaction sum.</li>" +
      "</ol>",
  },
  {
    id: "005",
    name: "5. TERMINAL LOCATION",
    description:
      "<ol type='a'>" +
      "<li>The Terminal(s) shall be used and located only at the Agent's site(s) stated in Agent’s biodata information.</li>" +
      "<li>The Agent must at all times know the location of the Terminal(s). Cosmic shall conduct spot checks to ensure this condition is adhered to. Any re-locations must be approved by Cosmic in writing</li>" +
      "<li>Subcontracting of Terminals to third parties is strictly prohibited, and Cosmic shall only recognize the registered/mapped Agent.</li>" +
      "<li>Cosmic reserves the right to immediately retrieve/recover all of its Terminals should the Terminal be subcontracted to a third party, be used at any other site or location contrary to the location stated herein or be used for any business other than the one stated herein</li>" +
      "</ol>",
  },
  {
    id: "006",
    name: "6. AGENTS OBLIGATIONS",
    description:
      "<ol type='a'>" +
      "<li>As regards each POS Device the Agent shall:" +
      "<ol> " +
      "<li>Promptly honour, without discrimination, all valid Payment Cards.</li>" +
      "<li>Be authorized to refuse to accept payment by means of an electronic payment instrument, including payment with cards, if: the electronic payment instrument is invalid; a notification of loss, missing, stolen or damaged has been made of the electronic payment instrument; the cardholder refuses to present a document confirming his/her identity in the event of suspicious/unauthorized use of electronic payment instruments.</li>" +
      "<li>Reject any unsigned electronic payment card for Transactions.</li>" +
      "<li>Ensure valid identification cards under the Know Your Customer (KYC) Policy document  (i.e. National Identification Card, Driving License, International Passport) are obtained from customers for Transactions in excess of<b> N200,000.00(Two Hundred Thousand Naira only)</b></li>" +
      "<li>Check card security features prior to completing any Transaction. Such check shall include but not be limited to comparing card number on the card with the card number on the POS receipt or voucher.</li>" +
      "<li>Keep for not less than 120 days or such other period as Cosmic may stipulate from time to time details of all Transactions and documents including but not limited to receipt, personal identification e.t.c related to the cardholder and the Transactions and upon the request of Cosmic provide within 7 days from the date of such request such details and documents.</li>" +
      "<li>In case of failure, refusal or neglect to provide such details and documents for any reason whatsoever upon the request of Cosmic, the Agent hereby indemnifies Cosmic against all losses, claims, demands, liabilities, actions, proceedings, damages, cost, fees and expenses arising as a result of non-compliance and shall be bound to pay Cosmic the amount or value of any such losses, claims, demands, liabilities, actions, proceedings, damages, cost, fees and expenses costs including attorney or solicitors fees.</li></ol></li>" +
      "<li>The Agent shall ensure Terminals shall only be operated by staff nominated and authorised by the Agent.</li>" +
      "<li>The Agents’ customers shall not in any way be permitted to operate the Terminal.</li>" +
      "<li>The Agent shall report all suspicious Transactions to Cosmic not later than 12hours after the same or before 12 noon on the next working day.</li>" +
      "<li>The Agent shall display the payment device conspicuously enough for the cardholder to observe the amount entered into the device before the cardholder enters his/her PIN and shall ensure that the \"card never leaves sight of cardholder\" rule is strictly observed.</li>" +
      "<li>Unless otherwise specified, the Agent agrees to retain and provide, at Cosmic’s request, copies of all Sales and Credit Slips and all Card Transactions’ records.</li>" +
      "<li>The Agent shall be required to take good care of the Terminal and keep it away from any possible contact with fluids, food or materials which may damage its functionality and immediately notify Cosmic if the POS Terminal is damaged or stolen.</li>" +
      "<li>The Agent will be liable for the full repair or replacement costs of a Terminal that is damaged, lost or misplaced whilst in the Agents’ custody, as result of the Agent’s negligence and subject to the provision of section 3(c) above. A schedule of costs is available from Cosmic upon request.</li>" +
      "<li>The Agent shall allow Cosmic and parties authorised by Cosmic to have access to the Terminal for routine support, repair and or replacement.</li>" +
      "<li>The Agent shall ensure that Terminals are not used for fraudulent activities and shall be held liable for frauds arising from its negligence or connivance.</li>" +
      "<li>The Agent shall ensure that Terminals are carefully maintained and secured against damages and theft in locations.</li>" +
      "<li>The Agent shall not tamper with the hardware or application loaded on the Terminal.</li>" +
      "<li>Where the Terminal is damaged beyond repair within <b> three (3) months</b> of deployment, the agent shall bear the cost of replacement, subject to the provision of sections 3(c) and 6(7)(h) above.</li></ol>",
  },
  {
    id: "007",
    name: "7. CHARGEBACK",
    description:
      "<ol type='a'>" +
      "<li>The Agent shall not under any circumstance refund the cardholder in cash for unsuccessful or declined Transactions. The Agent should refer the cardholder to the Issuing Bank to lodge their claim and/or the Agent should lodge the claim with Cosmic.</li>" +
      "<li>Cosmic will not be held liable for any losses which the Agent may incur from any refund in  (a) above.</li>" +
      "</ol>",
  },
  {
    id: "008",
    name: "8. CARD FRAUD",
    description:
      "<ol type='a'>" +
      "<li>In the event of a valid fraudulent Transaction claim, the Agent would be charged 105% of the value of the claim if the Agent is found to be negligent in the area of enforcing cardholder identification at the point of receiving the cardholder's payment card to be used on the Terminal.</li>" +
      "<li>In the event of a valid fraudulent Transaction claim, if the Agent is found to have connived with the perpetrator(s), the Agent would be reported to the law enforcement agencies and/or blacklisted from Transactions by the Cosmic/ any financial institution or/ and regulatory authorities or all the penalties stated in this section</li>" +
      "<li>Cosmic may freeze the Agent’s account in an event where a fraudulent Transaction is reported on the POS Terminal deployed to the Agent’s location.</li>" +
      "</ol>",
  },
  {
    id: "009",
    name: "9. OBLIGATIONS OF COSMIC",
    description:
      "<ol type='a'>" +
      "<li>Only deploy and support POS Terminals through a CBN licensed Payment Terminal Services Provider (PTSP)</li>" +
      "<li>Ensure that Terminals deployed at the Agent location(s) through the PTSP and shall accept all payment cards (card agnostic).</li>" +
      "<li>Ensure that the Terminals are connected directly to the Payments Terminal Service Aggregator.</li>" +
      "<li>Perform prompt settlement of the Agent. </li>" +
      "<li>Liaise with the PTSP to ensure that faulty Terminals are attended to within the scheduled time frame of 24 hours for Lagos and 72 hours for locations outside Lagos, subject to the availability and support of the PTSP.</li>" +
      "<li>Ensure that, subject to the filling of appropriate forms required for Agent registration/onboarding, a match check is conducted on the Agent to verify a good track record before the Agent is issued a POS.</li>" +
      "<li>Provide continuous training of the Agent on Terminal usage at the Agent’s locations.</li>" +
      "</ol>",
  },
  {
    id: "010",
    name: "10. TECHNICAL SUPPORT SERVICES",
    description:
      "<ol type='a'>" +
      "<li>Hardware technical support and services shall be carried out by Cosmic’s technical department.</li>" +
      "<li>All Terminal repairs shall be done on a carry-in basis.</li>" +
      "<li>The Agent may call for hardware support by calling our customer care numbers [018885008] or sending the email to support@kudi.com. </li>" +
      "</ol>",
  },
  {
    id: "011",
    name: "11. TERM, TERMINATION AND RETRIEVAL",
    description:
      "<ol type='a'>" +
      "<li>These terms and conditions shall be deemed effective and in full force from the date of Activation of the Terminal.</li>" +
      "<li>You may also terminate this Agreement at any time by deactivating your Account with us and returning the Terminal.</li>" +
      "<li>The Terminal is, and will at all times be and remain, the sole and exclusive property of Cosmic.</li>" +
      "<li>Cosmic reserves the right to withdraw or repossess the POS from the Agent without prior notice or liability for any reason whatsoever, including without limitation if you breach any of the terms.</li>" +
      "<li>In addition to the above, Cosmic reserves the right to recall non-performing Terminal(s) and/or give the notice to terminate the contract with Agents holding non-performing Terminal(s). Such Terminals should be returned within 30 calendar days.</li>" +
      "<li>If this Agreement or your Account with us is terminated or suspended for any reason: (a) the rights granted under this Agreement will end, (b) you agree to immediately terminate and cease use of the Terminals, (c) we may (but have no obligation to) delete your information and account data stored on our servers, and (c) we will not be liable to you or any third party for compensation, reimbursement, or damages for any termination or suspension of use of the Terminals, or for deletion of your information or account data. In addition to any payment obligations under this Agreement, the following sections of this Agreement shall survive and remain in effect in accordance with their terms upon termination: 6(4); 6(7)[Agent Obligations]; 7[Chargeback], 8[Card Fraud], 13 [Indemnification]; 14[Assignment]; 16[Governing Law]; 17[Dispute]</li>" +
      "</ol>",
  },
  {
    id: "012",
    name: "12. ENCUMBRANCES",
    description:
      "<p>The Agent will not grant any security interest in the Terminals to a third party, and will specifically exclude it from any equipment, fixture, floor or blanket secured financing and will otherwise keep it free and clear of all levies, liens and encumbrances, including any that may arise from Agent's failure to pay any assessments, taxes or similar governmental charges.<br /></p>",
  },
  {
    id: "013",
    name: "13. INDEMNIFICATION",
    description:
      "<ol type='a'>" +
      "<li>In consideration of the Services offered by  Cosmic the Agent hereby irrevocably and unconditionally undertakes and covenants that it shall at all times hereafter well and truly indemnify Cosmic and keep Cosmic fully indemnified against all losses, claims, demands, liabilities, actions, proceedings, damages, cost, fees and expenses which may be occasioned to the Cosmic in consequence of:" +
      "<ol>" +
      "<li>Agent’s failure to comply with or to enforce any provision of this Agreement;</li>" +
      "<li>Any loss if fraud precautions are not taken by the Agent;</li>" +
      "<li>Any loss in the case of any Transaction in which a Card was not presented; and</li>" +
      "<li>Any loss caused by the Agent’s violation of Card Schemes’ rules or regulations or the laws of the Federal Republic of Nigeria or of any other country.</li>" +
      "</li></ol>",
  },
  {
    id: "014",
    name: "14. ASSIGNMENT",
    description:
      "<p>This Agreement and any rights and licences granted hereunder, may not be transferred or assigned by you/the Agent and any attempted transfer or assignment will be null and void. <br/></p>",
  },
  {
    id: "015",
    name: "15. AMENDMENT",
    description:
      "<ol type='a'>" +
      "<li>These POS Terms and Conditions shall be read in conjunction with the Terms and Condition of the Application where applicable.</li>" +
      "<li>These POS Terms and Conditions may be reviewed and re-negotiated in the light of changing market conditions. Therefore, this contract is subject to periodic updates.</li>" +
      "<li>Supplemental terms and conditions or documents that may be posted on the Application from time to time are hereby expressly incorporated herein by reference. We reserve the right, in our sole discretion, to make changes or modifications to these Terms and Conditions at any time and for any reason. We will alert you about any changes by updating the “Last updated” date of these Terms and Conditions and you waive any right to receive specific notice of each such change. It is your responsibility to periodically review these Terms and Conditions to stay informed of updates. You will be subject to, and will be deemed to have been made aware of and to have accepted, the changes in any revised Terms and Conditions by your continued use of the Application after the date such revised Terms are posted.</li>" +
      "</ol>",
  },
  {
    id: "016",
    name: "16. GOVERNING LAW",
    description:
      "<p>These terms and conditions will be governed and interpreted pursuant to the laws of the Federal Republic of Nigeria.</p>",
  },
  {
    id: "017",
    name: "17. DISPUTE",
    description:
      "<p>Any and all disputes, claims or litigation arising from or related in any way to these Terms or any provisions herein will be resolved by mediation at the Lagos Multi Door Court House, Lagos State, Nigeria. Where such dispute persists, it shall then be resolved by Nigerian Courts in Lagos State. The parties hereby waive any objections against and expressly submit to the exclusive jurisdiction of the Nigerian Courts in Lagos State.</p>",
  },
]
