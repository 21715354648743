import {
    ASYNC_START,
    LOG_ERROR,
    GET_STORIES,
    SET_STORIES,
    SET_ACTIVE_CATEGORY,
    PAGINATE,
    SET_PAGE,
    SET_SEARCH_TERM,
    SEARCH_STORIES,
    SET_SEARCH_PAGE,
  } from "../actions/types"
  
  export default (
    state = {
      activeCategory: "all",
      stories: [],
      limit: 5,
      offset: 0,
      page: 1,
      filteredStories: [],
      searchTerm: "",
      searchResult: [],
      isSearch: false,
      inProgress: true
    },
    action
  ) => {
    switch (action.type) {
      case SET_STORIES:
        return {
          ...state,
          stories: action.payload,
          filteredStories: [],
          offset: 0,
          page: 1,
          inProgress: false,
          isSearch: false,
        }
      case SET_SEARCH_TERM:
        return {
          ...state,
          searchTerm: action.term,
        }
      case SEARCH_STORIES:
        return {
          ...state,
          offset: 0,
          page: 1,
          searchResult: action.payload.result,
          isSearch: action.payload.isSearch,
          filteredStories: action.payload.filteredStories,
        }
      case SET_ACTIVE_CATEGORY:
        return {
          ...state,
          activeCategory: action.category,
        }
      case SET_PAGE:
        return {
          ...state,
          page: action.page,
        }
      case SET_SEARCH_PAGE:
        return {
          ...state,
          page: 1,
          filteredStories: action.filteredStories,
        }
      case PAGINATE:
        return {
          ...state,
          filteredStories: action.filteredStories,
        }
      case ASYNC_START:
        if (action.subtype === GET_STORIES) {
          return {
            ...state,
            inProgress: true,
          }
        }
        return state
      case LOG_ERROR:
        return {
          ...state,
          inProgress: false,
          error: action.error || "Sorry, something went wrong. Please try again",
        }
      default:
        return state
    }
  }