import React, { Fragment } from "react"
import ProgressiveImage from "react-progressive-image"
import Container from "../container"
import ContentHeader from "../content-header"
import CardContainer from "../card-container"
import cx from "classnames"
import styles from "./covid.module.scss"
import Banner from "../banner"
import WidePattern from "../wide-pattern"
import Covid1 from "../../assets/images/covid-1.png"
import Covid2 from "../../assets/images/covid-2.png"
import Covid3 from "../../assets/images/covid-3.png"
import Covid4 from "../../assets/images/covid-4.png"
import Covid5 from "../../assets/images/covid-5.png"
import Covid6 from "../../assets/images/covid-6.png"
import Covid7 from "../../assets/images/covid-7.png"
import Covid8 from "../../assets/images/covid-8.png"
import Covid1Tiny from "../../assets/images/covid-1tiny.png"
import Covid2Tiny from "../../assets/images/covid-2tiny.png"
import Covid3Tiny from "../../assets/images/covid-3tiny.png"
import Covid4Tiny from "../../assets/images/covid-4tiny.png"
import Covid5Tiny from "../../assets/images/covid-5tiny.png"
import Covid6Tiny from "../../assets/images/covid-6tiny.png"
import Covid7Tiny from "../../assets/images/covid-7tiny.png"
import Covid8Tiny from "../../assets/images/covid-8tiny.png"

const Covid = () => (
  <Fragment>
    <Container>
      <ContentHeader
        title={`Kudi’s Response to COVID-19`}
        backText="COVID-19"
        description={`Kudi is committed to helping curb the spread of the corona virus.
             Here’s how we’re helping our communities`}
      ></ContentHeader>
      <div className="contentHolder">
        <CardContainer>
          <div className={cx("resources", styles.Covid)}>
            <div className={styles.CovidImage}>
              <ProgressiveImage src={Covid1} placeholder={Covid1Tiny}>
                {src => (
                  <img
                    className={styles.item1}
                    src={src}
                    alt="Safe Kudi POS transactions"
                  />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid2} placeholder={Covid2Tiny}>
                {src => (
                  <img
                    className={styles.item2}
                    src={src}
                    alt="Keep safe and sanitize"
                  />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid3} placeholder={Covid3Tiny}>
                {src => (
                  <img className={styles.item3} src={src} alt="Wear gloves" />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid4} placeholder={Covid4Tiny}>
                {src => (
                  <img
                    className={styles.item4}
                    src={src}
                    alt="Remember to wash your hand, Kudi cares"
                  />
                )}
              </ProgressiveImage>
            </div>
            <div className={styles.CovidText}>
              <p>
                It feels like someone somewhere has pressed the pause button on
                Mother Earth. COVID-19 has hit the million mark and somehow it
                has been a leveler. World leaders are stuck, businesses have
                come to a grinding halt, sources of livelihood disappeared,
                thousands have lost jobs and hundreds of thousands are not
                earning in the fight to end this once-in-a-lifetime pandemic.{" "}
              </p>
              <p>
                Kudi is an agent-focused business and our agents all over the
                country are trying to meet the needs of unbanked and underbanked
                consumers who don’t have access to banking services. While the
                world adjusts to living in a new way for the health of every
                person, we will keep looking for ways to be a part of the
                solution.
              </p>
              <p>
                The CBN directive permitting critical financial services to
                function during this period allows our agents to keep operating
                all over the country and we are particular about being a part of
                the solution and helping our agents navigate these strange
                times.
              </p>
              <p>
                The World Health Organization has recommended infection
                prevention and control measures including hand hygiene -
                handwashing the use of hand sanitizers and Personal Protective
                Equipment (PPE) including gloves, surgical and face masks. Kudi
                started playing her part by providing PPE to over 2,000 agents
                all over the country.
              </p>
            </div>
            <div className={styles.CovidFooter}>
              <ProgressiveImage src={Covid5} placeholder={Covid5Tiny}>
                {src => (
                  <img
                    className={styles.item5}
                    src={src}
                    alt="Safe Transactions"
                  />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid6} placeholder={Covid6Tiny}>
                {src => (
                  <img
                    className={styles.item6}
                    src={src}
                    alt="Social distance"
                  />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid7} placeholder={Covid7Tiny}>
                {src => (
                  <img
                    className={styles.item7}
                    src={src}
                    alt="Transact safely"
                  />
                )}
              </ProgressiveImage>
              <ProgressiveImage src={Covid8} placeholder={Covid8Tiny}>
                {src => (
                  <img
                    className={styles.item8}
                    src={src}
                    alt="Sanitize your Card"
                  />
                )}
              </ProgressiveImage>
            </div>
          </div>
        </CardContainer>
      </div>
    </Container>
    <WidePattern type="dark" />
    <Banner type="list" />
  </Fragment>
)

Covid.propTypes = {}

export default Covid
