import React from "react"
import { Link } from "gatsby"
import Container from "../container"

import styles from "./welcome.module.scss"
import Button from "../button"
import { WelcomeToKudiImage } from "../../assets/svg"

const Welcome = ({ }) => {
  return (
    <>
      <Container>
        <div className={styles.verifyContainer}>
          <div className={styles.verifyContainer__inner}>
            <h2>Welcome to Kudi</h2>
            <p>
              We are excited to have you onboard as a partner in achieving our
              goal to make financial services accessible and affordable for all.
              Download the mobile app and login to proceed
            </p>

            <p>
              <WelcomeToKudiImage></WelcomeToKudiImage>
            </p>

            <div className={styles.verifyContainer__inner_footer}>
              <div>
                <Button
                  onClick={() =>
                    window.open(
                      "https://play.google.com/store/apps/details?id=ai.kudi.agent&hl=en",
                      "_blank"
                    )
                  }
                >
                  Download App
                </Button>
              </div>
              <div>
                <small>
                  <Link to="/">Go to Home Page</Link>
                </small>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </>
  )
}

export default Welcome
