import React from "react"
import { navigate } from "gatsby"
import Image from "../images/savings-her.png"
import ImageTiny from "../images/savings-hero-tiny.jpg"
import { SavingsPlan, QuickWithdrawal } from "../svg"

export const heroData = {
  image: Image,
  imageTiny: ImageTiny,
  description:
    "<p>Helping underbanked and unbanked Nigerians carry out daily thrift collections seamlessly.</p>",
  header:
    "A <span class='underlined'> <span><i>‘Beta’ </i> </span></span> Way <br/>to Save!",
  buttonText: "Get Started",
  alt: "Kudi Savings",
  page: "savings",
  link: () => navigate("/markets"),
  yPercent: "20",
  type: "stretch",
}

export const subHero =
  "<p>What we <span class='underlined--black'> <span>guarantee.</span></span></p>"
export const subProduct =
  "<p>Explore our <span class='underlined--black'> <span>product</span></span>  <br/> <span class='underlined--black'> <span>offerings.</span></span></p>"

export const features = [
  {
    background: "blue",
    animate: "fade-up",
    icon: <SavingsPlan />,
    title: "Secure Savings Plan",
    description:
      "An Agent assigned to you will be available to collect your savings at your convenience and our automated SMS system will keep you updated on your total savings per time.",
  },
  {
    background: "blue",
    animate: "fade-up",
    icon: <QuickWithdrawal />,
    title: "Quick Withdrawals",
    description:
      "Your assigned Agent will always be within reach so you never have to worry about making withdrawals during an emergency.",
  },
]
export const bannerText =
  "We are trusted by millions of people across different markets in Nigeria."
