import React, { Fragment } from "react"
import Container from "../container"
import CardContainer from "../card-container"
import Banner from "../banner"
import ContentHeader from "../content-header"

import styles from "./markets.module.scss"
import { contact, title } from "../../assets/pageData/markets"
const Terms = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={title}
          backText="Contact"
          description={" "}
        ></ContentHeader>
        <div className="contentHolder">
          <CardContainer>
            <div className="resources">
              <div className={styles.Fees}>
              <table className={styles.Table}>
                  <thead>
                    <tr>
                      {contact &&
                        contact.columns.map(({ render }, i) => (
                          <th key={`${render}-${i}`}>{render}</th>
                        ))}
                    </tr>
                  </thead>
                  <tbody>
                    {contact &&
                      contact.data.map(
                        ({ markets, zone, zonal_head, contact }, index) => (
                          <tr key={`${title}-${index}`}>
                            <td dangerouslySetInnerHTML={{ __html: markets }} />
                            <td>{zone}</td>
                            <td>{zonal_head} </td>
                            <td>{contact} </td>
                          </tr>
                        )
                      )}
                  </tbody>
                </table>
              </div>
            </div>
          </CardContainer>
        </div>
      </Container>
      <Banner type="list" />
    </Fragment>
  )
}

export default Terms
