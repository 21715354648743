import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { window } from "browser-monads"
import { FirebaseContext } from "gatsby-plugin-firebase"
import cx from "classnames"
import { Close } from "../../assets/svg"
import styles from "./header.module.scss"

const NavList = ({ show, closeFn }) => {
  const isAboutUs = window.location.pathname.includes("/about-us")
  const isHome = window.location.pathname === "/"
  const firebase = useContext(FirebaseContext)
  const isCareers = window.location.pathname.includes("/careers")
  const isStories = window.location.pathname.includes("/stories")
  // const isProducts = window.location.pathname.includes("/products")
  const isFaq = window.location.pathname.includes("/faq")
  const addAnalytics = name => {
    return () => {
      firebase.analytics().logEvent(name)
    }
  }
  return (
    show && (
      <div className={styles.navList}>
        <div className={styles.navListClose}>
          <Close onClick={closeFn} />
        </div>
        <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isHome,
          })}
          to="/"
        >
          Home
        </Link>
        <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isAboutUs,
          })}
          to="/about-us"
          onClick={addAnalytics("visit_about_us")}
        >
          About Us
        </Link>
        {/* <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isProducts,
          })}
          to="/products"
        >
          Products
        </Link> */}
        <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isStories,
          })}
          to="/stories"
          onClick={addAnalytics("visit_stories")}
        >
          Stories
        </Link>
        <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isCareers,
          })}
          to="/careers"
          onClick={addAnalytics("visit_careers")}
        >
          Careers
        </Link>

        <Link
          className={cx(styles.navListItem, {
            [styles.navListItemActive]: isFaq,
          })}
          to="/faq"
          onClick={addAnalytics("visit_FAQ")}
        >
          FAQ
        </Link>
      </div>
    )
  )
}

NavList.propTypes = {
  show: PropTypes.bool.isRequired,
}

export default NavList
