import React, { createContext, Component } from "react"
import { ToastContainer } from 'react-toastify';
import PropTypes from "prop-types"
import cx from "classnames"
import "url-search-params-polyfill"
import { connect } from "react-redux"
import { navigate } from "gatsby"
import { window } from "browser-monads"
import * as actions from "../../store/actions/common"
import { getOpeningsRequest } from "../../store/actions/job-openings"
import AOS from "aos"
import "aos/dist/aos.css"
import styles from "./layout.module.scss"
import Header from "../header"
import Footer from "../footer"

import 'react-toastify/dist/ReactToastify.css';

const layoutContext = createContext()

const { Provider } = layoutContext

class Layout extends Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    style: PropTypes.object,
    onClick: PropTypes.func,
    location: PropTypes.object,
    className: PropTypes.string,
    showNav: PropTypes.bool,
    disableHeader: PropTypes.bool,
    disableFooter: PropTypes.bool,
    onAppLoad: PropTypes.func,
    onRedirect: PropTypes.func,
  }

  componentWillReceiveProps(nextProps) {
    const { onRedirect } = this.props
    if (nextProps.redirectTo) {
      navigate(nextProps.redirectTo)
      onRedirect()
    }
  }

  componentDidMount() {
    this._handleAppLoading()
    // this._handleRedirect()
    AOS.init()
  }

  _handleAppLoading = () => {
    const { onAppLoad, appLoaded, getOpeningsRequest } = this.props
    if (!appLoaded) {
      onAppLoad()
      getOpeningsRequest()
    }
  }

  // _handleRedirect = () => {
  //   const { onRedirect } = this.props
  //   const token = window.localStorage.getItem("jwt")
  //   if (!token && window.location.pathname.split("/").includes("checkout")) {
  //     navigate("/insurance", { replace: true })
  //     onRedirect()
  //   }
  //   if (token && window.location.pathname.split("/").includes("sign-in")) {
  //     navigate("/insurance/checkout", { replace: true })
  //     onRedirect()
  //   }
  // }

  render() {
    const {
      children,
      style,
      onClick,
      location,
      className,
      showNav,
      disableHeader,
      disableFooter,
    } = this.props
    const urlParams = new URLSearchParams(location.search)
    const header = urlParams.get("header")

    return (
      <Provider
        value={{
          disableHeaderParam: header === "disable" ? "?header=disable" : "",
        }}
      >
        <div
          onClick={onClick}
          style={style}
          className={cx(className, styles.layout)}
          ref={this.layoutRef}
        >
          <Header showNav={showNav} />
          <main>{children}</main>
          <ToastContainer />
          <Footer />
        </div>
      </Provider>
    )
  }
}

Layout.context = layoutContext

const mapStateToProps = ({ common: { appLoaded, redirectTo, token } }) => {
  return {
    appLoaded,
    redirectTo,
    token,
  }
}

export default connect(mapStateToProps, { ...actions, getOpeningsRequest })(
  Layout
)
