import React from "react"
import styles from "./social.module.scss"

import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from "react-share"

const socialShare = link => {
  return (
    <div className={styles.Social}>
      <p>Share Job:</p>
      <div>
        <FacebookShareButton url={link}>
          <FacebookIcon className={styles.socialIcon} />
        </FacebookShareButton>
        <TwitterShareButton url={link}>
          <TwitterIcon className={styles.socialIcon} />
        </TwitterShareButton>
        <LinkedinShareButton url={link}>
          <LinkedinIcon className={styles.socialIcon} />
        </LinkedinShareButton>
        <WhatsappShareButton url={link}>
          <WhatsappIcon className={styles.socialIcon} />
        </WhatsappShareButton>
      </div>
    </div>
  )
}

export default socialShare
