import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import MarketPlace from "../components/marketplace"

const MarketplacePage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO
      title="Kudi Marketplace: Digitizing Offline Payment across Nigeria"
      description="With Kudi's fast-growing network of over 50,000 Mobile Money Agents, you can reach new offline customers with financial services such as bill payment savings and loans, insurance subscription, fee collection, mass payout, bank account and wallet creation and many more."
      slug="marketplace"
    />
    <MarketPlace />
  </Layout>
)

MarketplacePage.propTypes = {
  location: PropTypes.object,
}

export default MarketplacePage
