import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"
import InsuranceHome from "../components/insurance"

const Insurance = ({ location }) => (
  <Layout location={location}>
    <SEO
      title="Kudi Cash Insurance"
      slug="insurance"
      keywords="Kudi Cash Insurance, Agent Business Insurance"
      description="With Kudi Cash Insurance you can be rest assured that we’ve got you covered with a plan that works for you and your business"
    />
    <InsuranceHome />
  </Layout>
)

Insurance.propTypes = {
  location: PropTypes.object,
}

export default Insurance
