import { window } from "browser-monads"
import { setToken } from "../../lib"
import { LOGIN, LOGOUT, SELECT_INSURANCE } from "../actions/types"

const localStorageMiddleware = store => next => action => {
  if (action.type === LOGIN) {
    let token
    let kyc
    let wallet
    let tokenExpires
    if (!action.error) {
      token = action.token
      kyc = action.agent
      wallet = action.wallet
      tokenExpires = action.expires
      window.localStorage.setItem("jwt", token)
      window.localStorage.setItem("kyc", JSON.stringify(kyc))
      window.localStorage.setItem("wallet", JSON.stringify(wallet))
      window.localStorage.setItem("tokenExpires", JSON.stringify(tokenExpires))
      setToken(token)
    }
  } else if (action.type === SELECT_INSURANCE) {
    window.localStorage.setItem("selectedPlan", action.slug)
  } else if (action.type === LOGOUT) {
    window.localStorage.removeItem("jwt")
    window.localStorage.removeItem("kyc")
    window.localStorage.removeItem("wallet")
    window.localStorage.removeItem("tokenExpires")
    window.localStorage.removeItem("selectedPlan")
    setToken(null)
  }
  next(action)
}

export default localStorageMiddleware
