import React from "react"
import styles from "./wrapper.module.scss"

const Wrapper = ({ children, bg, style }) => {
  return (
    <div style={style} className={[styles.wrapper, styles[`${bg}`]].join(" ")}>
      {children}
    </div>
  )
}

export default Wrapper
