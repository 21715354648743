import React, { Fragment} from "react"
import Container from "../container"
import CardContainer from "../card-container"
import Banner from "../banner"
import ContentHeader from "../content-header"
import Accordion from "../accordion"
import privacyData from "../../assets/pageData/policy"

const PrivacyPolicy = () => {
  return (
    <Fragment>
      <Container>
        <ContentHeader
          title={`Privacy Policy`}
          backText="Privacy Policy"
          description={`Cosmic Intelligent Labs Limited knows that you care how information about you is used and shared. Please read this Privacy Policy carefully to learn more about what information is collected, used and stored on this KUDI MOBILE APP (the “APP”).`}
        ></ContentHeader>
        <div className="contentHolder">
          <CardContainer>
            <div className="resources">
              {privacyData && <Accordion data={privacyData} />}
            </div>
          </CardContainer>
        </div>
      </Container>
      <Banner type="list" />
    </Fragment>
  )
}

export default PrivacyPolicy
