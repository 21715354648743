import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

import Career from "../components/career"

const CareersPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO
      title="Careers"
      slug="careers"
      description="We’re working to find innovative ways to help Africans acces affordable financial services. Help us keep moving, find out how you can be a part of our team"
      keywords="Become a Kudi Agent, All about Kudi Hq, Kudi Insurance, money, Financial services in Africa, Loans in Nigeria, Agency Banking, Innovative Agency banking, Banking in local communities, Join us In Kudi, Careers at Kudi, Careers in Kudi, KudiMoney, Kudi moni, funds, local funds"
    />
    <Career />
  </Layout>
)

CareersPage.propTypes = {
  location: PropTypes.object,
}

export default CareersPage
