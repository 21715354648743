import Image from "../images/insurance-hero.png"
import ImageTiny from "../images/insurance-hero-tiny.png"

export const heroData = {
  image: Image,
  imageTiny: ImageTiny,
  description:
    "<p>Get protected for up to 2 million naira for as low as ₦100 daily.</p>",
  header:
    " Protect Your<br/><span class='underlined'> <span>Agent Business</span></span>",
  buttonText: "Start Now",
  alt: "Kudi Insurance",
  page: "insurance",
  link: () => (window.location.href = `#plans`),
  type: "stretch",
  yPercent: "30",
}

export const insuranceOptions = [
  {
    slug: "KUDI_PLUS",
    title: "Kudi Plus",
    price: "₦15,500",
    unformattedPrice: "15500",
    popular: false,
    details: `<p><span>Cash in Transit Cover</span><br/>
  Limit per carriage ₦500,000<br/>
  Estimated annual carriage ₦1,500,000</p><br/>
  
  <p><span>Cash in Premises Cover</span><br/>
  Limit per occurrence ₦250,000<br/>
  Limit annual occurrence: ₦1,500,000</p><br/>
  
  <p><span>Cash in Safe Cover</span><br/>
  Limit per occurrence 250,000<br/>
  Limit annual occurrence: 1,500,000</p>`,
  },
  {
    slug: "KUDI_LITE",
    title: "Kudi Lite",
    price: "₦8,500",
    unformattedPrice: "8500",
    popular: true,
    details: `<p><span>Cash in Transit Cover</span><br/>
  Limit per carriage ₦500,000<br/>
  Estimated annual carriage ₦1,500,000</p><br/>
  
  <p><span>Cash in Premises Cover</span><br/>
  Limit per occurrence ₦250,000<br/>
  Limit annual occurrence: ₦1,000,000</p>`,
  },
  {
    slug: "KUDI_EXTRA",
    title: "Kudi Extra",
    price: "₦25,500",
    unformattedPrice: "25500",
    popular: false,
    details: `<p><span>Cash in Transit Cover</span><br/>
  Limit per carriage ₦500,000<br/>
  Estimated annual carriage ₦1,500,000</p><br/>
  
  <p><span>Cash in Premises Cover</span><br/>
  Limit per occurrence ₦250,000<br/>
  Limit annual occurrence: ₦1,000,000</p><br/>
  
  <p><span>Cash in Safe Cover</span><br/>
  Limit per occurrence ₦250,000<br/>
  Limit annual occurrence: ₦1,500,000</p><br/>
  
  <p><span>Employee Cover</span><br/>
  Payable per employee ₦10,000</p>`,
  },
]
