import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links";
import styles from "./documentSideNav.module.scss"

const DocumentSideNav = ({ pageData, sideNavTitle }) => {
  return (
    <div className={styles.sideNav}>
      <h2>{sideNavTitle}</h2>
      <ul>
        {pageData &&
          pageData.map(data => (
            <AnchorLink key={data.id} to={`/cookies/#${data.id}`}>
              <li>{data.header}</li>
            </AnchorLink>
          ))}        
      </ul>
    </div>
  )
}

export default DocumentSideNav
