import {
  SELECT_INSURANCE,
  SET_ACTIVE_INSURANCE,
  APP_LOAD,
  LOGIN,
  ASYNC_START,
  PURCHASE_INSURANCE,
  LOG_ERROR,
  CLEAR_ERROR,
} from "../actions/types"
import { getInsurance } from "../../lib"

export default (state = {}, action) => {
  switch (action.type) {
    case SELECT_INSURANCE:
      return {
        ...state,
        selected: getInsurance(action.slug),
      }
    case SET_ACTIVE_INSURANCE:
      return {
        ...state,
        inProgress: false,
        active: getInsurance(action.slug),
      }
    case ASYNC_START:
      if (action.subtype === PURCHASE_INSURANCE) {
        return {
          ...state,
          inProgress: true,
        }
      }
      return state
    case LOG_ERROR:
      return {
        ...state,
        inProgress: false,
        error: action.error || "Sorry, something went wrong. Please try again",
      }
    case CLEAR_ERROR:
      return {
        ...state,
        error: "",
      }
    case LOGIN:
      return {
        ...state,
        active:
          action.agent && action.agent.insurancePlan
            ? getInsurance(action.agent.insurancePlan)
            : null,
      }
    case APP_LOAD:
      return {
        ...state,
        selected: getInsurance(action.selectedPlan),
        active:
          action.kyc && action.kyc.insurancePlan
            ? getInsurance(action.kyc.insurancePlan)
            : null,
      }
    default:
      return state
  }
}
