import React, { useState, useEffect } from "react"
import { toast } from "react-toastify"
import Countdown from "react-countdown"
import PinInput from "react-pin-input"
import { useDispatch, useSelector } from "react-redux"
import { Link, navigate } from "gatsby"

import Container from "../container"

import styles from "./register.module.scss"
import Button from "../button"
import { Refresh, LostPhone } from "../../assets/svg"
import {
  verifyAgentOTPRequest,
  resendAgentOTPRequest,
} from "../../store/actions/verfiy-otp"

const RCprops = {
  inputStyle: {
    margin: "4px",
    width: "55px",
    fontSize: "18px",
    marginRight: "25px",
    marginLeft: "-10px",
    color: "#0E2D8F",
    backgroundColor: "white",
    border: "1px solid #D2DDEB",
    borderRadius: "3px",
    height: "55px",
  },
}

const Register = (props) => {
  const [otp, setOTP] = useState(null)
  const [pin, setPin] = useState(null)
  const [checked, setChecked] = useState(false)
  const [countDown, setCounterDown] = useState(Date.now() + 240000)
  // FINAL URL https://kudi.com/reg/0ad1c8279476d0131ab0d11
  // let params = new URLSearchParams(props.location.search)
  const [registrationId, setRegistrationId] = useState(props.regId)

  const dispatch = useDispatch()
  const loading = useSelector(({ common }) => common.verifyAgentLoading)
  const verifyAgentData = useSelector(({ common }) => common.verifyAgent)

  const handleSubmit = async () => {
    if (!otp && !pin) {
      return toast.info("Sorry, PIN and OTP are required.")
    }
    if (!checked) {
      return toast.info("Agree to Kudi's Term of Use")
    }
    if (registrationId === null) {
      return toast.error("Invalid path or no registration ID provided")
    }
    const data = {
      registrationId: registrationId,
      newAgentActivationRequest: {
        otp: otp,
        pin: pin,
      },
    }
    const submitted = await dispatch(verifyAgentOTPRequest(data))

    if (submitted) {
      navigate("/welcome-to-kudi")
    }
  }

  const handleResendOTP = async ()=> {
    const resend = await dispatch(resendAgentOTPRequest(registrationId))

    if(resend) {
      setCounterDown(Date.now() + 240000)
    }
  }

  useEffect(() => {
    return () => {
    }
  }, [otp, pin, countDown])

  return (
    <>
      <Container>
        <div className={styles.verifyContainer}>
          {loading ? (
            <h3>Loading...</h3>
          ) : (
            <>
              {" "}
              <div className={styles.verifyContainer__inner}>
                <h2>Verify Phone Number</h2>
                <p>{`We’ve sent a text message to your phone number, enter the 4-digit code below to continue.`}</p>

                <PinInput
                  length={4}
                  initialValue=""
                  secret={false}
                  onChange={(value, index) => {
                    setOTP(value)
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={RCprops.inputStyle}
                  inputFocusStyle={{ borderColor: "blue" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />

                <div className={styles.verifyContainer__inner_footer}>
                  <div>
                    <button
                      onClick={handleResendOTP}
                      className={styles.transparentBtn}
                    >
                      <Refresh />
                      Resend OTP
                    </button>
                  </div>
                  <div>
                    <p>
                      Time Left{" "}
                      <Countdown
                        renderer={props => (
                          <span>{`${props.minutes} : ${props.seconds}`}</span>
                        )}
                        key={677}
                        onComplete={() => {
                          setCounterDown(Date.now() + 240000)
                        }}
                        date={countDown}
                      />
                    </p>
                  </div>
                </div>
              </div>
              <div className={styles.verifyContainer__inner}>
                <h2>Set up your PIN</h2>
                <p>
                  For your safety, use a <strong>unique PIN</strong> (something
                  only you know) to secure your account.
                </p>

                <PinInput
                  length={4}
                  initialValue=""
                  secret={false}
                  onChange={(value, index) => {
                    setPin(value)
                  }}
                  type="numeric"
                  inputMode="number"
                  style={{ padding: "10px" }}
                  inputStyle={RCprops.inputStyle}
                  inputFocusStyle={{ borderColor: "blue" }}
                  onComplete={(value, index) => {}}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                />
                <br />

                <label className={styles.label}>
                  <input
                    type="checkbox"
                    defaultChecked={checked}
                    onChange={() => setChecked(!checked)}
                  />
                  I agree and accept{" "}
                  <Link to={`/terms`}> Kudi’s Term of Use</Link>
                </label>

                <div className={styles.verifyContainer__inner_footer}>
                  <div>
                    <Button onClick={handleSubmit}>Continue</Button>
                  </div>
                  <div></div>
                </div>

                <br />
                <div className={styles.verifyContainer__inner_footer}>
                  <div>
                    <p>Lost your phone number?</p>
                  </div>
                  <div>
                    <button className={styles.transparentBtn}>
                      <LostPhone />
                      Click Here
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </Container>
    </>
  )
}

export default Register
