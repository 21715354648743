import React, { useEffect, useContext } from "react"
import { Link } from "gatsby"
import styles from "./../questions.module.scss"
import { ScrollToHOC } from "react-scroll-to"
import { Back } from "../../../assets/svg"
import { isMobile } from "react-device-detect"
import Layout from "../../layout"

const SingleFaq = ({ pageContext, scrollTo }) => {
  useEffect(() => scrollTo({ y: isMobile ? 260 : 560, smooth: true }), [])
  const layoutContextValue = useContext(Layout.context)
  const { disableHeaderParam } = layoutContextValue
  const { faq } = pageContext
  return (
    <div className={styles.singleFaq}>
      {pageContext && (
        <div className={styles.sideContent}>
          {isMobile && (
            <p className={styles.back}>
              <Link to={`/faq${disableHeaderParam}`}>
                <Back /> Back
              </Link>
            </p>
          )}
          <h2>
            <Link to={`/faq/${faq.id}${disableHeaderParam}`}>{faq.name}</Link>
          </h2>
          <ul>
            {faq.resources.map((resource, id) => (
              <li key={id}>
                <Link to={`/faq/${faq.id}/${resource.id}${disableHeaderParam}`}>
                  {resource.name}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  )
}
export default ScrollToHOC(SingleFaq)
