import React, { useState, useEffect } from "react"
import styles from "./home-carousel.module.scss"
import Slide from "./slide"
import Indicator from "./indicator"
const HomeCarousel = ({ slides }) => {
  const [activeIndex, setActiveIndex] = useState(0)
  let slidesLength = slides.length - 1
  useEffect(() => {
    const timeout = setTimeout(() => {
      if (activeIndex === slidesLength) {
        setActiveIndex(0)
      } else {
        setActiveIndex(activeIndex + 1)
      }
    }, 3700)
    return () => {
      clearTimeout(timeout)
    }
  }, [setActiveIndex, activeIndex, slidesLength])
  return (
    <div className={styles.carousel}>
      <ul className={styles.Slides}>
        {slides.map((slide, index) => (
          <Slide
            key={index}
            index={index}
            activeIndex={activeIndex}
            slide={slide}
          />
        ))}
      </ul>
      <ul className={styles.Indicators}>
        {slides.map((slide, index) => (
          <Indicator
            key={`${slide.alt}-${index}`}
            index={index}
            activeIndex={activeIndex}
            isActive={activeIndex === index}
            onClick={() => setActiveIndex(index)}
          />
        ))}
      </ul>
    </div>
  )
}
export default HomeCarousel
