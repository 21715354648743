import React from "react"
import SubHero from "../sub-hero"
import { features, subHero } from "../../assets/pageData/home"
import WidePattern from "../wide-pattern"
import styles from "./home.module.scss"

const Features = () => (
  <section className={styles.feature}>
    <SubHero description={subHero.assurance} />
    <WidePattern type="grey" />
    <div className={styles.featureOptions}>
      {features.map(({ icon, title, description, animate }, index) => (
        <div
          key={index}
          data-aos={animate}
          data-aos-duration={2000}
          className={styles.featureOptionsItem}
        >
          {icon}
          <h4>{title}</h4>
          <p>{description}</p>
        </div>
      ))}
    </div>
  </section>
)

export default Features
