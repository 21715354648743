import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = ({ location }) => (
  <Layout showNav location={location}>
    <SEO title="404: Not found" />
    <div
      style={{
        width: "100%",
        height: "400px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <h1 style={{ fontSize: "11.11rem", marginBottom: "0.15em" }}>404</h1>
      <p style={{ fontSize: "1.67rem", marginBottom: "1em" }}>
        Sorry, you just hit a page that doesn't exist.
      </p>
    </div>
  </Layout>
)

NotFoundPage.propTypes = {
  location: PropTypes.object,
}

export default NotFoundPage
